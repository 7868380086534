import React from 'react'

export default function ComingSoon() {
    return (
        <section className="main-content tw-flex tw-justify-center">
            <div className="tw-flex tw-flex-col tw-items-center">
                {/* <img alt={1} className="tw-w-8" src="/static/media/send.c277ed167fea4ec49b406655e6592ac5.svg" /> */}
                <h2 style={{ fontSize: "40px" }} >Coming soon...</h2>
            </div>
        </section>
    )
}