import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';

class DropsContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }



  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
          <Header />
          <div className="tw-flex tw-w-full tw-flex-auto">
            <Left name="Sidebar_slim__EH6w7" />

            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__slim lg:tw-ml-80px">
              <section>
                <div className="container"><a href="/drops"><span className="hover:tw-opacity-80 tw-text-pink-hot">See all drops</span></a>
                  <div className="tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-gap-12 tw-justify-content-center tw-mt-4">
                    <div className="tw-flex-1">
                      <div className="tw-mt-4 lg:tw-mt-0 tw-rounded-xl">
                        <h1 className="tw-text-5xl tw-font-extrabold tw-leading-tight">Trippin' Gators</h1>
                        <div className="tw-flex tw-text-sm text-white tw-mt-2">
                          <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-20" data-original-title="Asia/Shanghai" style={{ display: "inline" }}>Aug 18, 08:00</div>
                          <span className="tw-flex tw-items-center text-white tw-rounded-md tw-ml-4 tw-px-2 tw-bg-gray-400">New</span>
                        </div>
                        <div className="tw-flex tw-mt-4 tw-flex-wrap tw-gap-y-4">
                          <a href="#" target="_blank" rel="noreferrer noopener" className="tw-mr-4 tw-border tw-border-solid tw-border-purple-3 tw-flex tw-items-center tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2"><img className="tw-w-6" src="/static/media/globe_white.1cc8238d59c1be911999eabdeeb72b8c.svg" alt="social link" /><span className="tw-ml-2">website</span></a>
                          <a href="#" target="_blank" rel="noreferrer noopener" className="tw-mr-4 tw-border tw-border-solid tw-border-purple-3 tw-flex tw-items-center tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2" ><img className="tw-w-6" src="/static/media/twitter_white.646bf11eec160fc04b57653d5d95478b.svg" alt="social link" /></a><a href="#" target="_blank" rel="noreferrer noopener" className="tw-mr-4 tw-border tw-border-solid tw-border-purple-3 tw-flex tw-items-center tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2"><img className="tw-w-6" src="/static/media/discord_white.a80cd7b6a598d458f12090e4f0316953.svg" alt="social link" /></a>
                          <div className="tw-cursor-help" data-tooltipped="" aria-describedby="tippy-tooltip-21" style={{ display: "inline" }}>
                            <button className="tw-flex tw-gap-2 tw-items-center tw-px-4 tw-py-2 tw-border tw-rounded-lg tw-border-solid UpVote_btnSmall__gz6gC tw-border tw-border-gray-700"><span><svg stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#e42575" fill="#4D3D5C" width="1rem" height="1rem"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></span><span>4510</span><span>Upvote</span></button>
                          </div>
                        </div>
                        <div className="tw-my-6 tw-break-words tw-text-gray-3">We are a community-minded crew working to make Trippin' Gators a top-tier project by creating trendingnft.in, a news platform and premium marketplace. Built on sol, gators are here to enjoy and make money. There are 3331 original works of art available.</div>
                      </div>
                      <div className="md:tw-mt-12"></div>
                    </div>
                    <div className="tw-flex-1">
                      <div className="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-overflow-hidden tw-w-full"><img draggable="false" loading="lazy" src="https://img-cdn.magiceden.dev/rs:fill:640:640:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/trippin_gators_pfp_1658485772715.png" alt="" style={{ backgroundColor: "transparent" }} /></div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" style={{ backdropFilter: "blur(20px)" }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content position-relative tw-bg-gray-100 tw-rounded-xl tw-p-4 tw-border-gray-500">
                    <div className="modal-body">
                      <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
                        <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-white-2">
                          <h2 className="tw-text-xl tw-mb-2">Before you mint… as always, DYOR.</h2>
                          <p className="tw-mb-2">Projects featured are upcoming NFT projects that are not an endorsement from I META ID or a guarantee of listing on I META ID post-mint.</p>
                          <p><a className="tw-text-pink-primary" href="#" target="_blank" rel="noreferrer">Learn more about DYOR</a></p>
                          <div className="tw-mt-10">
                            <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-mr-2 PlainButton_transparent__LvWuT">I understand</button>
                            <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_transparent__LvWuT">Take me back</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    );
  }
}

export default DropsContent;

