import React from 'react';
import Slider from "react-slick";
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './Games.css';
import './about.css';

class Games extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],
      p2: [
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
		  { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
		  { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://storage.googleapis.com/fractal-launchpad-public-assets/bossfighters/reveal/1163.jpg", code: "2.5" },
        { name: "NFT Leaguez", type:"Listing", time:"about 1 month ago", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "2.5" },
      ],


    };
  }

  componentDidMount() {

  }


  render() {
    const settingsSlick = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlay: true,
      autoPlaySpeed: 3000,
      dotsClass: "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full customDots",
      arrows: false,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: dots => (
        <div>
          <ul className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4" style={{ gridTemplateColumns: "repeat(" + this.state.p2.length + ", 1fr)" }}>
            {dots}
          </ul>
        </div>
      ),
      customPaging: i => (
          <div key={i} className="tw-cursor-pointer tw-flex tw-items-center">
            <span className={`${this.state.currentSlide === i ? 'tw-bg-white-2' : 'tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50'}`}></span>
          </div>
      )
    };




    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/>
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left name="Sidebar_slim__EH6w7"/>
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__slim lg:tw-ml-80px" >










  <main className="tw-flex tw-flex-col tw-items-center tw-mt-4">
   <div className="tw-flex tw-flex-col AboutPage_aboutPage__juJr-">
    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-8 xl:tw-mx-36">
     <h1 className="tw-font-extrabold tw-mt-2 tw-text-center !tw-text-[20px] sm:!tw-text-[40px] md:!tw-text-[58px] !tw-leading-[135%] sm:!tw-leading-[120%] tw-mt-8 tw-mb-6"><span>Welcome to I META ID</span><br /><span className="!tw-text-[18px] sm:!tw-text-[24px] md:!tw-text-[30px]">Home to the next generation of digital creators</span></h1>
     <div className="tw-flex tw-w-full sm:tw-w-max tw-flex-col sm:tw-flex-row tw-mt-10 tw-space-y-8 sm:tw-space-y-0 sm:tw-space-x-4">
      <a className="AboutPage_button__cBu7R" href="/">Explore marketplace</a>
      <a href="#" className="AboutPage_button__cBu7R AboutPage_secondary__2qSCz" target="_blank" rel="noreferrer">Apply for Launchpad</a>
     </div>
    </div>
    <div className="tw-grid tw-gap-4 tw-grid-cols-12 tw-justify-center tw-mx-8 xl:tw-mx-36 tw-mt-20 sm:tw-mt-44">
     <h2 className="tw-relative tw-font-extrabold !tw-text-2xl sm:!tw-text-6xl tw-col-span-12 xl:tw-col-span-4 xl:tw-min-w-[373px]"><span>We are a </span><span className="tw-pb-1 sm:tw-pb-2" style={{background: "url(/static/media/web3-marketplace-underline.c8844584d2c7865fd100ab0a795ecdca.svg) center bottom repeat-x"}}> web3 </span><span> first marketplace</span></h2>
     <div className="tw-row-start-2 tw-col-span-12 xl:tw-col-start-7 xl:tw-col-span-6">
      <p className="tw-text-gray-5"><span>I META ID is a community-centric company. We work hard to be responsive, close to the ground, and in service to the best interests of our collections. To prove our commitment to the community, we airdropped </span><a className="tw-underline tw-text-light-grey-600" href="#" target="_blank" rel="noreferrer noopener">Magic Ticket</a><span>, our own collection, to over 30,000 users. Holding a Magic Ticket gives holders access to our discord-based DAO, MagicDAO, where we deliver interconnectivity via social programs and rewards to the community.</span></p>
      <a className="AboutPage_button__cBu7R tw-max-w-max tw-mt-8" href="/marketplace/magicticket" target="_blank" rel="noreferrer noopener">Get Magic Ticket</a>
     </div>
     <div className="tw-grid tw-justify-center tw-gap-4 tw-col-span-12 tw-mt-28 tw-grid-cols-1 md:tw-grid-cols-3 2xl:tw-grid-cols-[repeat(3,373px)]">
      <a className="tw-bg-gray-200 tw-p-2 tw-transition-transform hover:tw-opacity-100 hover:tw-scale-[1.02]" href="/marketplace/magicticket?search=normie" target="_blank" rel="noreferrer noopener">
       <video autoPlay="" playsInline="" loop="">
        <source src="https://bafybeihr7vi77syiup5o4avvzemhlp4v4nplpc23haderjcjkkzgah2a5u.ipfs.dweb.link/0.mp4"></source>
       </video>
       <div className="tw-flex tw-justify-between tw-mx-2 sm:tw-mx-8 md:tw-mx-2 lg:tw-mx-8 tw-my-4">
        <p className="tw-text-2xl tw-text-center tw-text-white-2">Normie</p>
        <div className="tw-flex tw-items-center">
         <span className="tw-flex tw-items-center tw-px-4 tw-py-1 tw-text-xs tw-text-white-2 tw-border tw-border-pink-primary tw-rounded-20px">Level 1</span>
        </div>
       </div></a>
      <a className="tw-bg-gray-200 tw-p-2 tw-transition-transform hover:tw-opacity-100 hover:tw-scale-[1.02]" href="/marketplace/magicticket?search=degen" target="_blank" rel="noreferrer noopener">
       <video autoPlay="" playsInline="" loop="">
        <source src="https://bafybeigm42xnsdukek7wupydeiu4n7spmqf7zc73r54x3p3jjiw72nlrla.ipfs.dweb.link/0.mp4"></source>
       </video>
       <div className="tw-flex tw-justify-between tw-mx-2 sm:tw-mx-8 md:tw-mx-2 lg:tw-mx-8 tw-my-4">
        <p className="tw-text-2xl tw-text-center tw-text-white-2">Degen</p>
        <div className="tw-flex tw-items-center">
         <span className="tw-flex tw-items-center tw-px-4 tw-py-1 tw-text-xs tw-text-white-2 tw-border tw-border-pink-primary tw-rounded-20px">Level 2</span>
        </div>
       </div></a>
      <a className="tw-bg-gray-200 tw-p-2 tw-transition-transform hover:tw-opacity-100 hover:tw-scale-[1.02]" href="/marketplace/magicticket?search=og" target="_blank" rel="noreferrer noopener">
       <video autoPlay="" playsInline="" loop="">
        <source src="https://bafybeihlkgqlslyayv3u5c6ix4tuis27jp6mrapyfqjosgwaqb7prickbi.ipfs.dweb.link/0.mp4"></source>
       </video>
       <div className="tw-flex tw-justify-between tw-mx-2 sm:tw-mx-8 md:tw-mx-2 lg:tw-mx-8 tw-my-4">
        <p className="tw-text-2xl tw-text-center tw-text-white-2">OG</p>
        <div className="tw-flex tw-items-center">
         <span className="tw-flex tw-items-center tw-px-4 tw-py-1 tw-text-xs tw-text-white-2 tw-border tw-border-pink-primary tw-rounded-20px">Level 3</span>
        </div>
       </div></a>
     </div>
    </div>
    <div className="tw-grid tw-gap-x-4 lg:tw-gap-y-24 tw-grid-cols-12 tw-justify-center tw-mx-8 xl:tw-mx-36 tw-mt-16 lg:tw-mt-40">
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2">22,000,000+</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">unique monthly visitors</span>
      </div>
     </div>
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6 xl:tw-col-start-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2">8,000+</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">collections</span>
      </div>
     </div>
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2">70+</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">people</span>
      </div>
     </div>
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6 xl:tw-col-start-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2">100,000+</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">daily wallet connections</span>
      </div>
     </div>
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2">$1.9 billion</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">secondary trading volume</span>
      </div>
     </div>
     <div className="tw-border-t tw-border-gray-300 tw-col-span-12 lg:tw-col-span-6 xl:tw-col-span-4 tw-pt-10 tw-pb-6 xl:tw-col-start-6">
      <div className="tw-flex tw-flex-col tw-text-lg">
       <span className="tw-text-4xl sm:tw-text-5xl tw-text-white-2" style={{fontSize:"2.7rem"}}>iOS / Android</span>
       <span className="tw-text-pink-primary tw-mt-6 tw-ml-1">browsing app</span>
      </div>
      <div className="tw-flex tw-gap-6 tw-mt-6">
       <a className="AboutPage_button__cBu7R AboutPage_secondary__2qSCz AboutPage_mobile__FGRHd" href="#" target="_blank" rel="noreferrer noopener">
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" color="#e42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(228, 37, 117)"}}>
         <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"></path>
        </svg><span style={{fontSize:".775rem"}}>App Store</span></a>
       <a className="AboutPage_button__cBu7R AboutPage_secondary__2qSCz AboutPage_mobile__FGRHd" href="#" target="_blank" rel="noreferrer noopener">
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" color="#e42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(228, 37, 117)"}}>
         <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z"></path>
        </svg><span style={{fontSize:".775rem"}}>Google Play</span></a>
      </div>
     </div>
    </div>
    <div className="tw-grid tw-gap-x-4 tw-gap-y-24 tw-grid-cols-12 tw-justify-center tw-items-center tw-mx-8 xl:tw-mx-36 tw-mt-12 sm:tw-mt-20">
     <div className="tw-col-span-12 xl:tw-col-span-5 tw-space-y-12">
      <h2 className="tw--translate-x-10 sm:tw--translate-x-16 !tw-text-[24px] sm:!tw-text-[40px] tw-whitespace-nowrap"><span className="tw-font-extrabold tw-pb-4 tw-pl-10 sm:tw-pb-6 sm:tw-pl-16" style={{background: "url(/static/media/magic-eden-story-underline.1346b37bad517d7c2d18c135073d47f9.svg) center bottom no-repeat"}}>I META ID's Story</span></h2>
      <p className="tw-text-gray-5">After four friends discovered NFTs and its tremendous potential, I META ID was born. With backgrounds across crypto, DeFi, consumer internet companies and management consulting, they identified gaps in existing NFT marketplaces and created something new.</p>
     </div>
     {/* <div className="tw-flex tw-flex-col tw-col-span-12 xl:tw-col-start-7 xl:tw-col-span-6 tw-space-y-6 tw-min-h-500px tw-mt-16 tw-mx-8 xl:tw-mx-0">
      <div className="tw-flex">
       <div className="tw-flex tw-flex-col tw-space-y-6 tw-items-center" itemID="sidd">
        <img className="tw-cursor-pointer" src="https://bafybeif4hxe2fvcd5nn2imj6tbs77oieqweklq3hpnrjzarcncqcv74swi.ipfs.dweb.link" />
        <span className="tw-cursor-pointer tw-text-pink-primary tw-underline">Sidney Zhang</span>
        <span className="tw-text-gray-5 tw-text-center tw-text-sm">Chief Technology Officer</span>
       </div>
       <div className="tw-flex tw-flex-col tw-space-y-6 tw-items-center" itemID="jack">
        <img className="tw-cursor-pointer" src="https://bafybeiecyh7r5y5giiqnf7gb2sdjup4ipbkwseeryvxyoiab2qanff2wfi.ipfs.dweb.link" />
        <span className="tw-cursor-pointer tw-text-white-2">Jack Lu</span>
        <span className="tw-text-gray-5 tw-text-center tw-text-sm">Chief Executive Officer</span>
       </div>
       <div className="tw-flex tw-flex-col tw-space-y-6 tw-items-center" itemID="zedd">
        <img className="tw-cursor-pointer" src="https://bafybeiel7rme4vvd32ji7o5ecchugc7nyzbfbtsutxpwlq4x4dhbevwasq.ipfs.dweb.link" />
        <span className="tw-cursor-pointer tw-text-white-2">Zhuoxun Yin</span>
        <span className="tw-text-gray-5 tw-text-center tw-text-sm">Chief Operating Officer</span>
       </div>
       <div className="tw-flex tw-flex-col tw-space-y-6 tw-items-center" itemID="rex">
        <img className="tw-cursor-pointer" src="https://bafybeiduvyzfrpk34zo5z3n3vm2vtpq6thkgl22nio2aey2jujg5ingzhm.ipfs.dweb.link" />
        <span className="tw-cursor-pointer tw-text-white-2">Zhuojie Zhou</span>
        <span className="tw-text-gray-5 tw-text-center tw-text-sm">Chief Engineer</span>
       </div>
      </div>
      <div className="tw-flex tw-space-x-6 tw-px-4">
       <a className="tw-flex tw-space-x-2 tw-max-w-max hover:tw-text-gray-3 AboutPage_button__cBu7R AboutPage_secondary__2qSCz" href="#" target="_blank" rel="noreferrer noopener">
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" color="#E42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(228, 37, 117)"}}>
         <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
        </svg><span className="tw-text-white-2">@sidazhang</span></a>
      </div>
      <p className="tw-text-pink-primary tw-h-[550px] sm:tw-h-[250px] tw-px-4">Sidney started in crypto with his first crypto company working on the Bitcoin Developer API in 2013. He joined Uber in 2015 as one of the Founding Engineers at Uber Eats, where he worked on Uber Eats from zero till it hit the moon. Sidney subsequently spent a few years at Uber Advanced Technologies Group working on self-driving production modeling and research. focusing on computer vision and prediction. He lastly joined Facebook��s AI team before co-founding I META ID.</p>
     </div> */}
    </div>
    <div className="tw-grid tw-gap-4 tw-grid-cols-12 tw-justify-center tw-items-center tw-mx-8 xl:tw-mx-36 tw-mt-56">
     <h2 className="tw-font-extrabold tw-col-span-12 tw-whitespace-nowrap !tw-text-[54px] !tw-text-[rgba(41,32,50,0.8)] tw-blur-[1px] tw-select-none tw-mb-32" >Services</h2>
     <div className="tw-grid tw-gap-x-4 tw-gap-y-4 md:tw-gap-y-32 tw-grid-cols-12 tw-col-span-12 tw--translate-y-20 md:tw--translate-y-32">
      <h3 className="!tw-text-pink-primary tw-col-span-12 md:tw-col-span-3 tw-text-2xl">Core secondary marketplace</h3>
      <div className="tw-flex tw-flex-col tw-space-y-9 tw-mb-12 tw-col-span-12 md:mb-0 md:tw-col-start-6 md:tw-col-span-6">
       <div className="tw-text-gray-5">
        We offer creators the greatest liquidity, holding over 90% market share for secondary trading volume on certain Blockchains and majority share on others.
       </div>
       <a href="#" className="tw-max-w-max AboutPage_button__cBu7R" target="_blank" rel="noreferrer noopener">Apply for Listing</a>
      </div>
     </div>
     <div className="tw-grid tw-gap-x-4 tw-gap-y-4 md:tw-gap-y-32 tw-grid-cols-12 tw-col-span-12 tw--translate-y-20 md:tw--translate-y-32">
      <h3 className="!tw-text-pink-primary tw-col-span-12 md:tw-col-span-3 tw-text-2xl">Launchpad</h3>
      <div className="tw-flex tw-flex-col tw-space-y-9 tw-mb-12 tw-col-span-12 md:mb-0 md:tw-col-start-6 md:tw-col-span-6">
       <div className="tw-text-gray-5">
        <span>Launchpad is our exclusive minting platform. We accept 3% of all Launchpad applications and feature only the best projects.</span>
        <ul className="tw-list-disc tw-ml-5">
         <li>The greatest exposure and widest reach on Web 3</li>
         <li>The greatest potential for secondary trading volume post-mint</li>
         <li>A hassle-free mint with dedicated development support</li>
        </ul>
       </div>
       <a href="/launchpad" className="tw-max-w-max AboutPage_button__cBu7R" target="_blank" rel="noreferrer noopener">Learn more</a>
      </div>
     </div>
     <div className="tw-grid tw-gap-x-4 tw-gap-y-4 md:tw-gap-y-32 tw-grid-cols-12 tw-col-span-12 tw--translate-y-20 md:tw--translate-y-32">
      <h3 className="!tw-text-pink-primary tw-col-span-12 md:tw-col-span-3 tw-text-2xl">Whitelabel marketplaces</h3>
      <div className="tw-flex tw-flex-col tw-space-y-9 tw-mb-12 tw-col-span-12 md:mb-0 md:tw-col-start-6 md:tw-col-span-6">
       <div className="tw-text-gray-5">
        Partner with us by powering your own marketplace or mint button with our tech under your hood.
        <a className="tw-text-white-2" href="#" target="_blank" rel="noreferrer noopener">Raydium��s NFT marketplace</a>,
        <a className="tw-text-white-2" href="#" target="_blank" rel="noreferrer noopener">Thugbirdz��s Thugstore</a> and
        <a className="tw-text-white-2" href="#" target="_blank" rel="noreferrer noopener">Galactic Gecko��s marketplace</a>, are all powered by I META ID.
       </div>
       <a href="mailto:logan.holling@magiceden.io" className="tw-max-w-max AboutPage_button__cBu7R" target="_blank" rel="noreferrer noopener">Learn More</a>
      </div>
     </div>
     <div className="tw-grid tw-gap-x-4 tw-gap-y-4 md:tw-gap-y-32 tw-grid-cols-12 tw-col-span-12 tw--translate-y-20 md:tw--translate-y-32">
      <h3 className="!tw-text-pink-primary tw-col-span-12 md:tw-col-span-3 tw-text-2xl">Partnerships</h3>
      <div className="tw-flex tw-flex-col tw-space-y-9 tw-mb-12 tw-col-span-12 md:mb-0 md:tw-col-start-6 md:tw-col-span-6">
       <div className="tw-text-gray-5">
        Join global brands and leaders, and drop your NFT collections with us! From white-glove partnerships to something completely new, we want to hear your bright ideas.
       </div>
       <a href="#" className="tw-max-w-max AboutPage_button__cBu7R" target="_blank" rel="noreferrer noopener">Get in Touch</a>
      </div>
     </div>
     <div className="tw-grid tw-gap-x-4 tw-gap-y-4 md:tw-gap-y-32 tw-grid-cols-12 tw-col-span-12 tw--translate-y-20 md:tw--translate-y-32">
      <h3 className="!tw-text-pink-primary tw-col-span-12 md:tw-col-span-3 tw-text-2xl">Games</h3>
      <div className="tw-flex tw-flex-col tw-space-y-9 tw-mb-12 tw-col-span-12 md:mb-0 md:tw-col-start-6 md:tw-col-span-6">
       <div className="tw-text-gray-5">
        More than 90% of all NFT gaming secondary trading volume occurs on I META ID. We are passionate about supporting game creators and their communities. We��ve partnered with top gaming projects to launch Eden Games, a place for games to call home on Solana &amp; Beyond
       </div>
       <a href="/games" className="tw-max-w-max AboutPage_button__cBu7R" target="_blank" rel="noreferrer noopener">Explore Games</a>
      </div>
     </div>
    </div>
    <div className="tw-grid tw-grid-cols-12 tw-justify-center tw-space-y-20 tw-mx-8 md:tw-mx-36 tw-mt-20 lg:tw-mt-0">
     <h2 className="tw-text-white-2 tw-col-span-12 lg:tw-col-span-7 tw-font-extrabold !tw-text-2xl sm:!tw-leading-[120%] sm:!tw-text-[58px]"><span style={{fontSize:"57px !important"}}>We are supported by the top firms who care about </span><span className="tw-pb-2 sm:tw-pb-2" style={{background: "url(/static/media/crypto-underline.5ff45a77824471fd65217b2b94f2e7c9.svg) center bottom no-repeat"}}> crypto </span><span> and </span><span className="tw-pb-2 sm:tw-pb-2" style={{background: "url(/static/media/web3-underline.b905d41cc4ef120afdb59e99e76aaceb.svg) center bottom no-repeat"}}> web3</span></h2>
     <div className="tw-grid sm:tw-grid-cols-2 xl:tw-grid-cols-4 tw-items-center tw-justify-center tw-gap-20 tw-col-span-12">
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="xl:tw-col-start-2 tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center">
       <img alt="case" src="/static/media/paradigm.c5737ebd07cd7a0b3ee2f8d9e9614305.svg" />
      </div>
     </div>
    </div>
    <div className="tw-grid tw-gap-x-4 tw-gap-y-16 tw-grid-cols-12 tw-justify-center tw-items-center tw-mx-8 md:tw-mx-36 tw-mt-20 lg:tw-mt-44">
     <h2 className="tw-text-white-2 tw-font-extrabold tw-col-span-12 lg:tw-col-span-5 tw-text-[40px]">Get in Touch</h2>
     <div className="tw-flex tw-flex-col tw-space-y-12 tw-col-span-12 lg:tw-col-start-8 lg:tw-col-span-4">
      <div className="tw-flex tw-flex-col tw-space-y-2">
       <h4 className="tw-text-white-2 tw-font-bold">Support</h4>
       <div className="tw-flex tw-flex-col tw-text-gray-5 tw-leading-6">
        <p>Need assistance? Want to find out more about I META ID? Visit our <a href="#" className="tw-text-pink-primary" target="_blank" rel="noreferrer">help desk</a>!</p>
       </div>
      </div>
      <div className="tw-flex tw-flex-col tw-space-y-2">
       <h4 className="tw-text-white-2 tw-font-bold">Press</h4>
       <div className="tw-flex tw-flex-col tw-text-gray-5 tw-leading-6">
        <p>Want to feature I META ID? Looking for the I META ID logo? Feel free to <a href="mailto:press@magiceden.io" className="tw-text-pink-primary" target="_blank" rel="noreferrer">reach out to us</a>!</p>
        <a href="#" className="tw-text-pink-primary tw-text-sm tw-mt-6" target="_blank" rel="noreferrer">Download ME logo</a>
       </div>
      </div>
      <div className="tw-flex tw-flex-col tw-space-y-2">
       <h4 className="tw-text-white-2 tw-font-bold">Careers</h4>
       <div className="tw-flex tw-flex-col tw-text-gray-5 tw-leading-6">
        We're hiring. Lunatics only. Join our team!
        <a href="#" className="tw-text-pink-primary" target="_blank" rel="noreferrer">Apply here.</a>
       </div>
      </div>
     </div>
    </div>
   </div>
   <img width="100%" alt="banner" src="/static/media/metaverse.60e20f03a12b881f08fd90f341c6f417.svg" />
  </main>











			</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Games;

