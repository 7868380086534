import React from 'react';
import Header from "./Header";
import Left from "./Left";
import ComingSoon from './components/ComingSoon';
import './Home.css';

class WatchList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],

    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
          <Header />
          <div className="tw-flex tw-w-full tw-flex-auto">
            <Left />
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">

              {true ? <ComingSoon /> : (<section className="main-content tw-flex tw-items-center tw-justify-center">
                <div className="tw-flex tw-flex-col tw-items-center">
                  <h4 className="tw-mb-4">Connect your wallet to view your watchlist</h4>
                  <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_primary__22Ken" style={{ width: "150px" }}>Connect Wallet</button>
                </div>
              </section>)}


            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WatchList;

