import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './AuctionContent.css';

class AuctionContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/>
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left />

            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">
              <section className="min-h-full d-flex flex-column align-items-center justify-content-center tw-pt-0">
                <div className="mt-5 auction__title md:tw-p-4 d-flex flex-column justify-content-center">
                  <div className="cursor-pointer auction__image"><img draggable="false" loading="lazy" className="card-img-top SolanaNFTMedia_media__1RXR3 SolanaNFTMedia_center__YTvJM" src="https://img-cdn.magiceden.dev/rs:fill:1080:1380:0:0/plain/https://bafybeia2y5y2nlxnge45ifousugfawxxxw4qg4krwbwg642bw4t4zbmopy.ipfs.dweb.link?ext=png" style={{backgroundColor: "transparent"}}  alt="1" /></div>
                </div>
                <div className="container mt-5 row auction__interface">
                  <div className="container col-md-6 col-sm-12 tw-p-4">
                    <h1 className="auction__title">The Weeknd</h1>
                    <div className="mt-2 auction__creator">
                      <h3 className="text-muted">CREATED BY</h3>
                      <h3 className="px-2 creator-address tw-flex align-items-center"><p className="creator-link">Celebrity Cyborg Society / Travis Rime Brooks</p></h3></div>
                    <div role="group" className="mt-2 tw-flex tw-relative">
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-10" data-original-title="Website" style={{display: "inline"}}>
                          <a href="#" target="_blank" rel="noreferrer">
                            <img alt="1" src="/static/media/globe_white.1cc8238d59c1be911999eabdeeb72b8c.svg" className="tw-w-6 tw-h-6 hover:tw-opacity-80" /></a></div>
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-11" data-original-title="Discord" style={{display: "inline"}}>
                          <a href="#" target="_blank" rel="noreferrer" >
                            <img alt="1" src="/static/media/discord_white.a80cd7b6a598d458f12090e4f0316953.svg" className="tw-w-6 tw-h-6 hover:tw-opacity-80" />
                          </a>
                          </div>
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-12" data-original-title="Twitter" style={{display: "inline"}} ><a href="#" target="_blank" rel="noreferrer" ><img alt="1" src="/static/media/twitter_white.646bf11eec160fc04b57653d5d95478b.svg" className="tw-w-6 tw-h-6 hover:tw-opacity-80" /></a></div>
                      </div>
                      <a href="#" target="_blank" rel="noreferrer noopener" className="tw-border tw-border-solid tw-border-purple-1 tw-flex tw-items-center tw-gap-2 tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" color="#e42575">
                          <path stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round" d="M15 10.833v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V6.667A1.667 1.667 0 014.167 5h5M12.5 2.5h5v5M8.332 11.667L17.499 2.5"></path>
                        </svg><span>Token 8oPP...Gxy5</span></a>
                    </div>
                    <p className="mt-4 mb-4 tw-whitespace-pre-wrap">
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">We're the first 333 Cyborgs of Human Celebrities from Earth. Everyone = 1/1. Join us.</p>
                    </p>
                    <div style={{marginLeft: "-12px", marginRight: "-12px"}}>
                      <h3 className="m-3">Attributes</h3>
                      <div className="pt-2">
                        <div>
                          <div className="row attributes attributes-row tw-justify-center">
                            <div className="col-lg-4 col-12 attributes-column">
                              <div className="p-3  bg-color-third d-flex flex-column border-radius-8px h-100 position-relative attributes-main"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZXNEYIwEIVfGA/iiRLszBawArACpQOtDDrAEwkX1iyQ8WY2BDj4zfAzzNtskn1sgI1R7qXXfUFEuf2SeaMIb3t/Hk/HqyiBMeZug3IEQqBbmqalV2g60xptSGt9hgDWsb43fSvRg8V8IQBpzAHLacTKJSuQkmBjRFsUY2HvCtjC1o6laHCGdQq5dVr5HeRHDWIt7K/BPHP7QzUQ4HR2SzNZgkimBFNhIN2G4ASkqOKngqrnetRYiTEBN6yBhsqtZFdi+9RORf7vBIEWdjql1BjnbXZsYWvfYrYwpNBArzGRRNx13SNBcpF2U56U6Kxegw+CobhgOuqV7gAAAABJRU5ErkJggg==" alt="copy" />
                                <div className="tw-flex tw-flex-row"><span className="fs-11px text-uppercase letter-spacing-3 text-truncate tw-text-secondary tw-mr-2" title="name">name</span></div><span className="text-white fs-14px text-truncate attribute-value" title="The Weeknd">The Weeknd</span></div>
                            </div>
                            <div className="col-lg-4 col-12 attributes-column">
                              <div className="p-3  bg-color-third d-flex flex-column border-radius-8px h-100 position-relative attributes-main"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZXNEYIwEIVfGA/iiRLszBawArACpQOtDDrAEwkX1iyQ8WY2BDj4zfAzzNtskn1sgI1R7qXXfUFEuf2SeaMIb3t/Hk/HqyiBMeZug3IEQqBbmqalV2g60xptSGt9hgDWsb43fSvRg8V8IQBpzAHLacTKJSuQkmBjRFsUY2HvCtjC1o6laHCGdQq5dVr5HeRHDWIt7K/BPHP7QzUQ4HR2SzNZgkimBFNhIN2G4ASkqOKngqrnetRYiTEBN6yBhsqtZFdi+9RORf7vBIEWdjql1BjnbXZsYWvfYrYwpNBArzGRRNx13SNBcpF2U56U6Kxegw+CobhgOuqV7gAAAABJRU5ErkJggg==" alt="copy" />
                                <div className="tw-flex tw-flex-row"><span className="fs-11px text-uppercase letter-spacing-3 text-truncate tw-text-secondary tw-mr-2" title="type">type</span></div><span className="text-white fs-14px text-truncate attribute-value" title="music">music</span></div>
                            </div>
                            <div className="col-lg-4 col-12 attributes-column">
                              <div className="p-3  bg-color-third d-flex flex-column border-radius-8px h-100 position-relative attributes-main"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZXNEYIwEIVfGA/iiRLszBawArACpQOtDDrAEwkX1iyQ8WY2BDj4zfAzzNtskn1sgI1R7qXXfUFEuf2SeaMIb3t/Hk/HqyiBMeZug3IEQqBbmqalV2g60xptSGt9hgDWsb43fSvRg8V8IQBpzAHLacTKJSuQkmBjRFsUY2HvCtjC1o6laHCGdQq5dVr5HeRHDWIt7K/BPHP7QzUQ4HR2SzNZgkimBFNhIN2G4ASkqOKngqrnetRYiTEBN6yBhsqtZFdi+9RORf7vBIEWdjql1BjnbXZsYWvfYrYwpNBArzGRRNx13SNBcpF2U56U6Kxegw+CobhgOuqV7gAAAABJRU5ErkJggg==" alt="copy" />
                                <div className="tw-flex tw-flex-row"><span className="fs-11px text-uppercase letter-spacing-3 text-truncate tw-text-secondary tw-mr-2" title="born">born</span></div><span className="text-white fs-14px text-truncate attribute-value" title="1990">1990</span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container col-md-6 col-sm-12 tw-p-4">
                    <div className="auction__info">
                      <div className="tw-flex p-4 auction__stats tw-mt-4 tw-flex-row tw-flex-wrap tw-justify-between tw-items-center tw-gap-4">
                        <div className="tw-flex tw-flex-col">
                          <div className="d-flex align-items-center">
                            <h4>CURRENT BID</h4><span className="live-indicator d-flex align-items-center tw-ml-2"><i className="live-indicator-circle" aria-hidden="true"></i>Live</span></div>
                          <h1 className="mt-2 tw-overflow-hidden"><span className="tw-flex">1.25 <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-13" data-original-title="Copy token address" style={{display: "inline"}}><span className="tw-cursor-pointer">◎</span></div></span></h1>
                          <p>Minimum bid: 1.50 ◎</p>
                        </div>
                        <div className="d-flex flex-column col-md-7 col-sm-12 auction__countdown tw-min-w-[160px] tw-max-w-[220px]">
                          <h4>AUCTION ENDS IN</h4>
                          <div className="p-0 mt-2 d-flex">
                            <div className="col-4 d-flex flex-column">
                              <h1>09</h1>
                              <p>Hours</p>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <h1>02</h1>
                              <p>Minutes</p>
                            </div>
                            <div className="col-4 d-flex flex-column">
                              <h1>30</h1>
                              <p>Seconds</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container px-4 py-3 mt-3 auction__bidder d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <h4 className="text-muted">LAST BID BY</h4>
                          <h4 className="px-2 last-bidder"><div className="" data-tooltipped="" aria-describedby="tippy-tooltip-14" data-original-title="Copied" style={{display: "inline"}}><div className="tw-cursor-pointer tw-inline">VZQC...hY6v</div></div></h4>
                          <p className="px-2 tw-text-xs">2022年8月11日 GMT+8 08:26:24</p>
                          <p className="px-2 tw-text-sm tw-ml-auto tw-cursor-pointer">See all</p>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-5 row auction__place-bid">
                      <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
                        <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER tw-p-0"><a className="tw-flex tw-w-full" href="/me"><span className="tw-px-2"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{color: "rgb(228, 37, 117)"}}><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></span></a></button>
                        <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">Connect Wallet</button>
                      </div>
                      <p className="mt-3 mb-3"><b>How it works:</b>
                        <br />1. Connect your wallet and place a bid. The bid must be at least 0.25 ◎ greater than the current bid
                          <br />2. You will automatically get your ◎ returned to your wallet if outbid
                            <br />3. If a bid is received 5 minutes from the end of the auction, the bidding time will be extended by 5 minutes
                              <br />4. When auction closes, the artwork will belong to the highest bidder. Please claim the NFT by pressing the “claim item” button
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="modal fade auction__place-bid-modal" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" style={{backdropFilter: "blur(1px)"}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content position-relative tw-bg-gray-100 tw-rounded-20px tw-p-2 tw-border-gray-500">
                              <div className="modal-header">
                                <div className="d-flex w-100 justify-content-center">
                                  <h1>Place bid</h1></div><span aria-hidden="true" className="modal-close-btn"></span></div>
                              <div className="modal-body">
                                <div className="make-offer__label tw-text-light-gray-700">Bid amount in ◎</div>
                                <input className="numeric-input input-dark-1" defaultValue="" />
                              </div>
                              <div className="modal-footer">
                                <div className="d-flex w-100">
                                  <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 flex-1-1-0 PlainButton_primary__22Ken">Place bid</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>



          </div>
        </div>
      </div>
    );
  }
}

export default AuctionContent;

