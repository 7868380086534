
import React, { useContext, useState, useEffect } from "react";
import { Button, Select, Checkbox, Form, Input, Modal, Divider, Col, Row, message, Space, Tooltip, Typography } from 'antd';
import Login from "./Login";
import { register, loginByPhone } from "./services/userService";
import { shortenAddress } from './utils/shortenAddress'
import { TransactionContext } from './context/TransactionContext'
import Test2 from "./Test2";

import "./Header.sass"

const { Option } = Select;

const ForgetPassword = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    setOpen(false)
  }

  const onFinish = (values) => {
    register(values).then(data => {
      console.log('data', data)
      if (data.code === 200) {
        message.success(data.msg);
        close()
      } else {
        message.error(data.msg);
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal title="Retrieve password" open={open} maskClosable={false} onOk={close} onCancel={close} footer={[]} >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Account"
          name="userName"
          rules={[
            {
              required: true,
              message: 'Please input your email address!',
            },
          ]}
        >
          <Input placeholder="email address" />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const LoginByPhone = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    setOpen(false)
  }

  const onFinish = (values) => {
    loginByPhone(values).then(data => {
      console.log('data', data)
      if (data.code === 200) {
        message.success(data.msg);
        close()
      } else {
        message.error(data.msg);
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  return (
    <Modal title="Sign in by Phone" width={800} open={open} maskClosable={false} onOk={close} onCancel={close} footer={[]} >
      <Form
        {...formItemLayout}
        name="basic"
        // layout="horizontal"
        form={form}
        // size={"default"}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          prefix: '86',
        }}
      >
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: 'Please input your phone number !',
            },
          ]}
          style={{
          }}
        >
          <Input
            addonBefore={prefixSelector}
            style={{
              width: '100%',
            }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button>Get verification code</Button>
        </Form.Item>
        <Form.Item
          name="verificationCode"
          wrapperCol={{ span: 12 }}
          label="Verification code"
          rules={[
            {
              required: true,
              message: 'Please input your verification code you got!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </Modal >
  );
}

const SignUp = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    setOpen(false)
  }

  const onFinish = (values) => {
    register(values).then(data => {
      console.log('data', data)
      if (data.code === 200) {
        message.success(data.msg);
        close()
      } else {
        message.error(data.msg);
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal title="Sign up" open={open} maskClosable={false} onOk={close} onCancel={close} footer={[]} >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Account"
          name="userName"
          rules={[
            {
              required: true,
              message: 'Please input your email address!',
            },
          ]}
        >
          <Input placeholder="email address" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder="password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="password" />
        </Form.Item>
        <Form.Item >
          <Button type="primary" htmlType="submit">Sign up</Button>
        </Form.Item>

      </Form>
    </Modal>
  );
}

const LoginForm = ({ open, setOpen, setSignUpOpen, setForgotPasswordOpen, setLoginByPhoneOpen }) => {
  const context = useContext(TransactionContext)
  const [form] = Form.useForm();

  const close = () => {
    form.resetFields();
    setOpen(false)
  }

  const onFinish = (values) => {
    const { handlerUserLogin } = context;
    handlerUserLogin(values).then(data => {
      console.log('data', data)
      if (data.code === 200) {
        close()
      } else {
        message.error(data.msg);
      }
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Modal title="Sign in to IMETAID" open={open} maskClosable={false} onOk={close} onCancel={close} footer={[]} >
        <Form
          name="basic"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Account"
            name="userName"
            rules={[
              {
                required: true,
                message: 'Please input your email address!',
              },
            ]}
          >
            <Input placeholder="email address" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password placeholder="password" />
          </Form.Item>

          <Form.Item
          // wrapperCol={{
          //   offset: 8,
          //   span: 16,
          // }}
          >
            <Button type="primary" htmlType="submit">
              Sign in
            </Button>
            <Button onClick={setForgotPasswordOpen} type="link">Forgot Password</Button>

          </Form.Item>
          <Divider plain>or</Divider>
          <Form.Item >
            <Button onClick={setLoginByPhoneOpen} block> Phone number </Button>
          </Form.Item>

          <div>
            New to IMETAID?<Button onClick={setSignUpOpen} type="link">Sign Up Now</Button>

          </div>
        </Form>
      </Modal>
    </div>
  );
};

class Header extends React.Component {

  static contextType = TransactionContext;

  constructor(props) {
    super(props)
    this.loginDialog = React.createRef()
    this.state = {
      showLogin: "",
      showDropdown: false,
      open: false,
      signUpOpen: false,
      forgotPasswordOpen: false,
      loginByPhoneOpen: false,
    };
  }

  handlerShowDropdown = () => {
    this.setState({ showDropdown: true })
  }

  handlerChooseWallet = (type) => {
    const { connectWallet } = this.context
    if (type === 'MetaMask') {
      this.loginDialog.current.hideModel('')
      connectWallet();
    } else {
      alert("This wallet does not supported now, it is coming soon.")
    }
  }

  signOut = () => {
    const { disconnectWallet } = this.context
    disconnectWallet();
    this.setState({ showDropdown: false })
  }

  setOpen = (value) => {
    this.setState({ open: value })
  }

  setSignUpOpen = (value) => {
    this.setState({ signUpOpen: value, open: false })
  }

  setForgotPasswordOpen = (value) => {
    this.setState({ forgotPasswordOpen: value, open: false })
  }

  setLoginByPhoneOpen = (value) => {
    this.setState({ loginByPhoneOpen: value, open: false })
  }

  componentDidMount() { }
  render() {
    const { currentAccount, currentAccountBalance, currentUser } = this.context;

    const loginButtonRender = () => {

      if (currentUser && currentUser.userName) {
        if (currentAccount !== '') {
          return (
            <div className="tw-flex tw-items-center tw-space-x-2">
              <div className="tw-block lg:tw-hidden tw-ml-2"><button className="tw-w-10 tw-h-10 hover:tw-bg-gray-500 tw-inline-flex tw-justify-center tw-items-center tw-transform active:tw-scale-90"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f8f7f8" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(248, 247, 248)' }}>
                <circle cx={11} cy={11} r={8} />
                <line x1={21} y1={21} x2="16.65" y2="16.65" />
              </svg></button></div>
              <div className="tw-hidden md:tw-inline-flex tw-items-center tw-justify-end tw-space-x-4"><button type="button"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f5f3f7" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(245, 243, 247)' }}>
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                <path d="M13.73 21a2 2 0 0 1-3.46 0" />
              </svg>
              </button>
                <div className="tw-hidden lg:tw-flex">
                  <div className="tw-flex tw-items-center tw-space-x-2 tw-p-1 tw-transition-colors"><a href="/me"><img className="tw-rounded-full tw-min-w-[36px] tw-w-9" src="/static/images/default.svg" width={36} /></a>
                    <div className="me-dropdown-container">
                      <div className="cursor-pointer position-relative">
                        <button onClick={() => this.setState({ showDropdown: true })} className="tw-flex tw-items-center tw-space-x-1" type="button">
                          <span className="tw-text-white-2"><span title={currentAccount} className="cursor-pointer d-inline-flex align-items-center tw-whitespace-nowrap">
                            <span>{shortenAddress(currentAccount)}</span></span></span>

                          <svg className={this.state.showDropdown ? "tw-rotate-180" : ""} stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24"
                            strokeLinecap="round" strokeLinejoin="round" color="#fff" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}>
                            <polyline points="6 9 12 15 18 9" /></svg>

                        </button></div>
                      <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border visible" style={{ position: 'absolute', inset: 'auto 0px 0px auto', width: 320, transform: 'translate3d(-20px, -56px, 0px)' }} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="top-end">
                        <div style={{ backgroundColor: "#131212", display: `${this.state.showDropdown === true ? "block" : "none"}` }} className="tw-absolute tw-flex tw-flex-col sm:tw-w-[320px] tw-mt-2 tw-rounded-xl tw-overflow-hidden tw-bg-gray-100 tw-border tw-border-gray-300 tw-shadow">
                          <button onClick={() => this.setState({ showDropdown: false })} className="tw-absolute tw-top-2 tw-right-2" type="button"><svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" color="#a197aa" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(161, 151, 170)' }}>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                            </path>
                          </svg></button>
                          <div className="tw-flex tw-m-4 tw-space-x-2"><a href="/me"><img className="tw-rounded-full tw-min-w-[36px] tw-w-9" src="https://img-cdn.magiceden.dev/rs:fill:36:36:0:0/plain/https://avatars.dicebear.com/api/jdenticon/default.svg" width={36} /></a>
                            <div className="tw-flex tw-flex-col"><span className="tw-text-white-2"><span title="0x9078e2B612dB19e2444063Cd5704d9Aa673CF3a2" className="cursor-pointer d-inline-flex align-items-center tw-whitespace-nowrap">
                              <span>{shortenAddress(currentAccount)}</span></span></span><a className="tw-text-pink-primary tw-text-sm" href="/me">View Profile</a></div>
                          </div>
                          <hr className="tw-bg-gray-300 tw-opacity-100 tw-mx-2 tw-h-px" />
                          <div className="tw-flex tw-items-center tw-p-3">
                            <div className="tw-flex tw-space-x-2 tw-items-center tw-flex-grow">
                              <div className="tw-bg-gray-300 tw-p-3 tw-rounded-full"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <circle cx={16} cy={16} r={16} fill="#24182F" />
                                  <g fill="#FFF" fillRule="nonzero">
                                    <path fillOpacity=".602" d="M16.498 4v8.87l7.497 3.35z" />
                                    <path d="M16.498 4L9 16.22l7.498-3.35z" />
                                    <path fillOpacity=".602" d="M16.498 21.968v6.027L24 17.616z">
                                    </path>
                                    <path d="M16.498 27.995v-6.028L9 17.616z" />
                                    <path fillOpacity=".2" d="M16.498 20.573l7.497-4.353-7.497-3.348z">
                                    </path>
                                    <path fillOpacity=".602" d="M9 16.22l7.498 4.353v-7.701z" />
                                  </g>
                                </g>
                              </svg></div>
                              <div>
                                <div className="WalletInfo_copyContainer__rOjPF tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer">
                                  <p className="tw-text-white-2 tw-text-sm tw-select-none">Main Wallet</p><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" className="WalletInfo_copyIcon__wpcBR" height={16} width={16} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(228, 37, 117)' }}>
                                    <rect x={9} y={9} width={13} height={13} rx={2} ry={2} />
                                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1">
                                    </path>
                                  </svg>
                                </div>
                                <div className="tw-flex tw-justify-between tw-items-center">
                                  <p className="tw-text-white-2 tw-font-bold tw-text-sm">{currentAccountBalance} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tw-bg-gray-200 tw-overflow-hidden" style={{ height: 0 }} />
                          <div className="tw-bg-gray-200 tw-overflow-hidden" style={{ height: 0 }}><button className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" type="button"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height={16} width={16} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}>
                            <circle cx={12} cy={12} r={10} />
                            <line x1={12} y1={8} x2={12} y2={16} />
                            <line x1={8} y1={12} x2={16} y2={12} />
                          </svg><span>Deposit from main wallet</span></button><button className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" type="button"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height={16} width={16} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}>
                            <circle cx={12} cy={12} r={10} />
                            <line x1={8} y1={12} x2={16} y2={12} />
                          </svg><span>Withdraw to main wallet</span></button></div>
                          <hr className="tw-bg-gray-300 tw-opacity-100 tw-mx-2 tw-h-px" /><a className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" href="/me"><svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 9.375C10.9518 9.375 13.75 8.1158 13.75 6.5625C13.75 5.0092 10.9518 3.75 7.5 3.75C4.04822 3.75 1.25 5.0092 1.25 6.5625C1.25 8.1158 4.04822 9.375 7.5 9.375Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.25 6.5625V9.6875C1.25 11.2422 4.04687 12.5 7.5 12.5C10.9531 12.5 13.75 11.2422 13.75 9.6875V6.5625" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 9.14062V12.2656" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.75 7.55469C16.6016 7.82031 18.75 8.95313 18.75 10.3125C18.75 11.8672 15.9531 13.125 12.5 13.125C10.9687 13.125 9.5625 12.875 8.47656 12.4688" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.25 12.4453V13.4375C6.25 14.9922 9.04687 16.25 12.5 16.25C15.9531 16.25 18.75 14.9922 18.75 13.4375V10.3125" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 12.8906V16.0156" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 9.14062V16.0156" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                          </svg><span>Sell</span></a><a className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" href="/me"><svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#fff" size={16}>
                            <path d="M2.5 13.75L10 18.125L17.5 13.75" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.5 10L10 14.375L17.5 10" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
                          </svg><span>My items</span></a>
                          <hr className="tw-bg-gray-300 tw-opacity-100 tw-mx-2 tw-h-px" /><button className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" type="button"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height={16} width={16} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}>
                            <polyline points="23 4 23 10 17 10" />
                            <polyline points="1 20 1 14 7 14" />
                            <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15">
                            </path>
                          </svg><span>Connect a different wallet</span></button>
                          <button onClick={this.signOut} className="tw-flex tw-items-center tw-space-x-4 tw-p-4 tw-text-white-2 tw-w-full hover:tw-bg-gray-300" type="button"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height={16} width={16} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(255, 255, 255)' }}>
                            <path d="M18.36 6.64a9 9 0 1 1-12.73 0" />
                            <line x1={12} y1={2} x2={12} y2={12} />
                          </svg><span>Sign out</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
        } else {
          return (
            <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
              <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
                <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER tw-p-0"><a className="tw-flex tw-w-full" href="/me"><span className="tw-px-2"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(228, 37, 117)' }}>
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg></span></a>
                </button>
                <button onClick={() => this.loginDialog.current.hideModel('show')} className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">
                  Connect Wallet
                </button>
              </div>
            </div>
          )
        }
      } else {
        return (<div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">

          <LoginForm open={this.state.open} setOpen={this.setOpen}
            setSignUpOpen={this.setSignUpOpen}
            setForgotPasswordOpen={this.setForgotPasswordOpen}
            setLoginByPhoneOpen={this.setLoginByPhoneOpen} />

          <SignUp open={this.state.signUpOpen} setOpen={this.setSignUpOpen} />
          <ForgetPassword open={this.state.forgotPasswordOpen} setOpen={this.setForgotPasswordOpen} />
          <LoginByPhone open={this.state.loginByPhoneOpen} setOpen={this.setLoginByPhoneOpen} />

          <div className="tw-hidden md:tw-inline-flex tw-items-center tw-justify-end tw-space-x-4">
            <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
              <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER tw-p-0"><a className="tw-flex tw-w-full" href="/me"><span className="tw-px-2"><svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" height={24} width={24} xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(228, 37, 117)' }}>
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                <circle cx={12} cy={7} r={4} />
              </svg></span></a>
              </button>
              <button onClick={() => { this.setOpen(true) }} className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">
                Sign in / Sign up
              </button>
            </div>

            {false && (<div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
              <a href="/sellercentral">
                <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">
                  Seller Center
                </button>
              </a>
            </div>)}

          </div>
        </div>)
      }
    }

    return (
      <div style={{ display: "contents" }}>
        {/*
          <div className="tw-w-full tw-flex tw-bg-gray-200 tw-z-50 tw-min-h-[32px] tw-relative">
            <div className="tw-flex-auto tw-justify-center">
              <ul className="tw-flex tw-justify-center tw-items-center">
                <li className="tw-text-xs tw-mr-2 lg:tw-mr-4 tw-whitespace-nowrap">
                  <div className="" style={{ display: "inline" }}>
                    <div className="tw-flex tw-items-center"><span className="tw-text-light-gray-500">Volume 24h:&nbsp;&nbsp;</span><span className="tw-font-mono tw-text-pink-primary tw-tracking-tight">42,588 SOL</span><span className="tw-ml-1.5"></span></div>
                  </div>
                </li>
                <li className="tw-text-xs tw-mr-2 lg:tw-mr-4 tw-whitespace-nowrap">
                  <div className="" style={{ display: "inline" }}>
                    <div className="tw-flex tw-items-center"><span className="tw-text-light-gray-500">Volume total:&nbsp;&nbsp;</span><span className="tw-font-mono tw-text-pink-primary tw-tracking-tight">23,111,715 SOL</span><span className="tw-ml-1.5"></span></div>
                  </div>
                </li>
                <li className="tw-text-xs tw-mr-2 lg:tw-mr-4 tw-whitespace-nowrap">
                  <div className="" style={{ display: "inline" }}>
                    <div className="tw-flex tw-items-center"><span className="tw-text-light-gray-500">SOL/USD&nbsp;&nbsp;</span>
                      <svg aria-labelledby="k19vivhj-aria" role="img" width="50px" height="16px" className="tw-rounded-sm">
                        <title id="k19vivhj-aria">Loading...</title>
                        <rect role="presentation" x="0" y="0" width="100%" height="100%" clipPath="url(#k19vivhj-diff)" style={{ fill: "url(&quot;#k19vivhj-animated-diff&quot;)" }}></rect>
                        <defs>
                          <clipPath id="k19vivhj-diff">
                            <rect x="0" y="0" width="50px" height="16px"></rect>
                          </clipPath>
                          <linearGradient id="k19vivhj-animated-diff">
                            <stop offset="0%" stopColor="#333" stopOpacity="1">
                              <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="50%" stopColor="#444" stopOpacity="1">
                              <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                            </stop>
                            <stop offset="100%" stopColor="#333" stopOpacity="1">
                              <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                            </stop>
                          </linearGradient>
                        </defs>
                      </svg><span className="tw-ml-1.5"></span></div>
                  </div>
                </li>
                <li className="tw-text-xs tw-mr-2 lg:tw-mr-4 tw-whitespace-nowrap">
                  <div className="" style={{ display: "inline" }}>
                    <div className="tw-flex tw-items-center"><span className="tw-text-light-gray-500">Solana Network:&nbsp;&nbsp;</span><span className="tw-font-mono tw-text-pink-primary tw-tracking-tight">2,701 TPS</span><span className="tw-ml-1.5"></span></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>


          <div className="tw-flex tw-items-center tw-absolute tw-z-[51] tw-right-2 tw-h-[32px]">
            <div className="me-dropdown-container">
              <div className="cursor-pointer position-relative">
                <button className="tw-flex tw-items-center"><span className="tw-text-xs">English</span><span className="tw-ml-1"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f8f7f8" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(248, 247, 248)" }}><polyline points="6 9 12 15 18 9"></polyline></svg></span></button>
              </div>
              <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border" data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-end" style={{ position: "absolute", inset: "0px 0px auto auto", width: "fit-content", transform: "translate(-0.0625px, 32px)" }}>
                <ul className="tw-py-2 tw-px-3 tw-text-white-1">
                  <li className="tw-cursor-pointer">
                    <div className="tw-border-soft tw-border-b tw-border-solid">English</div>
                  </li>
                  <li className="tw-cursor-pointer">
                    <div className="tw-border-soft tw-border-b tw-border-solid">한국어</div>
                  </li>
                  <li className="tw-cursor-pointer">
                    <div className="tw-border-soft tw-border-b tw-border-solid">日本語</div>
                  </li>
                  <li className="tw-cursor-pointer">
                    <div className="tw-border-soft tw-border-b tw-border-solid">Türkçe</div>
                  </li>
                  <li className="tw-cursor-pointer">
                    <div className="tw-border-soft tw-border-b tw-border-solid">Tiếng Việt</div>
                  </li>
                  <li className="tw-cursor-pointer">
                    <div className="">Русский</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
    */}

        <header
          className="tw-px-4 tw-w-full tw-h-80px tw-flex tw-sticky tw-top-0 tw-z-30 tw-transition-colors tw-bg-gray-100"
          style={{ backgroundColor: "rgb(12, 39, 62)" }}
        >
          <nav className="tw-w-full tw-flex tw-items-center">
            <div className="tw-flex tw-flex-auto tw-items-center">
              <div className="tw-flex tw-items-center">
                <button className="tw-p-2 md:tw-p-4 hover:tw-bg-gray-500 tw-rounded-lg lg:tw-hidden HeaderV2_collapseBtn__KfrfG">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    color="#f8f7f8"
                    height="1.5rem"
                    width="1.5rem"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(248, 247, 248)" }}
                  >
                    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                  </svg>
                </button>
                <a className="navbar-brand" href="/">
                  <img
                    className="logo HeaderV2_logo__Wyfk+"
                    src="/static/media/newlogo3.png"
                    style={{ height: 60 }}
                    alt="sticky brand-logo"
                  />
                  {/* <img
                    className="logo HeaderV2_logo__Wyfk+"
                    src="/static/media/logo3.png"
                    width="190px"
                    height="auto"
                    alt="sticky brand-logo"
                  /> */}
                </a>
              </div>
              <div className="tw-flex-auto tw-px-2 3xl:tw-ml-96 tw-hidden lg:tw-inline-flex tw-justify-center">
                <div className="nav-item--search-bar tw-w-full tw-max-w-2xl lg:tw-px-0 HeaderV2_search__UUbij css-b62m3t-container">
                  <span
                    id="react-select-2-live-region"
                    className="css-7pg0cj-a11yText"
                  ></span>
                  <span
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions text"
                    className="css-7pg0cj-a11yText"
                  ></span>
                  <div className="header-search__control css-znzj9m-control">
                    <div
                      className="header-search__value-container css-1d8n9bt"
                      style={{ display: "block" }}
                    >
                      <input
                        className="header-search__input"
                        type="text"
                        style={{
                          color: "inherit",
                          background: "0px center",
                          opacity: "1",
                          width: "100%",
                          gridArea: " 1 / 2 / auto / auto",
                          font: "inherit",
                          minWidth: "2px",
                          border: "0px",
                          margin: "0px",
                          outline: "0px",
                          padding: "0px",
                        }}
                      />
                    </div>
                    <div className="header-search__indicators css-1wy0on6">
                      <span className="header-search__indicator-separator css-1hyfx7x"></span>
                      <div
                        className="me-1 tw-h-9 tw-p-0 tw-mt-[2px] header-search__indicators css-1wy0on6"
                        aria-hidden="true"
                      >
                        <img
                          src="/static/media/search.48a682a639f3023f1afd96a01fadeeca.svg"
                          alt="search icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loginButtonRender()}

            {/* <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
              <a href="/sellercentral">
                <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">
                  Seller Center
                </button>
              </a>
            </div> */}

          </nav>
        </header>
        <Login ref={this.loginDialog} handlerChooseWallet={this.handlerChooseWallet} />
      </div>
    );
  }
}

export default Header;
