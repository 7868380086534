import React from 'react';
import Slider from "react-slick";
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './Games.css';

class Games extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],
      p2: [
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
		  { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
		  { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "/static/images/launchpad_main.gif", code: "01d  07h 58m" },
      ],


    };
  }

  componentDidMount() {

  }


  render() {
    const settingsSlick = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass: "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full customDots",
      arrows: false,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: dots => (
        <div>
          <ul className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4" style={{ gridTemplateColumns: "repeat(" + this.state.p2.length + ", 1fr)" }}>
            {dots}
          </ul>
        </div>
      ),
      customPaging: i => (
          <div key={i} className="tw-cursor-pointer tw-flex tw-items-center">
            <span className={`${this.state.currentSlide === i ? 'tw-bg-white-2' : 'tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50'}`}></span>
          </div>
      )
    };




    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/>
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left />
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240" style={{marginLeft: "240px"}} >





  <section className="tw-py-0">
   <section className="tw-p-4">
    <h2 className="tw-text-28px tw-font-bold tw-mb-8">All Games</h2>
    <div className="tw-grid tw-gap-3 tw-grid-cols-auto-fill-150">


{this.state.p2.map((item, index) => (
      <div key={index}>
     <div className="p-0 text-center card collection-card me" key={index}>
      <a href="/games/content">
       <div className="image-over border-radius-24px tw-relative">
        <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0">
         <img draggable="false" loading="lazy" className="card-img-top tw-object-cover tw-w-full tw-h-full" src={item.pic} style={{ backgroundColor: "transparent" }} />
        </div>
       </div>
       <div className="p-0 card-caption col-12">
        <div className="tw-px-4 tw-pt-3 tw-pb-4 tw-px-2 tw-pb-2">
         <h5 className="tw-mb-2 tw-text-[20px] tw-truncate !tw-text-[16px] tw-mb-1" title="BOSS FIGHTERS">{item.name}</h5>
         <div className="tw-text-secondary fs-14px description" title="A revolutionary VR competitive gaming show on PC.">
          {item.content}
         </div>
        </div>
       </div></a>
     </div>
	    </div>
		))}
    </div>
   </section>
  </section>






			</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Games;

