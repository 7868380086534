import client from "./apiService"

const login = (user) => {
    if (user.userName === 'admin') {
        return Promise.resolve({
            code: 200,
            msg: "success",
            data: { userName: "user01", token: "token01" }
        });
    } else {
        return Promise.resolve({
            code: 500,
            msg: "username or password not correct"
        });
    }
};

const loginByPhone = ({ phone, verificatonCode }) => {
    return Promise.resolve({
        code: 200,
        msg: "successful, please login in",
    });
};

const logout = ({ userName }) => {
    return Promise.resolve({
        code: 200,
        msg: "successful, please login in",
    });
};

const register = ({ userName, password, confirmPassword }) => {
    return Promise.resolve({
        code: 200,
        msg: "successful, please login in",
    });
};



export { login, loginByPhone, logout, register };