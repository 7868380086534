import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';


class Auction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: [{ name: "Marketplace", ico: "M16.9758 4.48294L15.086 3.68724C14.9865 3.6375 14.887 3.53804 14.887 3.43858L14.4395 1.44931C14.3897 1.15092 13.9919 1.05145 13.8427 1.30011L12.4999 2.8418C12.4005 2.94126 12.301 2.99099 12.2015 2.94126L10.1625 2.6926C9.86414 2.64287 9.66521 2.99099 9.81441 3.23965L10.8588 4.98026C10.9085 5.07972 10.9085 5.22892 10.8588 5.32838L10.0133 7.16846C9.86414 7.41712 10.1128 7.71551 10.4112 7.66577L12.4502 7.21819C12.5497 7.16846 12.6988 7.21819 12.7486 7.31765L14.2903 8.71014C14.4892 8.90907 14.887 8.75987 14.887 8.46148L15.0362 6.47221C15.0362 6.37275 15.1357 6.22355 15.2352 6.17382L17.0255 5.17919C17.2742 4.98026 17.2742 4.58241 16.9758 4.48294Z", sub: [{ name: "Popular collections", link: "/popular-collections" }, { name: "Popular collections", link: "/drops" }, { name: "Auctions" }] }, { name: "Insights", ico: "M18.3337 10H15.0003L12.5003 17.5L7.50033 2.5L5.00033 10H1.66699", sub: [{ name: "Stats", link: "/stats" }, { name: "My Watchlist", link: "/watchlist" }] }],

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }



  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left /> 
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">

              <div className="tw-px-3 tw-pt-8">
                <div className="tw-border tw-border-purple-3 tw-p-8 tw-rounded-2xl tw-flex tw-justify-around tw-flex-col md:tw-flex-row tw-gap-4">
                  <div className="tw-flex tw-flex-col tw-w-full tw-max-w-lg tw-py-8 xl:tw-self-center">
                    <h1 className="fw-800 fs-48px">The Sol Army GV Pass 2.0 Auction #24</h1>
                    <div className="fs-14px tw-mt-1">CREATED BY <span className="tw-text-pink-primary">The Sol Army</span></div><span className="tw-my-2 fs-14px tw-max-h-[128px] tw-overflow-hidden" style={{"textOverflow":"ellipsis","WebkitLineClamp":"4","display":"-webkit-box","WebkitBoxOrient":"vertical"}}><p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">A community-driven company with the objective to educate, innovate and pursue growth within Web3. Holding a GV Pass grants exclusive benefits. Winner gets a free GEN0 PFP. These 2.0 Passes have been upgraded already and include all new benefits! Check discord for more info.</p></span>
                    <div className="tw-mt-4">
                      <div className="tw-p-4 tw-bg-gray-200 tw-rounded-md tw-flex tw-justify-between tw-gap-10 tw-flex-col md:tw-flex-row">
                        <div className="tw-flex tw-flex-col"><span className="tw-text-light-gray-500 fs-14px">CURRENT BID</span><span className="tw-text-white-1 fw-800 fs-28px">15.00 SOL</span></div>
                        <div className="tw-flex tw-flex-col"><span className="tw-text-light-gray-500 fs-14px">AUCTION ENDS IN</span>
                          <div className="tw-flex tw-flex-row tw-basis-0 tw-gap-4">
                            <div className="tw-flex tw-flex-col"><span className="tw-text-white-1 fw-800 fs-28px">14</span><span className="tw-text-light-gray-500 fs-12px">Hours</span></div>
                            <div className="tw-flex tw-flex-col"><span className="tw-text-white-1 fw-800 fs-28px">00</span><span className="tw-text-light-gray-500 fs-12px">Minutes</span></div>
                            <div className="tw-flex tw-flex-col"><span className="tw-text-white-1 fw-800 fs-28px">09</span><span className="tw-text-light-gray-500 fs-12px">Seconds</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-mt-4 tw-flex tw-items-center tw-justify-between"><span><span className="tw-text-light-gray-500 fs-14px ">LAST BID BY&nbsp;</span><span className="tw-text-pink-primary fs-14px">5n4K...m78z</span></span><span className="tw-text-light-gray-500 fs-12px">2022/8/12 06:34</span></div>
                    <div className="md:tw-mt-auto xl:tw-mt-2 tw-pt-2">
                      <a href="/auction/the_sol_army_gv_pass_2.0_24">
                        <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_primary__22Ken" style={{width: "100%"}}>Place your bid</button>
                      </a>
                    </div>
                  </div>
                  <div className="border-radius-12px tw-overflow-hidden xl:tw-h-[700px] xl:tw-w-[700px] sm:tw-h-400px sm:tw-w-400px"><img draggable="false" loading="lazy" className="card-img-top SolanaNFTMedia_media__1RXR3 SolanaNFTMedia_center__YTvJM" src="https://img-cdn.magiceden.dev/rs:fill:700:700:0:0/plain/https://hxgkxp4mpxr2zqcygtsyijmv43ssivbbvcrdfripgi6hir3mjy.arweave.net/Pcyrv4x946zAWDTlhCWV5uUkVCG-oojLFDzI8dEdsTg" style={{"backgroundColor":"transparent"}} alt="1" /></div>
                </div>
              </div>

              <div className="tw-mt-10 tw-px-4 tw-w-full">
                <h2 className="tw-text-32px tw-font-800">Live Auctions</h2>
                <div className="tw-mt-4 tw-grid tw-gap-3 tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 4xl:tw-grid-cols-6">
                  <div className="p-0 text-center card auction-card tw-border-purple-3 border-radius-24px me">
                    <a href="/auction/the_sol_army_gv_pass_2.0_24">
                      <div className="image-over border-radius-12px"><img draggable="false" loading="lazy" className="card-img-top SolanaNFTMedia_media__1RXR3 SolanaNFTMedia_center__YTvJM" src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://hxgkxp4mpxr2zqcygtsyijmv43ssivbbvcrdfripgi6hir3mjy.arweave.net/Pcyrv4x946zAWDTlhCWV5uUkVCG-oojLFDzI8dEdsTg" style={{ "backgroundColor": "transparent" }} alt="1" /></div>
                      <div className="p-0 card-caption col-12">
                        <div className="px-4 pt-3 pb-3 card-body">
                          <h5 className="mb-2 fs-14px tw-font-bold" title="The Sol Army GV Pass 2.0 Auction #24">The Sol Army GV Pass 2.0 Auction #24</h5>
                          <div className="fs-12px tw-text-pink-primary tw-flex tw-content-center tw-justify-center tw-gap-1" title="The Sol Army">The Sol Army
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                              <path fill="#E42575" fillRule="evenodd" d="M5.655.33a.5.5 0 01.69 0l.492.469a.5.5 0 00.514.109l.64-.229a.5.5 0 01.63.28l.26.63a.5.5 0 00.424.308l.678.052a.5.5 0 01.462.513l-.02.68a.5.5 0 00.263.454l.599.323a.5.5 0 01.213.657l-.294.613a.5.5 0 00.054.522l.416.539a.5.5 0 01-.072.686l-.519.44a.5.5 0 00-.162.5l.16.66a.5.5 0 01-.345.598l-.652.192a.5.5 0 00-.351.39l-.123.669a.5.5 0 01-.558.406l-.674-.09a.5.5 0 00-.48.213l-.383.561a.5.5 0 01-.676.144l-.579-.357a.5.5 0 00-.524 0l-.58.357a.5.5 0 01-.675-.144l-.383-.561a.5.5 0 00-.48-.214l-.674.09a.5.5 0 01-.558-.405l-.123-.67a.5.5 0 00-.35-.39l-.653-.19a.5.5 0 01-.346-.598l.16-.662a.5.5 0 00-.161-.499l-.519-.44a.5.5 0 01-.072-.686l.416-.54a.5.5 0 00.055-.52L.5 4.575a.5.5 0 01.213-.657l.6-.323a.5.5 0 00.261-.454l-.02-.68a.5.5 0 01.463-.513l.678-.052a.5.5 0 00.424-.308l.26-.63a.5.5 0 01.63-.28l.64.229a.5.5 0 00.514-.11L5.655.33z"></path>
                              <g clipPath="url(#clip0_1538_39894)">
                                <path stroke="#120C18" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M8.5 4.5L5.062 7.938 3.5 6.375"></path>
                              </g>
                              <defs></defs>
                            </svg>
                          </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-bg-gray-200 tw-mx-3 tw-mb-3 tw-py-2 tw-px-4 tw-rounded-md ">
                          <div className="tw-flex tw-flex-col tw-justify-between tw-text-left"><span className="fs-12px tw-text-light-gray-500">CURRENT BID</span><span className="fs-14px tw-font-bold tw-text-white-1 tw-flex tw-items-center"><img width="12px" className="me-1" src="/static/media/solana-icon-gradient.fa522d66295471a41d0ae10b89993cf3.svg" alt="solana" />15.00 SOL</span></div>
                          <div className="tw-flex tw-flex-col tw-justify-between tw-text-right tw-w-36"><span className="fs-12px tw-text-light-gray-500">AUCTION ENDS IN</span><span className="fs-14px tw-font-bold tw-text-white-1 ">13h 47m 10s</span></div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


          <div className="tw-mt-10 tw-px-4 tw-w-full">
                <h2 className="tw-text-32px tw-font-800">Finished Auctions</h2>
                <div className="tw-mt-4 tw-grid tw-gap-3 tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 4xl:tw-grid-cols-6">

                {this.state.p1.map((item, index) => (

                  <div key={index} className="p-0 text-center card auction-card tw-border-purple-3 border-radius-24px me">
                    <a href="/auction/the_sol_army_gv_pass_2.0_24">
                      <div className="image-over border-radius-12px"><img alt="1" draggable="false" loading="lazy" className="card-img-top SolanaNFTMedia_media__1RXR3 SolanaNFTMedia_center__YTvJM" src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeia2y5y2nlxnge45ifousugfawxxxw4qg4krwbwg642bw4t4zbmopy.ipfs.dweb.link?ext=png" style={{ "backgroundColor": "transparent" }} /></div>
                      <div className="p-0 card-caption col-12">
                        <div className="px-4 pt-3 pb-3 card-body">
                          <h5 className="mb-2 fs-14px tw-font-bold" title="The Sol Army GV Pass 2.0 Auction #24">The Sol Army GV Pass 2.0 Auction #24</h5>
                          <div className="fs-12px tw-text-pink-primary tw-flex tw-content-center tw-justify-center tw-gap-1" title="The Sol Army">The Sol Army
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
                              <path fill="#E42575" fillRule="evenodd" d="M5.655.33a.5.5 0 01.69 0l.492.469a.5.5 0 00.514.109l.64-.229a.5.5 0 01.63.28l.26.63a.5.5 0 00.424.308l.678.052a.5.5 0 01.462.513l-.02.68a.5.5 0 00.263.454l.599.323a.5.5 0 01.213.657l-.294.613a.5.5 0 00.054.522l.416.539a.5.5 0 01-.072.686l-.519.44a.5.5 0 00-.162.5l.16.66a.5.5 0 01-.345.598l-.652.192a.5.5 0 00-.351.39l-.123.669a.5.5 0 01-.558.406l-.674-.09a.5.5 0 00-.48.213l-.383.561a.5.5 0 01-.676.144l-.579-.357a.5.5 0 00-.524 0l-.58.357a.5.5 0 01-.675-.144l-.383-.561a.5.5 0 00-.48-.214l-.674.09a.5.5 0 01-.558-.405l-.123-.67a.5.5 0 00-.35-.39l-.653-.19a.5.5 0 01-.346-.598l.16-.662a.5.5 0 00-.161-.499l-.519-.44a.5.5 0 01-.072-.686l.416-.54a.5.5 0 00.055-.52L.5 4.575a.5.5 0 01.213-.657l.6-.323a.5.5 0 00.261-.454l-.02-.68a.5.5 0 01.463-.513l.678-.052a.5.5 0 00.424-.308l.26-.63a.5.5 0 01.63-.28l.64.229a.5.5 0 00.514-.11L5.655.33z"></path>
                              <g clipPath="url(#clip0_1538_39894)">
                                <path stroke="#120C18" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M8.5 4.5L5.062 7.938 3.5 6.375"></path>
                              </g>
                              <defs></defs>
                            </svg>
                          </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-bg-gray-200 tw-mx-3 tw-mb-3 tw-py-2 tw-px-4 tw-rounded-md ">
                          <div className="tw-flex tw-flex-col tw-justify-between tw-text-left"><span className="fs-12px tw-text-light-gray-500">CURRENT BID</span><span className="fs-14px tw-font-bold tw-text-white-1 tw-flex tw-items-center"><img width="12px" className="me-1" src="/static/media/solana-icon-gradient.fa522d66295471a41d0ae10b89993cf3.svg" alt="solana" />15.00 SOL</span></div>
                          <div className="tw-flex tw-flex-col tw-justify-between tw-text-right tw-w-36"><span className="fs-12px tw-text-light-gray-500">AUCTION ENDS IN</span><span className="fs-14px tw-font-bold tw-text-white-1 ">13h 47m 10s</span></div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}


                </div>
              </div>




              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Auction;

