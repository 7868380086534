import React from 'react';
import './Home.css';


class Left extends React.Component {
  constructor(props) {
    super(props)
    let styleName = ""
    if (props.name !== undefined) {
      styleName = props.name
    }

    let bg = "rgba(8, 38, 59, 1)"
    if (props.bg !== undefined) {
      bg = props.bg
    }
    this.state = {
      styleName: styleName,
      bg: bg,
      menu: [
        {
          name: "Marketplace",
          path: ["M864 307.2c-19.2-38.4-89.6-44.8-192-19.2-44.8-38.4-96-51.2-153.6-51.2-153.6 0-281.6 121.6-281.6 275.2v6.4c-70.4 70.4-96 128-76.8 166.4 12.8 25.6 44.8 38.4 89.6 38.4 19.2 0 38.4 0 64-6.4h12.8c51.2 44.8 115.2 76.8 192 76.8 153.6 0 275.2-121.6 275.2-275.2 0-12.8 0-32-6.4-44.8 70.4-70.4 96-128 76.8-166.4z m-345.6-38.4c121.6 0 224 89.6 236.8 204.8-19.2 12.8-38.4 32-57.6 44.8-6.4-6.4-19.2-12.8-32-12.8-25.6 0-44.8 19.2-44.8 44.8 0 6.4 0 12.8 6.4 19.2-19.2 12.8-38.4 19.2-57.6 32-76.8 38.4-153.6 70.4-217.6 89.6-51.2-44.8-76.8-108.8-76.8-179.2 0-134.4 108.8-243.2 243.2-243.2z m-345.6 409.6c-12.8-25.6 6.4-76.8 64-134.4 6.4 57.6 32 115.2 70.4 153.6-70.4 12.8-115.2 6.4-134.4-19.2z m345.6 76.8c-57.6 0-108.8-19.2-147.2-51.2 64-19.2 140.8-44.8 211.2-89.6 19.2-12.8 38.4-19.2 57.6-32 6.4 6.4 19.2 12.8 32 12.8 25.6 0 44.8-19.2 44.8-44.8v-19.2c19.2-12.8 32-25.6 51.2-38.4V512c-12.8 134.4-121.6 243.2-249.6 243.2zM787.2 448c-19.2-64-51.2-115.2-96-153.6 83.2-19.2 140.8-12.8 160 19.2 12.8 25.6-12.8 76.8-64 134.4z"],
          sub: [
            { name: "Popular collections", link: "/popular-collections" },
            { name: "Drop calendar", link: "/drops" },
            { name: "Auctions", link: "/auction" }
          ],
          show: true,
        },
        {
          name: "Insights",
          path: ["M836.608 229.376c-52.224 0-95.232 42.496-95.232 94.72 0 26.624 11.264 50.688 28.672 68.096L660.48 526.848c-15.36-11.264-34.816-17.92-55.296-17.92-29.696 0-55.808 13.312-73.728 34.816l-61.44-83.968c24.576-17.408 40.448-45.568 40.448-77.824 0-52.224-42.496-94.72-95.232-94.72s-95.232 42.496-95.232 94.72c0 5.12 0.512 10.24 1.024 15.36L227.328 466.944c-17.408-17.92-41.472-29.184-68.608-29.184-52.224 0-95.232 42.496-95.232 94.72s42.496 94.72 95.232 94.72c52.224 0 95.232-42.496 95.232-94.72 0-13.312-2.56-25.6-7.68-36.864l87.552-64.512c16.896 27.648 46.592 45.568 81.408 45.568 7.68 0 15.36-1.024 22.528-2.56l75.776 103.936c-2.56 8.192-3.584 16.896-3.584 26.112 0 52.224 42.496 94.72 95.232 94.72 52.224 0 95.232-42.496 95.232-94.72 0-19.456-5.632-37.376-15.872-52.224l114.688-140.288c11.264 5.12 24.064 7.68 37.376 7.68 52.736 0 95.232-42.496 95.232-94.72 0-52.736-42.496-95.232-95.232-95.232zM159.232 576c-24.064 0-43.52-19.456-43.52-43.52s19.456-43.52 43.52-43.52 43.52 19.456 43.52 43.52-19.456 43.52-43.52 43.52z m212.48-194.048c0-24.064 19.456-43.52 43.52-43.52s43.52 19.456 43.52 43.52-19.456 43.52-43.52 43.52-43.52-19.456-43.52-43.52z m233.984 266.24c-24.064 0-43.52-19.456-43.52-43.52s19.456-43.52 43.52-43.52 43.52 19.456 43.52 43.52-19.456 43.52-43.52 43.52z m230.912-280.064c-24.064 0-43.52-19.456-43.52-43.52s19.456-43.52 43.52-43.52 43.52 19.456 43.52 43.52c0.512 24.064-18.944 43.52-43.52 43.52z"],
          sub: [
            { name: "Stats", link: "/stats" },
            { name: "My Watchlist", link: "/watchlist" }
          ],
          show: false,
        },
        {
          name: "Launchpad",
          path: ["M485.5 963.8c-0.9 0-1.9-0.1-2.8-0.2-8.5-1.1-15.5-7.3-17.7-15.6L334.4 459.8 30.8 295.3c-7.9-4.3-12.3-13.1-10.9-22 1.4-8.9 8.2-15.9 17.1-17.6L971.1 79.6c4.7-0.9 9.6-0.3 14.1 2.1 0.4 0.2 0.9 0.5 1.3 0.8 4.4 2.8 7.4 6.9 8.9 11.5 0.6 1.9 0.9 3.9 1 5.9 0.1 3.4-0.7 6.9-2.3 10.2-0.2 0.4-0.4 0.8-0.7 1.2L503.9 953.2c-3.9 6.6-10.9 10.6-18.4 10.6zM377.4 456l115.4 431.6L918 156.5 377.4 456zM103.6 286.4l248.8 134.8 497-275.4-745.8 140.6z", "M218.8 774.3c-4.4 0-8.8-1.4-12.6-4.1-8-5.9-10.9-16.5-6.9-25.6L333.1 437c4.7-10.8 17.2-15.7 28-11 10.8 4.7 15.7 17.2 11 28l-96.4 221.6L377.9 587c8.9-7.7 22.3-6.7 30 2.1 7.7 8.9 6.7 22.3-2.1 30l-173 150.1c-4.1 3.4-9.1 5.1-14 5.1z"],
          sub: [
            { name: "Launches", link: "/launchpad" },
            { name: "About", link: "/launchpad/about" },
            { name: "Apply for launchpad", link: "#" }
          ],
          show: false,
        },
        {
          name: "IME Games",
          path: ["M512 1020C232 1020 4 792 4 512S232 4 512 4 1020 232 1020 512 792 1020 512 1020z m0-984C249.6 36 36 249.6 36 512s213.6 476 476 476 476-213.6 476-476S774.4 36 512 36z", "M566.4 909.6c-1.6 0-4-0.8-5.6-1.6-1.6-1.6-2.4-4-2.4-6.4V620c0-3.2 1.6-5.6 4-7.2 30.4-15.2 52.8-44 60-76.8 0.8-2.4 2.4-4 4-5.6l260-133.6c2.4-0.8 4.8-0.8 7.2 0 2.4 0.8 4 3.2 4.8 4.8 10.4 36 15.2 72.8 15.2 110.4 0 199.2-148.8 370.4-346.4 397.6h-0.8z m8-284.8v268c184.8-30.4 323.2-192 323.2-380.8 0-32.8-4-64.8-12-96.8l-248 127.2c-8.8 35.2-31.2 64.8-63.2 82.4zM600.8 437.6c-2.4 0-4-0.8-5.6-2.4-21.6-24-51.2-36.8-83.2-36.8-31.2 0-61.6 13.6-83.2 36.8-2.4 2.4-6.4 3.2-9.6 1.6L174.4 311.2c-1.6-0.8-3.2-3.2-4-4.8-0.8-2.4 0-4.8 0.8-6.4C244.8 180.8 372.8 110.4 512 110.4c139.2 0 267.2 71.2 340.8 189.6 0.8 1.6 1.6 4 0.8 6.4-0.8 2.4-2.4 4-4 4.8L604.8 436.8c-1.6 0.8-2.4 0.8-4 0.8zM512 382.4c33.6 0 66.4 13.6 90.4 37.6l232-119.2c-72-109.6-192-174.4-323.2-174.4s-251.2 64.8-323.2 174.4l232 119.2c25.6-24 58.4-37.6 92-37.6zM457.6 909.6h-0.8C259.2 882.4 110.4 711.2 110.4 512c0-37.6 4.8-74.4 15.2-110.4 0.8-2.4 2.4-4 4.8-4.8 2.4-0.8 4.8-0.8 7.2 0l260 133.6c2.4 0.8 4 3.2 4 5.6 7.2 33.6 28.8 61.6 60 76.8 2.4 1.6 4 4 4 7.2v281.6c0 2.4-0.8 4.8-2.4 6.4-2.4 1.6-4 1.6-5.6 1.6zM138.4 415.2c-8 31.2-12 64-12 96.8 0 188.8 138.4 350.4 323.2 380.8V624.8c-32-17.6-54.4-47.2-63.2-82.4l-248-127.2z"],
          sub: [
            { name: "Home", link: "/games" },
            { name: "All Games", link: "/games/browse" },
            { name: "Lottery", link: "/games/lottery" },
          ],
          show: false,
        },

        {
          name: "Creators",
          path: ["M665.007158 433.340632a53.894737 53.894737 0 0 0-76.234105-76.207158l-85.746527 85.746526-85.746526-85.746526a53.894737 53.894737 0 0 0-76.207158 76.207158l161.953684 161.953684 161.953685-161.953684z m-161.953684-104.798316l28.56421-28.564211a134.736842 134.736842 0 1 1 190.544842 190.517895l-219.136 219.162947-219.109052-219.136a134.736842 134.736842 0 0 1 190.517894-190.544842l28.591158 28.564211zM161.684211 164.217263v380.685474a242.526316 242.526316 0 0 0 105.903157 200.380631l218.893474 149.234527 236.975158-151.444211A242.526316 242.526316 0 0 0 835.368421 538.677895V164.944842l-349.884632-56.050526L161.684211 164.217263z m-80.842106-68.176842L485.052632 26.947368l431.157894 69.093053V538.677895a323.368421 323.368421 0 0 1-149.234526 272.491789L485.052632 991.366737l-263.006316-179.280842A323.368421 323.368421 0 0 1 80.842105 544.902737V96.040421z"],
          sub: [
            { name: "Apply for listing", link: "#" },
            { name: "Apply for launchpad", link: "#" },
            { name: "Apply for auctions", link: "#" },
          ]
        },

        {
          name: "Intro to NFTs",
          path: ["M643.603 574.542H282.534c-6.575 0-11.875 8.923-11.875 19.875 0 10.96 5.3 19.813 11.875 19.813h361.069c6.56 0 11.864-8.852 11.864-19.813 0-10.962-5.305-19.875-11.864-19.875z m0 0M643.603 482.917H282.534c-6.575 0-11.875 8.887-11.875 19.882 0 10.957 5.3 19.814 11.875 19.814h361.069c6.56 0 11.864-8.858 11.864-19.814 0-10.995-5.305-19.882-11.864-19.882z m0 0M643.603 391.354H282.534c-6.575 0-11.875 8.862-11.875 19.823 0 10.956 5.3 19.849 11.875 19.849h361.069c6.56 0 11.864-8.893 11.864-19.85 0-10.96-5.305-19.822-11.864-19.822z m0 0", "M900.158 178c0-62.308-50.614-113.017-112.829-113.017H234.202c-0.258 0-0.483 0.039-0.764 0.055-0.263-0.016-0.508-0.055-0.771-0.055-60.57 0-109.856 50.709-109.856 113.017v660.709c0 8.328 3.264 16.26 9.085 22.003l89.752 89.056c11.51 11.398 29.718 11.398 41.226 0l91.565-90.85 94.998 94.243c11.512 11.448 29.736 11.448 41.247 0l95.004-94.244 95.012 94.244c5.754 5.71 13.191 8.58 20.598 8.58 7.429 0 14.879-2.87 20.633-8.58l86-85.308c5.806-5.778 9.083-13.706 9.083-21.998V286.96c47.845-13.138 83.144-56.963 83.144-108.961z m-193.3 719.933l-97.303-96.539c-11.766-11.706-30.41-11.706-42.2 0l-97.28 96.539-97.293-96.539c-11.788-11.706-30.431-11.706-42.223 0l-93.765 93.01-61.506-61V170.087c0-29.331 23.184-53.18 51.68-53.18 0.256 0 0.484-0.076 0.718-0.076 0.304 0 0.576 0.077 0.857 0.077h0.106c30.13 0.067 54.616 23.904 54.616 53.179 0 29.323-24.541 53.193-54.722 53.193-16.804 0-30.394 13.98-30.394 31.254 0 17.295 13.59 31.314 30.394 31.314h536v554.829l-57.686 57.255z m67.984-661.969l-473.477-3.028c28.834-14.134 28.854-42.54 28.834-51.081-0.073-31.884-4.91-45.984-13.04-61.698l474.026-0.798c29.74 0 60.765 31.187 60.765 60.085 0 28.89-31.4 55.044-61.138 55.044l-15.97 1.476z m0 0"],
          sub: [

          ],
          show: false,
        },


        {
          name: "Support",
          path: ["M512.001 60.669c-249.296 0-451.39 202.094-451.39 451.39s202.093 451.39 451.39 451.39 451.39-202.094 451.39-451.39C963.391 262.762 761.297 60.669 512.001 60.669zM512.001 927.337c-229.352 0-415.279-185.927-415.279-415.279S282.649 96.78 512.001 96.78 927.28 282.707 927.28 512.059 741.353 927.337 512.001 927.337zM506.315 174.287c-116.952 0-211.784 94.831-211.784 211.783 0 12.708 10.299 23.033 23.032 23.033 12.708 0 23.02-10.324 23.02-23.033l0 0c0.012-91.536 74.208-165.744 165.732-165.744 91.563 0 165.756 74.208 165.756 165.744 0 91.538-74.194 165.747-165.756 165.747-12.708 0-23.007 10.323-23.007 23.03l0 126.614c0 12.705 10.299 23.008 23.007 23.008 12.733 0 23.033-10.303 23.033-23.008L529.348 596.627c106.137-11.481 188.776-101.37 188.776-210.557C718.123 269.119 623.292 174.287 506.315 174.287zM506.338 763.146c-22.785 0-41.272 18.488-41.272 41.272 0 22.786 18.488 41.272 41.272 41.272 22.806 0 41.283-18.486 41.283-41.272C547.621 781.634 529.144 763.146 506.338 763.146zM512.001 60.554c-249.029 0-450.908 201.878-450.908 450.907 0 249.031 201.879 450.908 450.908 450.908 249.03 0 450.908-201.877 450.908-450.908C962.909 262.432 761.031 60.554 512.001 60.554zM512.001 915.294c-223.031 0-403.832-180.801-403.832-403.833 0-223.03 180.801-403.832 403.832-403.832 223.032 0 403.831 180.802 403.831 403.832C915.833 734.494 735.033 915.294 512.001 915.294z"],
          sub: [

          ],
          show: false,
        },

        {
          name: "Resources",
          path: ["M896 256v32L512 416 128 288v-32l384-128 384 128zM512 480L224 384l-96 32v32l384 128 384-128v-32l-96-32-288 96z m0 160L224 544l-96 32v32l384 128 384-128v-32l-96-32-288 96z m0 160L224 704l-96 32v32l384 128 384-128v-32l-96-32-288 96z"],
          sub: [
            { name: "About I META ID", link: "/about" },
            { name: "Blog", link: "#" },
            { name: "Platform status", link: "#" },
            { name: "Careers", link: "#" },
            { name: "API", link: "#" },
          ],
          show: false,
        },


      ]
    };
  }
  componentDidMount() {
    let pathname = window.location.pathname;
    let ps = pathname.split("/");
    if (ps.length > 1) {
      if (ps[1] !== "") {
        let menu = this.state.menu;
        menu.forEach(item => {
          let sub = item.sub;
          item.show = false;
          sub.forEach(item1 => {
            if (item1.link.indexOf(ps[1]) > -1) {
              item.show = true;
            }
          });
        });
        this.setState({
          menu: menu
        });
      }
    }
  }


  menu() {
    let data = this.state.menu;
    return (
      data.map((item, index) => (
        <li className="Sidebar_tab__1lv-Q tw-rounded-sm tw-text-white-1 tw-cursor-pointer tw-min-w-200px" key={index}>
          <div className="tw-flex tw-flex-col" onClick={() => this.showSubMenu(index)}>
            <div className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center"><span><svg width="20px" height="20px" viewBox="0 0 1026 1024" fill="none" xmlns="http://www.w3.org/2000/svg" color="#F5F3F7">

              {item.path.map((item1, index2) => (
                <path key={index2} d={item1} fill="#F5F3F7"></path>
              ))}

            </svg></span><span className="tw-text-white-1 tw-ml-2 Sidebar_label__Chew5">{item.name}</span><span className="tw-ml-auto">

                {item.show ? (
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#F5F3F7" className="tw-transform tw-rotate-180"><path d="M5 7.5L10 12.5L15 7.5" stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                ) :
                  (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#F5F3F7" className="tw-transform"><path d="M5 7.5L10 12.5L15 7.5" stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  )
                }
              </span></div>
            <div className="tw-pl-10 Sidebar_subMenu__PHJln"><div>
              {item.show ? (
                <ul className="tw-text-light-gray-500" >
                  {item.sub.map((item1, index2) => (
                    <li className="tw-font-medium" key={index2}><a href={item1.link}>{item1.name}</a></li>
                  ))}
                </ul>
              ) : ""}

            </div></div>
          </div>
        </li>
      ))
    )
  }

  showSubMenu(idx) {
    let menu = this.state.menu;
    menu.forEach(item => {
      item.show = false;
    });
    menu[idx].show = true;
    this.setState({
      menu: menu
    });
  }

  render() {
    return (
      <div id="sidebar" className={`tw-flex tw-fixed tw-z-20 Sidebar_sidebar__wxxb8 Sidebar_isMeTheme__uAp0q ${this.state.styleName}`} style={{ backgroundColor: this.state.bg }}>
        <div className=" tw-bg-gray-100 lg:tw-w-240px tw-overflow-x-hidden tw-flex tw-flex-col Sidebar_content__BnMM7" style={{ marginTop: "75px" }}>
          <div className="lg:tw-hidden tw-flex-shrink-0 tw-flex tw-justify-center tw-py-2 tw-border-gray-500 tw-border-solid tw-border-b">
            <div className="tw-flex tw-items-center ButtonGroup_group__UbpA8">
              <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER tw-p-0"><a className="tw-flex tw-w-full" href="/me"><span className="tw-px-2"><svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(228, 37, 117)" }}><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></span></a></button>
              <button className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 BorderedButton_btn__nenER">Connect Wallet</button>
            </div>
            <hr className="tw-my-4" />
          </div>
          <ul className="tw-flex-auto tw-px-4">
            {this.menu()}
          </ul>
          <div className="tw-flex tw-gap-2 Sidebar_sidebarFooter__2bHEK">
            <div className="Sidebar_slimMenu__FbHCD">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575">
                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#F5F3F7" strokeMiterlimit="10"></path>
                <path d="M10.4375 10C10.4375 10.2416 10.2416 10.4375 10 10.4375C9.75838 10.4375 9.5625 10.2416 9.5625 10C9.5625 9.75838 9.75838 9.5625 10 9.5625C10.2416 9.5625 10.4375 9.75838 10.4375 10Z" fill="#F5F3F7" stroke="#F5F3F7"></path>
                <path d="M14.1875 10C14.1875 10.2416 13.9916 10.4375 13.75 10.4375C13.5084 10.4375 13.3125 10.2416 13.3125 10C13.3125 9.75838 13.5084 9.5625 13.75 9.5625C13.9916 9.5625 14.1875 9.75838 14.1875 10Z" fill="#F5F3F7" stroke="#F5F3F7"></path>
                <path d="M6.6875 10C6.6875 10.2416 6.49162 10.4375 6.25 10.4375C6.00838 10.4375 5.8125 10.2416 5.8125 10C5.8125 9.75838 6.00838 9.5625 6.25 9.5625C6.49162 9.5625 6.6875 9.75838 6.6875 10Z" fill="#F5F3F7" stroke="#F5F3F7"></path>
              </svg>
            </div>

            {/*
                  <div className="Sidebar_mainMenu__xOAJE">
                    <div>
                      <a href="https://apps.apple.com/us/app/magic-eden-nft-marketplace/id1602924580" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575">
                          <path d="M5 3.125L5 16.875C5 17.5654 5.55964 18.125 6.25 18.125L13.75 18.125C14.4404 18.125 15 17.5654 15 16.875L15 3.125C15 2.43464 14.4404 1.875 13.75 1.875L6.25 1.875C5.55965 1.875 5 2.43464 5 3.125Z" fill="#F5F3F7" stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M9.9375 14.25C9.38522 14.25 8.9375 14.6977 8.9375 15.25C8.9375 15.8023 9.38522 16.25 9.9375 16.25C10.4898 16.25 10.9375 15.8023 10.9375 15.25C10.9375 14.6977 10.4898 14.25 9.9375 14.25Z" fill="#120C18"></path>
                        </svg><span>iOS App</span></a>
                      <a href="https://tiktok.com/@magicedenofficial" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-gap-1  tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575">
                          <path d="M10.3016 2.0135C11.1741 2 12.0416 2.008 12.9081 2C12.9606 3.0205 13.3276 4.06 14.0746 4.7815C14.8201 5.521 15.8746 5.8595 16.9006 5.974V8.6585C15.9391 8.627 14.9731 8.427 14.1006 8.013C13.7206 7.841 13.3666 7.6195 13.0201 7.393C13.0156 9.341 13.0281 11.2865 13.0076 13.2265C12.9556 14.1585 12.6481 15.086 12.1061 15.854C11.2341 17.1325 9.72063 17.966 8.16613 17.992C7.21263 18.0465 6.26013 17.7865 5.44763 17.3075C4.10113 16.5135 3.15363 15.06 3.01563 13.5C2.99963 13.1665 2.99413 12.8335 3.00763 12.508C3.12763 11.2395 3.75513 10.026 4.72913 9.2005C5.83313 8.239 7.37963 7.781 8.82763 8.052C8.84113 9.0395 8.80163 10.026 8.80163 11.0135C8.14013 10.7995 7.36713 10.8595 6.78913 11.261C6.36713 11.5345 6.04663 11.9535 5.87963 12.4275C5.74163 12.7655 5.78113 13.141 5.78913 13.5C5.94763 14.594 6.99963 15.5135 8.12263 15.414C8.86713 15.406 9.58063 14.974 9.96863 14.3415C10.0941 14.12 10.2346 13.8935 10.2421 13.633C10.3076 12.4405 10.2816 11.253 10.2896 10.0605C10.2951 7.373 10.2816 4.693 10.3021 2.014L10.3016 2.0135Z" fill="#F5F3F7"></path>
                        </svg><span>TikTok</span></a>
                      <a href="https://twitter.com/MagicEden" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-gap-1  tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" color="#f5f3f7" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                          <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                        </svg><span>Twitter</span></a>
                    </div>
                    <div>
                      <a href="https://discord.gg/magiceden" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-gap-1  tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" color="#f5f3f7" height="20" width="20" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                          <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                        </svg><span>Discord</span></a>
                      <a href="https://www.youtube.com/channel/UCOeUcnlgATreezd7jRB5w-g" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-gap-1  tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575">
                          <path d="M10.3033 15.9856L7.04083 15.9259C5.98451 15.9051 4.92557 15.9466 3.88996 15.7312C2.31456 15.4094 2.20296 13.8314 2.08617 12.5077C1.92526 10.6468 1.98755 8.75212 2.29122 6.90675C2.46266 5.8713 3.13731 5.25345 4.18065 5.18621C7.70266 4.94221 11.2481 4.97112 14.7623 5.08496C15.1335 5.0954 15.5071 5.15244 15.8731 5.21736C17.6796 5.53402 17.7237 7.32227 17.8408 8.82764C17.9575 10.3485 17.9082 11.8773 17.685 13.3878C17.5059 14.6385 17.1633 15.6873 15.7174 15.7886C13.9057 15.921 12.1356 16.0276 10.3188 15.9936C10.3189 15.9856 10.3085 15.9856 10.3033 15.9856ZM8.38528 12.8192C9.75053 12.0353 11.0897 11.2645 12.4472 10.4859C11.0794 9.70201 9.74272 8.9312 8.38528 8.15259V12.8192Z" fill="#F5F3F7"></path>
                        </svg><span>Youtube</span></a>
                      <a href="https://instagram.com/magicedenofficial" className="tw-flex tw-p-3 hover:tw-bg-gray-300 tw-relative tw-rounded tw-items-center tw-gap-1  tw-border tw-border-gray-500 tw-mb-2" target="_blank" rel="noreferrer">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" color="#F5F3F7" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                        </svg><span>Instagram</span></a>
                    </div>
                  </div>
    */}


          </div>
        </div>
      </div>
    );
  }
}
export default Left;
