import React, { useEffect, useState, useContext } from "react"
import { TransactionContext } from '../context/TransactionContext'
import Icon from "../Icon";
import { sellNft } from "../utils/contractUtils"

export default function SellNftBox({ item }) {
    const context = useContext(TransactionContext)

    const handlerSellNft = async (item) => {
        const { nftAddress, tokenId, price } = item;
        sellNft(nftAddress, tokenId, price).then(data => {
            console.log('sell NFT success', data)
        }, error => {
            console.log('sell NFT error', alert(error.message))
        });
    }

    return (
        <div
            className="tw-p-0 mb-4 grid-card grid-card__main tw-border tw-border-solid tw-border-gray-500 tw-rounded-xl tw-overflow-hidden"
            style={{
                width: "278px",
                height: "376px",
                transform: "translateX(16px) translateY(0px)",
                // position: "absolute",
                marginRight: "15px"
            }}
        >
            <div className="tw-flex-auto tw-flex tw-flex-col tw-w-full tw-group">
                <div
                    className="tw-rounded-xl tw-overflow-hidden me-flex-center"
                    style={{
                        width: "276px",
                        height: "276px",
                        maxHeight: "276px",
                        minHeight: "276px",
                    }}
                >
                    <div className="tw-absolute tw-w-full tw-cursor-pointer">
                        <img
                            draggable="false"
                            loading="lazy"
                            className="card-img-top NFTMedia_media__Xij2j tw-rounded-b-xl NFTMedia_center__uJfn8"
                            src={`${item.imageURL}`}
                            style={{ backgroundColor: "transparent" }}
                        />
                        <div className="tw-mr-2 tw-mt-3 tw-absolute tw-top-0 tw-right-0 tw-opacity-0 tw-w-5 tw-h-5 tw-rounded-full                      tw-flex tw-items-center tw-justify-center group-hover:tw-opacity-100 tw-transition-opacity tw-ease-in-out tw-duration-100 tw-bg-[rgba(40,40,40,0.3)]">
                            {/* <svg
                                stroke="currentColor"
                                fill="none"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                width="1em"
                                height="1em"
                                color="#f5f3f7"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(245, 243, 247)" }}
                            >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg> */}
                        </div>
                        <div className="tw-w-full tw-absolute  tw-justify-center tw-hidden md:tw-flex" style={{ bottom: "40px" }}>
                            <button onClick={() => handlerSellNft(item)}
                                type="button"
                                className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-flex tw-items-center tw-relative !tw-rounded-full tw-text-sm tw-drop-shadow tw-text-white-2 tw-w-[112px]           tw-opacity-0 hover:tw-drop-shadow-md tw-transition-[background-color] tw-ease-in-out tw-duration-100 group-hover:tw-opacity-100 enforcement-trigger tw-w-[112px] tw-w-4/5 tw-max-w-[250px]"
                                style={{
                                    backgroundColor: "rgb(43, 32, 55)",
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 24 24"
                                    fill="#f5f3f7"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="tw-rotate-6 tw-scale-y-125 tw-mr-1 tw-relative tw-right-[2px]"
                                    color="#f5f3f7"
                                >
                                    <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                                </svg>
                                Sell
                            </button>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-justify-end tw-w-full tw-absolute tw-bottom-1 tw-gap-1.5 tw-cursor-default tw-pr-2"></div>
                    </div>
                </div>
            </div>
            <div>
                <div className="tw-flex tw-flex-initial flex-column tw-p-3 tw-pb-0">
                    <a href="/">
                        <h6
                            title="Fidenza #1"
                            className="tw-flex tw-items-center tw-relative mb-0 tw-truncate grid-card__title tw-font-bold tw-leading-none !tw-text-sm"
                        >
                            <div
                                title="Fidenza #1"
                                className="cursor-pointer d-inline-flex align-items-center tw-whitespace-nowrap tw-w-full tw-text-white-2 tw-font-bold tw-text-sm"
                            >
                                <div className="tw-flex tw-justify-between tw-space-x-1 tw-w-full">
                                    <div className="tw-truncate">
                                        {item?.name}
                                    </div>
                                    <div className="tw-flex tw-space-x-1 tw-justify-end tw-items-center">
                                        <div>#{item?.tokenId}</div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </h6>
                    </a>
                    {/* <div className="mt-auto">
                        <a
                            className="tw-flex tw-items-center tw-mr-2 collection-mark tw-cursor-text !tw-opacity-100"
                            href="/collections/eth/0x28f139197e8e916ca79b42935e6caceaa75dee56"
                        >
                            <span className="fs-80 tw-truncate tw-mr-1">
                                SPLOOT by De la Dondi
                            </span>
                        </a>
                    </div> */}
                    <div className="tw-truncate tw-mt-2.5">
                        <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
                            <span className="tw-flex tw-items-center tw-font-bold tw-gap-1 tw-text-white-2 tw-text-sm tw-min-w-0">
                                <div>
                                    <Icon name={"ETH"}></Icon>
                                </div>
                                <div
                                    className="tw-truncate"
                                    style={{ display: "inline" }}
                                >
                                    <div className="tw-flex tw-items-center tw-gap-1 tw-rounded-md">
                                        <span className="tw-truncate tw-space-x-2 tw-flex tw-justify-center tw-items-center">
                                            <span>{item?.price}</span>
                                        </span>
                                        <span className="tw-flex-shrink-0"></span>
                                    </div>
                                </div>
                            </span>
                            <div>
                                <div
                                    className=""
                                    data-tooltipped=""
                                    aria-describedby="tippy-tooltip-3"
                                    style={{ display: "inline" }}
                                >
                                    {/* <button
                                        className="tw-text-sm tw-text-pink-primary tw-border tw-border-solid tw-shadow \ tw-border-gray-500 hover:tw-bg-pink-primary hover:tw-border-pink-dark hover:tw-text-white-2 \ tw-px-1.5 tw-py-0.5 tw-rounded tw-transition-[background-color,border-color,color] tw-ease-in-out tw-duration-100 tw-hidden xs:tw-block"
                                        onClick={() => console.log("click")}
                                    >
                                        Details
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-absolute tw-left-2 tw-top-2 tw-p-[2pt] tw-z-0 tw-flex tw-space-x-1">
                <div
                    className=""
                    data-tooltipped=""
                    aria-describedby="tippy-tooltip-4"
                    style={{ display: "inline" }}
                >
                    <Icon name="OpenSea" />
                </div>
            </div>
        </div>)
}
