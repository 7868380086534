import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom'
import { ethers } from "ethers";
// import { getListing } from "./services/nftService";
import axios from "axios";
import Header from "./Header";
import Left from "./Left";
import "./Home.css";
import "./Marketplace.css";
import { TransactionContext } from './context/TransactionContext'
import { updateNftInfo, buyNft } from "./utils/contractUtils"
import Icon from "./Icon";

function NFTBox({ item, index, handlerBuyNft }) {
  return (
    <div key={index}
      className="tw-p-0 mb-4 grid-card grid-card__main tw-border tw-border-solid tw-border-gray-500 !tw-duration-100 tw-rounded-xl tw-overflow-hidden"
      style={{
        width: "283px",
        height: "381px",
        // transform: "translateX(" + index * 300 + "px) translateY(0px)",
        // position: "absolute",
        marginLeft: "12px"
      }}
    >
      <div className="tw-flex-auto tw-flex tw-flex-col tw-w-full tw-group">
        <div
          className="tw-rounded-xl tw-overflow-hidden me-flex-center"
          style={{
            width: "281px",
            height: "281px",
            maxHeight: "281px",
            minHeight: "281px",
          }}
        >
          <div className="tw-absolute tw-w-full tw-cursor-pointer">
            <img
              draggable="false"
              loading="lazy"
              className="card-img-top SolanaNFTMedia_media__1RXR3 tw-rounded-b-xl SolanaNFTMedia_center__YTvJM"
              src={`${item?.imageURL}`}
              style={{ backgroundColor: "gray" }}
              alt="1"
            />
            <div className="tw-mr-2 tw-mt-3 tw-absolute tw-top-0 tw-right-0 tw-opacity-0 tw-w-5 tw-h-5 tw-rounded-full                      tw-flex tw-items-center tw-justify-center group-hover:tw-opacity-100 tw-transition-opacity tw-ease-in-out tw-duration-100 tw-bg-[rgba(40,40,40,0.3)]">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="1em"
                height="1em"
                color="#f5f3f7"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  color: "rgb(245, 243, 247)",
                }}
              >
                <line
                  x1="12"
                  y1="5"
                  x2="12"
                  y2="19"
                ></line>
                <line
                  x1="5"
                  y1="12"
                  x2="19"
                  y2="12"
                ></line>
              </svg>
            </div>
            <div className="tw-w-full tw-absolute tw-bottom-5 tw-justify-center tw-hidden md:tw-flex">
              <button onClick={() => handlerBuyNft(item)}
                type="button"
                className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 !tw-rounded-full tw-text-sm tw-drop-shadow tw-text-white-2 tw-w-[112px]           tw-opacity-0 hover:!tw-bg-pink-dark hover:tw-drop-shadow-md tw-transition-[background-color] tw-ease-in-out tw-duration-100 group-hover:tw-opacity-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="#f5f3f7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="tw-rotate-6 tw-scale-y-125 tw-mr-1 tw-relative tw-right-[2px]"
                  color="#f5f3f7"
                >
                  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                </svg>
                Quick buy
              </button>
            </div>
            <div className="tw-flex tw-justify-end tw-w-full tw-absolute tw-bottom-0 tw-translate-y-2/4 tw-gap-1.5 tw-cursor-default tw-pr-2"></div>
          </div>
        </div>
      </div>
      <div>
        <div className="tw-flex tw-flex-initial flex-column tw-p-3 tw-pb-0">
          <a href="/item-details/HXKcq7yshw5Muwz4sqJXAWYkWi6qbVib8WvRd4J7sctm?name=Genesis-Habitat-%234856">
            <h6
              title="Genesis Habitat #4856"
              className="mb-0 tw-truncate grid-card__title tw-font-bold tw-leading-none !tw-text-sm"
            >
              {item?.name} #{item?.tokenId}
            </h6>
          </a>
          <div className="mt-auto">
            <a
              className="tw-flex tw-items-center tw-mr-2 collection-mark tw-cursor-text !tw-opacity-100"
              href="/marketplace/genesis_genopets_habitats"
            >
              <span className="fs-80 tw-truncate tw-mr-1">
                {item?.name}
              </span>
            </a>
          </div>
          <div className="tw-truncate tw-mt-2.5">
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
              <span className="tw-flex tw-items-center tw-font-bold tw-gap-1 tw-text-white-2 tw-text-sm tw-min-w-0">
                <div>
                  <Icon name={"ETH"} />
                </div>
                <div
                  className="tw-truncate tw-cursor-help"
                  data-tooltipped=""
                  aria-describedby="tippy-tooltip-298"
                  style={{ display: "inline" }}
                >
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <span className="tw-truncate">
                      {item?.price}
                    </span>
                    <span className="tw-flex-shrink-0"></span>
                  </div>
                </div>
              </span>
              <div>
                <div
                  className=""
                  data-tooltipped=""
                  aria-describedby="tippy-tooltip-299"
                  style={{ display: "inline" }}
                >
                  <a
                    className="tw-text-sm tw-text-pink-primary tw-border tw-border-solid tw-shadow \ tw-border-gray-500 hover:tw-bg-pink-primary hover:tw-border-pink-dark hover:tw-text-white-2 \ tw-px-1.5 tw-py-0.5 tw-rounded tw-transition-[background-color,border-color,color] tw-ease-in-out tw-duration-100 tw-hidden xs:tw-block"
                    href="/marketplace/details/NFTLeaguez/"
                  >
                    Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}


export default function Marketplace() {
  const context = useContext(TransactionContext)
  const [listedNft, setListedNft] = useState([])
  const { address: nftAddress } = useParams()

  const loadNftsByGraph = () => {
    if (nftAddress) {
      const query = `query {
        activeItems(
          where: {buyer: "0x0000000000000000000000000000000000000000",nftAddress:"${nftAddress.toLowerCase()}"}
        ) { id buyer seller nftAddress tokenId price }
      }`;
      axios.post(process.env.REACT_APP_PUBLIC_SUBGRAPH_URL, { query, }).then(data => {
        // console.log(`@123`, data.data.data.activeItems)
        if (data?.data?.data?.activeItems) {
          updateNftInfo(data?.data?.data?.activeItems)
            .then(data => data.map(item => ({ ...item, price: ethers.utils.formatEther(item.price) })))
            .then(data => {
              setListedNft(data);
            });
        }
      });
    }
  }

  const handlerBuyNft = (item) => {
    const { nftAddress, tokenId, price } = item
    console.log('item', item)
    buyNft(nftAddress, tokenId, price).then(
      data => {
        console.log('buy success', data)
        loadNfts();
      },
      error => console.log('buy error', error))
  }

  const loadNfts = () => {
    loadNftsByGraph();
  }

  useEffect(() => {
    loadNfts();
  }, [])




  return (
    <div className="App">
      <div className="main page tw-bg-[#120d18]">
        <Header />
        <div className="tw-flex tw-w-full tw-flex-auto">
          <Left
            name="Sidebar_slim__EH6w7"
            style={{ backgroundColor: "rgba(16, 10, 21, 0.3)" }}
          />
          <div
            id="content"
            className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__slim lg:tw-ml-80px"
          >
            <div
              className=""
              style={{
                position: "fixed",
                top: "0px",
                left: "0px",
                height: "2px",
                background: "transparent",
                zIndex: "2147483647",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  background: "red",
                  width: "0%",
                  opacity: "1",
                }}
              >
                <div
                  style={{
                    boxShadow: "red 0px 0px 10px, red 0px 0px 10px",
                    width: "5%",
                    opacity: "1",
                    position: "absolute",
                    height: "100%",
                    transform: "rotate(3deg) translate(0px, -4px)",
                    left: "-5.5%",
                  }}
                ></div>
              </div>
            </div>
            <div className="tw-w-full tw-py-0 sm:tw-mt-0">
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-auto tw-items-center md:tw-items-start tw-flex-col md:tw-flex-row tw-px-4 sm:tw-pl-8 md:tw-pt-16 lg:tw-pl-10 2xl:tw-pl-12 tw-pb-8 md:tw-pb-24">
                  <div className="tw-min-w-[170px] tw-max-w-[170px] tw-min-h-[170px] tw-max-h-[170px] tw-rounded-full tw-overflow-auto">
                    <img
                      draggable="false"
                      loading="lazy"
                      className="tw-object-center tw-object-cover tw-min-w-[170px] tw-max-w-[170px] tw-min-h-[170px] tw-max-h-[170px] tw-rounded-full tw-overflow-auto"
                      src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/genesis_genopets_habitats_pfp_1649105768353.gif"
                      alt="avatar"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                  <div className="tw-grid tw-grid-cols-1 2xl:tw-grid-cols-2 tw-mt-5 md:tw-mt-0 md:tw-ml-6 lg:tw-ml-14 2xl:tw-ml-24">
                    <div className="tw-flex tw-justify-center md:tw-justify-start">
                      <h1 className="tw-font-extrabold tw-text-[32px] tw-leading-[120%] tw-text-center">
                        Genesis Genopets Habitats
                      </h1>
                    </div>
                    <div className="tw-mt-1 md:tw-mt-4 tw-space-y-7 tw-flex tw-flex-col sm:tw-items-center md:tw-items-start tw-row-start-2">
                      <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-[250px,250px] tw-gap-3">
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="FLOOR"
                            >
                              FLOOR
                            </span>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-94"
                              data-original-title="38.90 ◎"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="38.90 ◎"
                              >
                                38.90 ◎
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="LISTED"
                            >
                              LISTED
                            </span>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-95"
                              data-original-title="119"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="119"
                              >
                                119
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="TOTAL VOL"
                            >
                              TOTAL VOL
                            </span>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-96"
                              data-original-title="143,572.17 ◎"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="143,572.17 ◎"
                              >
                                143,572.17 ◎
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="AVG. SALE (24h)"
                            >
                              AVG. SALE (24h)
                            </span>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-97"
                              data-original-title="37.87 ◎"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="37.87 ◎"
                              >
                                37.87 ◎
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="OWNERS"
                            >
                              OWNERS
                            </span>
                            <div
                              className=""
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-98"
                              data-original-title="Holders that have their NFTs custodially staked are not part of this calculation. NFTs listed with I META ID are taken into account."
                              style={{ display: "inline" }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                color="#a89db4"
                                height="1rem"
                                width="1rem"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(168, 157, 180)" }}
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="16" x2="12" y2="12"></line>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                              </svg>
                            </div>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-99"
                              data-original-title="4,117"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="4,117"
                              >
                                4,117
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="tw-bg-gray-200 tw-p-2 tw-space-x-2 tw-rounded-[4px] tw-flex tw-items-center tw-justify-between tw-flex-col sm:tw-flex-row">
                          <div className="tw-flex tw-justify-center tw-space-x-2">
                            <span
                              className="tw-text-xs tw-text-light-gray-500 tw-whitespace-nowrap"
                              title="TOTAL SUPPLY"
                            >
                              TOTAL SUPPLY
                            </span>
                          </div>
                          <div className="tw-flex tw-space-x-2 tw-items-center tw-max-w-full">
                            <div
                              className="tw-cursor-help tw-max-w-full tw-truncate"
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-100"
                              data-original-title="5.5K"
                              style={{ display: "inline" }}
                            >
                              <span
                                className="tw-text-white-1 tw-text-14px tw-truncate"
                                title="5.5K"
                              >
                                5.5K
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-flex tw-flex-col sm:tw-max-w-[500px] 2xl:tw-row-start-2 tw-mt-5 2xl:tw-ml-10">
                      <div className="2xl:tw-pl-6 2xl:tw-border-l 2xl:tw-border-gray-400">
                        <div className="tw-flex tw-flex-wrap tw-gap-3 tw-items-center tw-justify-center md:tw-justify-start">
                          <div
                            className=""
                            data-tooltipped=""
                            aria-describedby="tippy-tooltip-87"
                            data-original-title="Discord"
                            style={{ display: "inline" }}
                          >
                            <a href="#" target="_blank" rel="noreferrer">
                              <div className="tw-flex tw-items-center tw-bg-gray-100 tw-border tw-border-gray-300 tw-p-1.5 tw-rounded">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 640 512"
                                  color="#fff"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                >
                                  <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path>
                                </svg>
                              </div>
                            </a>
                          </div>
                          <div
                            className=""
                            data-tooltipped=""
                            aria-describedby="tippy-tooltip-88"
                            data-original-title="Twitter"
                            style={{ display: "inline" }}
                          >
                            <a href="#" target="_blank" rel="noreferrer">
                              <div className="tw-flex tw-items-center tw-bg-gray-100 tw-border tw-border-gray-300 tw-p-1.5 tw-rounded">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 512 512"
                                  color="#fff"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                >
                                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                </svg>
                                <span className="tw-px-[6px] tw-py-[2px] tw-ml-3 tw-rounded tw-bg-gray-300 tw-font-medium tw-text-xs tw-text-white-2">
                                  174.2K
                                </span>
                              </div>
                            </a>
                          </div>
                          <div
                            className=""
                            data-tooltipped=""
                            aria-describedby="tippy-tooltip-89"
                            data-original-title="Website"
                            style={{ display: "inline" }}
                          >
                            <a href="#" target="_blank" rel="noreferrer">
                              <div className="tw-flex tw-items-center tw-bg-gray-100 tw-border tw-border-gray-300 tw-p-1.5 tw-rounded">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  color="#fff"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(255, 255, 255)" }}
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="2" y1="12" x2="22" y2="12"></line>
                                  <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                </svg>
                              </div>
                            </a>
                          </div>
                          <div
                            className=""
                            data-tooltipped=""
                            aria-describedby="tippy-tooltip-90"
                            data-original-title="Watch"
                            style={{ display: "inline" }}
                          >
                            <button className="tw-flex tw-items-center tw-border-gray-300 tw-bg-gray-100 tw-border tw-border-gray-300 tw-p-1.5 tw-rounded">
                              <svg
                                width="20"
                                height="16"
                                viewBox="0 0 20 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                color="#fff"
                                size="16"
                              >
                                <path
                                  d="M7.9231 4.80493H12.077"
                                  stroke="#F5F3F7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M18.7923 10.1963L15.0106 1.603C14.6201 1.21668 14.093 1 13.5437 1C12.9945 1 12.4674 1.21668 12.0769 1.603V11.3819"
                                  stroke="#F5F3F7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M7.92292 11.3819V1.603C7.53246 1.21668 7.00536 1 6.45609 1C5.90682 1 5.37972 1.21668 4.98926 1.603L1.20752 10.1963"
                                  stroke="#F5F3F7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M4.46155 14.8434C6.37331 14.8434 7.92309 13.2936 7.92309 11.3819C7.92309 9.47011 6.37331 7.92032 4.46155 7.92032C2.54979 7.92032 1 9.47011 1 11.3819C1 13.2936 2.54979 14.8434 4.46155 14.8434Z"
                                  stroke="#F5F3F7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M15.5385 14.8434C17.4502 14.8434 19 13.2936 19 11.3819C19 9.47011 17.4502 7.92032 15.5385 7.92032C13.6267 7.92032 12.0769 9.47011 12.0769 11.3819C12.0769 13.2936 13.6267 14.8434 15.5385 14.8434Z"
                                  stroke="#F5F3F7"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                              <span className="tw-text-xs tw-font-medium tw-ml-2 tw-text-white-2">
                                Watch
                              </span>
                              <span className="tw-px-[6px] tw-py-[2px] tw-ml-3 tw-rounded tw-bg-gray-300 tw-font-medium tw-text-xs tw-text-white-2">
                                943
                              </span>
                            </button>
                          </div>
                          <div className="me-dropdown-container tw-w-min tw-flex tw-items-center">
                            <div className="cursor-pointer position-relative">
                              <button type="button">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  color="#f5f3f7"
                                  height="16"
                                  width="16"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(245, 243, 247)" }}
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="12" cy="5" r="1"></circle>
                                  <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                              </button>
                            </div>
                            <div
                              aria-label="dropdown-list"
                              className="dropdown tw-text-secondary no-border"
                              data-popper-reference-hidden="false"
                              data-popper-escaped="false"
                              data-popper-placement="bottom"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto 0px",
                                width: "165px",
                                transform: "translate(1334px, 267px)",
                              }}
                            >
                              <div
                                className=""
                                data-tooltipped=""
                                aria-describedby="tippy-tooltip-91"
                                data-original-title="Flag"
                                style={{ display: "inline" }}
                              >
                                <button className="tw-flex tw-items-center tw-bg-gray-200 tw-px-2.5 tw-py-2 tw-space-x-3 tw-w-[100%]">
                                  <svg
                                    viewBox="0 0 14.8 18.2"
                                    color="#f5f3f7"
                                    width="16"
                                    className="tw-p-0.5"
                                  >
                                    <g>
                                      <path
                                        fill="none"
                                        stroke="#f5f3f7"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M0.8,17.4v-5.8"
                                      ></path>
                                      <path
                                        fill="#f5f3f7"
                                        stroke="#f5f3f7"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M0.8,11.6 c0,0,0.8-0.8,3.3-0.8s4.2,1.7,6.7,1.7s3.3-0.8,3.3-0.8v-10c0,0-0.8,0.8-3.3,0.8S6.6,0.8,4.1,0.8S0.8,1.6,0.8,1.6V11.6z"
                                      ></path>
                                    </g>
                                  </svg>
                                  <span>Flag</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tw-max-w-3xl tw-text-white-2 2xl:tw-mt-3">
                          <div>
                            <span>
                              Genesis Habitats are a playable collection of
                              the first 5,537 created that have unique
                              abilities and in-game boosts like crafting all
                              types of Crystals. A Habitat unlocks advanced
                              gameplay like earning KI Token for steps,
                              creating more Habitats, and crafting in-game
                              NFTs. Genopets is a move-to-earn{" "}
                            </span>
                            <span>...</span>
                            <button className="tw-text-sm tw-cursor-pointer tw-text-pink-primary tw-ml-1">
                              Read more
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="modal"
                  aria-hidden="true"
                  style={{ backdropFilter: "blur(1px)" }}
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content position-relative tw-bg-gray-100 tw-rounded-20px tw-p-2 tw-border-gray-500">
                      <div className="modal-header">
                        <div>
                          <h2 className="tw-text-xl tw-font-bold">
                            Report this collection
                          </h2>
                        </div>
                        <span
                          aria-hidden="true"
                          className="modal-close-btn"
                        ></span>
                      </div>
                      <div className="modal-body">
                        <div className="d-flex flex-column">
                          <div className="tw-text-white-1 tw-font-bold">
                            I think this collection is
                          </div>
                          <div className="me-dropdown-container tw-mt-2 theme-me-dark-1">
                            <div className="cursor-pointer position-relative">
                              <input
                                defaultValue=""
                                className="tw-truncate tw-select-none no-border"
                                placeholder="Select a reason"
                              />
                              <div className="d-flex align-items-center h-100 chevron-down">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  color="#f5f3f7"
                                  height="24"
                                  width="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(245, 243, 247)" }}
                                >
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </div>
                            <div
                              aria-label="dropdown-list"
                              className="dropdown tw-text-secondary no-border"
                              data-popper-reference-hidden="true"
                              data-popper-escaped="true"
                              data-popper-placement="bottom-start"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto 0px",
                                width: "0px",
                                transform: "translate(0px, 4px)",
                              }}
                            >
                              <div className="me-select dark-scroll-bar">
                                <div
                                  tabIndex="0"
                                  className="me-select-item tw-flex"
                                >
                                  Fake or possible scam
                                </div>
                                <div
                                  tabIndex="0"
                                  className="me-select-item tw-flex"
                                >
                                  Explicit and sensitive content
                                </div>
                                <div
                                  tabIndex="0"
                                  className="me-select-item tw-flex"
                                >
                                  IP infringement
                                </div>
                                <div
                                  tabIndex="0"
                                  className="me-select-item tw-flex"
                                >
                                  Derivative
                                </div>
                                <div
                                  tabIndex="0"
                                  className="me-select-item tw-flex"
                                >
                                  Other
                                </div>
                              </div>
                            </div>
                          </div>
                          <textarea
                            className="input-dark-1 tw-mt-2"
                            placeholder="More details"
                            defaultValue=""
                          ></textarea>
                          <button
                            type="button"
                            className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-mt-8 PlainButton_disabled__o-JAq PlainButton_primary__22Ken"
                            disabled=""
                          >
                            Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row tw-mt-4">
                <div className="tw-flex tw-justify-center tw-border-gray-300 tw-border-b tw-border-solid"></div>
              </div>
              <div className="tw-flex tw-relative">
                {/* <div
                  className="tw-sticky tw-top-0 tw-self-start tw-hidden md:tw-flex tw-h-[calc(100vh-81px)] tw-overflow-y-auto tw-bg-gray-100 tw-border-b tw-border-gray-300 tw-top-[81px]"
                  style={{ width: "300px" }}
                >
                  <div className="tw-overflow-y-auto tw-flex-1 dark-scroll-bar">
                    <div className="tw-flex tw-flex-col">
                      <header className="tw-flex tw-p-4 tw-text-lg tw-border-solid tw-sticky tw-top-0 tw-cursor-pointer tw-z-50 tw-border-r tw-bg-gray-100 hover:tw-bg-gray-200 tw-border-gray-300">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="#f5f3f7"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "rgb(245, 243, 247)" }}
                        >
                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                        <div className="tw-cursor-pointer tw-ml-auto hover:tw-opacity-80">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#f5f3f7"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "rgb(245, 243, 247)" }}
                          >
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </div>
                      </header>
                      <div className="tw-flex tw-flex-col tw-relative">
                        <div className="tw-flex tw-justify-between tw-p-4 tw-font-medium tw-text-sm tw-border-t tw-border-r tw-border-gray-300">
                          <span className="tw-text-white-2">Buy now</span>
                          <div className="tw-flex tw-gap-2">
                            <div className="tw-flex tw-align-center tw-gap-x-1">
                              <input
                                className="!tw-h-0 !tw-w-0 tw-invisible toggle-checkbox"
                                id="all-toggle"
                                type="checkbox"
                              />
                              <label className="toggle-label">
                                <span className="toggle-button"></span>
                              </label>
                            </div>
                            <div
                              className=""
                              data-tooltipped=""
                              aria-describedby="tippy-tooltip-92"
                              data-original-title="Enable to show only NFTs that you can buy now"
                              style={{ display: "inline" }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                color="#665676"
                                height="24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(102, 86, 118)" }}
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line
                                  x1="12"
                                  y1="17"
                                  x2="12.01"
                                  y2="17"
                                ></line>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Price
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "initial" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-py-4">
                                <div className="me-dropdown-container tw-mb-3">
                                  <div className="cursor-pointer position-relative">
                                    <input
                                      defaultValue="SOL"
                                      className="tw-truncate tw-select-none no-border"
                                      disabled=""
                                    />
                                    <div className="d-flex align-items-center h-100 chevron-down">
                                      <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        color="#f5f3f7"
                                        height="24"
                                        width="24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          color: "rgb(245, 243, 247)",
                                        }}
                                      >
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                      </svg>
                                    </div>
                                  </div>
                                  <div
                                    aria-label="dropdown-list"
                                    className="dropdown tw-text-secondary no-border"
                                    data-popper-reference-hidden="true"
                                    data-popper-escaped="true"
                                    data-popper-placement="top-start"
                                    style={{
                                      position: "absolute",
                                      inset: "auto auto 0px 0px",
                                      width: "253px",
                                      transform: "translate(96px, 210px)",
                                    }}
                                  >
                                    <div className="me-select dark-scroll-bar">
                                      <div
                                        tabIndex="0"
                                        className="me-select-item tw-flex me-selected-item fw-500"
                                      >
                                        <span>
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            color="#e42575"
                                            className="me-10px"
                                          >
                                            <path
                                              d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M22 4L12 14.01L9 11.01"
                                              stroke="currentColor"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </span>
                                        SOL
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tw-flex tw-items-center tw-mb-3">
                                  <input
                                    type="number"
                                    placeholder="Min"
                                    className="tw-border tw-border-light-gray-100 focus:tw-border-light-gray-100 tw-rounded-lg tw-text-white-2 tw-placeholder-light-gray-100"
                                  />
                                  <span className="to-connector">to</span>
                                  <input
                                    type="number"
                                    placeholder="Max"
                                    className="tw-border tw-border-light-gray-100 focus:tw-border-light-gray-100 tw-rounded-lg tw-text-white-2 tw-placeholder-light-gray-100"
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_bordered__3suwZ"
                                  style={{ width: "100%" }}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Crystal Cap
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Crystals Available To Refine
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Element
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Wood
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        35
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      38.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.63%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Water
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        24
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      39.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.43%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Fire
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        22
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      40.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.40%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Metal
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        22
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      40.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.40%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Earth
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        16
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.69 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.29%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Ki Available To Harvest
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      40
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Ki Cap
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      40
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Level
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        119
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      38.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        2.15%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Royalty
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      0%
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Seeds Spawned
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      false
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Sequence
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4165
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      38.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1856
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      38.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2959
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      39.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1641
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      39.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4103
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      39.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5034
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      40.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3072
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      40.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5505
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      40.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4959
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      41.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2892
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      41.41 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      132
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      42.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2476
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      42.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1302
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      43.80 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      729
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      44.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      262
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      44.80 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3424
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      45.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2623
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      45.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      834
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2027
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.50 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      149
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.69 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1475
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5397
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2030
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2009
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      48.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      484
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      48.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      487
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      48.50 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2877
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      49.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3377
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      49.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4183
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      50.59 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2155
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      51.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3974
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      55.55 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1076
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      59.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      31
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      60.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      331
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      60.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      436
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      62.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1242
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      63.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      773
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      64.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      264
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      65.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3901
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      65.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3653
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      65.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      640
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      69.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      299
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      69.90 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4258
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      69.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4266
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      69.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4267
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      69.99 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2200
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      70.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1952
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      73.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3200
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      74.95 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2461
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      75.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5162
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      75.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4903
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      75.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5253
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      77.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      278
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2059
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2682
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2531
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      642
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5088
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2085
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3565
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5396
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      607
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3086
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3989
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      80.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1894
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      85.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5321
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      85.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5355
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      88.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2739
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      88.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4629
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      88.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5185
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      89.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2627
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      90.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2586
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      90.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4957
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      96.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1883
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      99.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1028
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      99.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2646
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4403
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3116
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3084
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1181
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4404
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1690
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1149
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      100.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      980
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      111.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1212
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      136.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1183
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      136.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3112
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      150.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4888
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      167.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      969
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      199.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4855
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2849
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4857
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2855
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      221
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4856
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4566
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2805
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1588
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4565
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2817
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4819
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      200.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1128
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      299.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      4413
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      325.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      737
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      355.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      606
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      369.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      587
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      400.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      68
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      459.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2624
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      499.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5000
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      500.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2641
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      999.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3720
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      1,000.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1338
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      1,000.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3244
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      1,111.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      1165
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      1,111.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5531
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      1,211.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      3
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      2,000.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      5296
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      9,000.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2339
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      50,000.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      2360
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        1
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      999,999.00 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.02%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                            <div className="tw-flex-1 tw-text-white-2">
                              Type
                            </div>
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              color="#f5f3f7"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(245, 243, 247)" }}
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </div>
                          <div
                            className="tw-overflow-hidden"
                            style={{ height: "0px" }}
                          >
                            <div className="tw-px-4 tw-pb-4">
                              <div className="tw-space-y-3 tw-py-4">
                                <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    color="#a197aa"
                                    height="24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(161, 151, 170)" }}
                                  >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                      x1="21"
                                      y1="21"
                                      x2="16.65"
                                      y2="16.65"
                                    ></line>
                                  </svg>
                                  <input
                                    className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                                    placeholder="Search"
                                  />
                                  <button className="tw-invisible">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 24 24"
                                      color="#a197aa"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0z"
                                      ></path>
                                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                                  <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                                    <span className="tw-text-white-2 tw-text-sm">
                                      Genesis
                                    </span>
                                    <div className="tw-justify-self-end">
                                      <span className="tw-text-white-2 tw-text-sm">
                                        2
                                      </span>
                                      <span className="tw-text-light-gray-500 tw-text-xs">
                                        /5537
                                      </span>
                                    </div>
                                    <span className="tw-text-light-gray-500 tw-text-sm">
                                      47.40 ◎ floor
                                    </span>
                                    <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                                      <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                        0.04%
                                      </span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tw-w-[3px] tw-h-full tw-cursor-[col-resize] tw-resize-x hover:tw-bg-[#c1c3c5b4] hover:tw-w-[3px]"></div>
                </div> */}
                <div className="tw-flex-auto tw-max-w-full tw-pt-0" style={{ width: "1000px" }}>
                  <div className="xl:tw-hidden tw-sticky tw-top-[79px] tw-z-10 tw-bg-gray-100"></div>
                  <div className="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-flex-nowrap lg:tw-flex-wrap tw-box-border tw-min-h-[52px] tw-border-0 tw-border-solid tw-border-gray-300 tw-border-b tw-px-0 tw-py-0.5 xl:tw-sticky xl:tw-top-[79px] tw-z-10 tw-bg-gray-100" style={{ backgroundColor: "rgb(12, 39, 62)" }}>
                    <div className="tw-hidden xl:tw-block">
                      <div className="tw-flex tw-items-center tw-gap-x-4 lg:tw-gap-x-10 tw-overflow-x-auto tw-px-5 tw-py-0.5">
                        <span className="tw-flex tw-items-center tw-gap-2 tw-group tw-cursor-pointer tw-flex-shrink-0 tw-h-[52px] ">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#e42575"
                            className="tw-stroke-[#f5f3f7] group-hover:!tw-stroke-[#B7185A] !tw-stroke-[#E42575] tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100"
                            height="20"
                            width="20"
                            style={{ color: "rgb(228, 37, 117)" }}
                          >
                            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                            <polyline points="2 17 12 22 22 17"></polyline>
                            <polyline points="2 12 12 17 22 12"></polyline>
                          </svg>
                          <h3 className="!tw-font-medium tw-text-sm group-hover:!tw-text-pink-dark !tw-text-pink-primary tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100">
                            Items
                          </h3>
                        </span>
                        {/* <span className="tw-flex tw-items-center tw-gap-2 tw-group tw-cursor-pointer tw-flex-shrink-0 tw-h-[52px]">
                          <h3 className="!tw-font-medium tw-text-sm group-hover:!tw-text-pink-dark tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100">
                            Auctions
                          </h3>
                        </span>
                        <span className="tw-flex tw-items-center tw-gap-2 tw-group tw-cursor-pointer tw-flex-shrink-0 tw-h-[52px] ">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#e42575"
                            className="tw-stroke-[#f5f3f7] group-hover:!tw-stroke-[#B7185A] tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100"
                          >
                            <path
                              d="M18.3337 10H15.0003L12.5003 17.5L7.50033 2.5L5.00033 10H1.66699"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                          <h3 className="!tw-font-medium tw-text-sm group-hover:!tw-text-pink-dark tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100">
                            Activity
                          </h3>
                        </span>
                        <span className="tw-flex tw-items-center tw-gap-2 tw-group tw-cursor-pointer tw-flex-shrink-0 tw-h-[52px] ">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#e42575"
                            width="24"
                            height="24"
                            className="tw-stroke-[#f5f3f7] group-hover:!tw-stroke-[#B7185A] tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100"
                            style={{ color: "rgb(228, 37, 117)" }}
                          >
                            <line x1="18" y1="20" x2="18" y2="10"></line>
                            <line x1="12" y1="20" x2="12" y2="4"></line>
                            <line x1="6" y1="20" x2="6" y2="14"></line>
                          </svg>
                          <h3 className="!tw-font-medium tw-text-sm group-hover:!tw-text-pink-dark tw-transition-[stroke,fill,color] tw-ease-in-out tw-duration-100">
                            Analytics
                          </h3>
                        </span> */}
                      </div>
                    </div>

                    <div className="tw-flex tw-items-center tw-flex-grow tw-justify-center lg:tw-justify-end">
                      <div className="last:tw-mr-5">
                        <div className="tw-flex tw-items-center tw-cursor-pointer md:tw-hidden tw-mt-3 tw-mr-4 tw-h-9 tw-w-9 tw-box-border tw-border tw-border-solid tw-border-gray-300 tw-justify-center">
                          <svg
                            stroke="#F5F3F7"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#e42575"
                            height="20"
                            width="20"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "rgb(228, 37, 117)" }}
                          >
                            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                          </svg>
                        </div>
                        <div className="me-dropdown-container lg:tw-max-w-[260px] tw-min-w-[180px] tw-flex-grow tw-text-sm">
                          <div className="cursor-pointer position-relative">
                            <input
                              className="tw-truncate tw-select-none no-border me-naked-dropdown"
                              defaultValue="Price: Low to high"
                            />
                            <div className="d-flex align-items-center h-100 chevron-down">
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                color="#f5f3f7"
                                height="24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(245, 243, 247)" }}
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </div>
                          </div>
                          <div
                            aria-label="dropdown-list"
                            className="dropdown tw-text-secondary no-border"
                            data-popper-reference-hidden="true"
                            data-popper-escaped="true"
                            data-popper-placement="top-start"
                            style={{
                              position: "absolute",
                              inset: "auto auto 0px 0px",
                              width: "202px",
                              transform: "translate(1573px, 33px)",
                            }}
                          >
                            <div className="me-select dark-scroll-bar">
                              <div
                                tabIndex="0"
                                className="me-select-item tw-flex"
                              >
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#594A68"
                                    className="me-10px"
                                  >
                                    <path
                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                                Recently Listed
                              </div>
                              <div
                                tabIndex="0"
                                className="me-select-item tw-flex me-selected-item fw-500"
                              >
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#e42575"
                                    className="me-10px"
                                  >
                                    <path
                                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M22 4L12 14.01L9 11.01"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                                Price: Low to high
                              </div>
                              <div
                                tabIndex="0"
                                className="me-select-item tw-flex"
                              >
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#594A68"
                                    className="me-10px"
                                  >
                                    <path
                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                                Price: High to low
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="me-btn-group tw-hidden md:tw-flex tw-h-fit-content last:tw-mr-5"
                        role="group"
                      >
                        <button type="button" className="me-btn">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="tw-text-2xl"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z"></path>
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="me-btn tw-opacity-80"
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="tw-text-2xl"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 md:tw-gap-4 tw-p-5">
                    <div className="tw-flex tw-items-center tw-gap-2 md:tw-w-44 tw-w-fit-content">
                      <span className="tw-h-9 tw-w-9 tw-box-border tw-border tw-border-solid tw-border-gray-300         tw-flex tw-items-center tw-justify-center tw-rounded tw-cursor-pointer">
                        <svg
                          stroke="#F5F3F7"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="#e42575"
                          height="20"
                          width="20"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "rgb(228, 37, 117)" }}
                        >
                          <polyline points="23 4 23 10 17 10"></polyline>
                          <polyline points="1 20 1 14 7 14"></polyline>
                          <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                        </svg>
                      </span>
                      <span className="tw-text-xs tw-text-light-gray-500 tw-hidden md:tw-block">
                        refreshed 40s ago
                      </span>
                    </div>
                    <div className="position-relative me-search tw-flex-grow lg:tw-max-w-[400px] ">
                      <input
                        type="text"
                        placeholder="Search items"
                        className="text-white !tw-rounded-[40px] tw-h-[36px] !tw-border-none !tw-bg-gray-300 !tw-text-light-gray-500 tw-text-xs"
                      />
                      <div className="d-flex align-items-center h-100 searchIcon">
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJlSURBVHgB3VU9bBJRHP+/u8YKLrh1E2Pogi2YgJNDHW1NZDHSxqQwGKlLcdOpx+hGE6NnXK5LodGBwRwmDp4biSQ9Uk1MWOhmNyYoNL7n7xGh9QnUIzroLwHee//vT4j+dbBxxEQiGzjXaaWEpkUEUbD3KKgGIZefIadYMhs0qYGVxcwGtGUFsQAj0RSMuT0BIaL9N9zy27aZIy8GpNdnu4cOCBF8LK7RVvGN6fxs/F5UCD0L6VWocNut6eslJ98cZmBKfZDKNRIXOLF0wTatYULb9ksZTerO0pqrC77h83fe435lGK9+8pJEWuB1koT2uFg2TToFn+vVSjgUP4BMZj4UY3v1qqPyDFKUTGSCWlfs4qmEvKbJA5ZvZCwU51a75buopkobHLq0IIt3RNomeYTQyYJjAZ+vk1JpAwNowyi+m6/sZy55hGwC2VWMiYhKOy4yo4jsCJoQP9o4qL5r9JdxbIDTPmGIaELIAYSO2mgDxF1ZqLs3MwvkEbcTD3rTLRi5Iw1MT/stWSgu5HR6w9QRz8oGQQSOShsMmvulcoih8eGYnZuNNz7Vq7XfUb6yeH8dkT9ixJ4UymZJpf+yi5aX1nZJ8CCmOVcoP8+PUw7eVdTNkt4X7Bfnh/Ho6kMsfG3nG+cJCKXmZ2PBudDV/b36x68neWSdwqGYgeExcG3AzxmsCsKq+ECnRdAH9pKBpbc+bl1jIW4WbdOQvFAk95ihrm99lAHUwAlfiu/AhRqm6AC/Mz0DjFWQwq12259+/e7p2z7vZSw7HA01krH/aF7RjwQOPezX749OskwXdlqO/it8BzJk+SseX8BFAAAAAElFTkSuQmCC"
                          alt="search icon"
                        />
                      </div>
                    </div>
                    <div className="tw-items-center tw-gap-2 tw-hidden md:tw-flex !tw-duration-[0ms]">
                      <button
                        type="button"
                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-w-[86px] tw-h-8 md:tw-h-9 tw-text-sm !tw-duration-[0ms] PlainButton_transparent__LvWuT"
                      >
                        Buy
                      </button>
                      <button
                        type="button"
                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-w-[86px] tw-h-8 md:tw-h-9 tw-text-sm !tw-duration-[0ms] PlainButton_transparent__LvWuT"
                      >
                        Bid
                      </button>
                      <button
                        type="button"
                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-w-[86px] tw-h-8 md:tw-h-9 tw-text-sm !tw-duration-[0ms] PlainButton_transparent__LvWuT"
                      >
                        Sweep
                      </button>
                    </div>
                  </div>
                  <div className="tw-flex">
                    <div className="tw-flex tw-flex-auto tw-flex-col">
                      <div className="row tw-m-2 collection-filter-tags">
                        <div className="tw-flex tw-flex-wrap tw-gap-y-2"></div>
                      </div>
                      <div className="tw-w-full">
                        <div className="tw-relative">
                          <div
                            className="tw-flex tw-flex-wrap tw-overflow-hidden"
                          // style={{
                          //   height: "7559px",
                          //   contain: "strict",
                          //   position: "relative",
                          // }}
                          >
                            {/* <div
                              className="erd_scroll_detection_container erd_scroll_detection_container_animation_active"
                              style={{
                                visibility: "hidden",
                                display: "inline",
                                width: "0px",
                                height: "0px",
                                zIndex: "-1",
                                overflow: "hidden",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <div
                                dir="ltr"
                                className="erd_scroll_detection_container"
                                style={{
                                  position: "absolute",
                                  flex: "0 0 auto",
                                  overflow: "hidden",
                                  zIndex: "-1",
                                  visibility: "hidden",
                                  width: "100%",
                                  height: "100%",
                                  left: "0px",
                                  top: "0px",
                                }}
                              >
                                <div
                                  className="erd_scroll_detection_container"
                                  style={{
                                    position: "absolute",
                                    flex: "0 0 auto",
                                    overflow: "hidden",
                                    zIndex: "-1",
                                    visibility: "hidden",
                                    inset: "-16px -15px -15px -16px",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      flex: "0 0 auto",
                                      overflow: "scroll",
                                      zIndex: "-1",
                                      visibility: "hidden",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        left: "0px",
                                        top: "0px",
                                        width: "1554px",
                                        height: "7600px",
                                      }}
                                    ></div>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      flex: "0 0 auto",
                                      overflow: "scroll",
                                      zIndex: "-1",
                                      visibility: "hidden",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        width: "200%",
                                        height: "200%",
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {listedNft.map((item, index) => <NFTBox item={item} index={index} handlerBuyNft={handlerBuyNft} />)}

                            {/* {
                              loading || !listedNfts ? (
                                <div>Loading...</div>
                              ) : (
                                listedNfts.activeItems.map((item, index) => {
                                  console.log(item, index)
                                })
                              )
                            } */}
                          </div>
                          {/* <div className="d-flex justify-content-center">
                            <h4> Loading more...</h4>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="me-drawer tw-fixed tw-h-screen tw-bg-card-dark market-place__drawer tw-bg-card-dark tw-block md:tw-hidden tw-z-20 full">
              <header className="tw-z-50 tw-flex tw-p-4 tw-text-lg tw-bg-card-dark tw-border-gray-500 tw-border-solid tw-border-b tw-sticky tw-top-0 tw-cursor-pointer">
                <div className="tw-flex-1 tw-self-start">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    color="#f5f3f7"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(245, 243, 247)" }}
                  >
                    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                  </svg>
                </div>
                <div className="tw-cursor-pointer tw-ml-auto collectionSidebar__collapse hover:tw-opacity-80">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    color="#f5f3f7"
                    height="24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ color: "rgb(245, 243, 247)" }}
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </div>
              </header>
              <div className="tw-flex tw-flex-col tw-relative">
                <div className="tw-flex tw-justify-between tw-p-4 tw-font-medium tw-text-sm tw-border-t tw-border-r tw-border-gray-300">
                  <span className="tw-text-white-2">Buy now</span>
                  <div className="tw-flex tw-gap-2">
                    <div className="tw-flex tw-align-center tw-gap-x-1">
                      <input
                        className="!tw-h-0 !tw-w-0 tw-invisible toggle-checkbox"
                        id="all-toggle"
                        type="checkbox"
                      />
                      <label className="toggle-label">
                        <span className="toggle-button"></span>
                      </label>
                    </div>
                    <div
                      className=""
                      data-tooltipped=""
                      aria-describedby="tippy-tooltip-93"
                      data-original-title="Enable to show only NFTs that you can buy now"
                      style={{ display: "inline" }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        color="#665676"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "rgb(102, 86, 118)" }}
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                      </svg>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                    <div className="tw-flex-1 tw-text-white-2">Price</div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      color="#f5f3f7"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(245, 243, 247)" }}
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </div>
                  <div
                    className="tw-overflow-hidden"
                    style={{ height: "0px" }}
                  >
                    <div className="tw-px-4 tw-pb-4">
                      <div className="tw-py-4">
                        <div className="me-dropdown-container tw-mb-3">
                          <div className="cursor-pointer position-relative">
                            <input
                              className="tw-truncate tw-select-none no-border"
                              defaultValue="SOL"
                            />
                            <div className="d-flex align-items-center h-100 chevron-down">
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                color="#f5f3f7"
                                height="24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(245, 243, 247)" }}
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </div>
                          </div>
                          <div
                            aria-label="dropdown-list"
                            className="dropdown tw-text-secondary no-border"
                            data-popper-reference-hidden="true"
                            data-popper-escaped="true"
                            data-popper-placement="bottom-start"
                            style={{
                              position: "absolute",
                              inset: "0px auto auto 0px",
                              width: "0px",
                              transform: "translate(0px, 4px)",
                            }}
                          >
                            <div className="me-select dark-scroll-bar">
                              <div
                                tabIndex="0"
                                className="me-select-item tw-flex me-selected-item fw-500"
                              >
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="#e42575"
                                    className="me-10px"
                                  >
                                    <path
                                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                    <path
                                      d="M22 4L12 14.01L9 11.01"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                </span>
                                SOL
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tw-flex tw-items-center tw-mb-3">
                          <input
                            type="number"
                            placeholder="Min"
                            className="tw-border tw-border-light-gray-100 focus:tw-border-light-gray-100 tw-rounded-lg tw-text-white-2 tw-placeholder-light-gray-100"
                          />
                          <span className="to-connector">to</span>
                          <input
                            type="number"
                            placeholder="Max"
                            className="tw-border tw-border-light-gray-100 focus:tw-border-light-gray-100 tw-rounded-lg tw-text-white-2 tw-placeholder-light-gray-100"
                          />
                        </div>
                        <button
                          type="button"
                          className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_bordered__3suwZ"
                          style={{ width: "100%" }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                    <div className="tw-flex-1 tw-text-white-2">
                      Crystal Cap
                    </div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      color="#f5f3f7"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(245, 243, 247)" }}
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </div>
                  <div
                    className="tw-overflow-hidden"
                    style={{ height: "0px" }}
                  >
                    <div className="tw-px-4 tw-pb-4">
                      <div className="tw-space-y-3 tw-py-4">
                        <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#a197aa"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "rgb(161, 151, 170)" }}
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line
                              x1="21"
                              y1="21"
                              x2="16.65"
                              y2="16.65"
                            ></line>
                          </svg>
                          <input
                            className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                            placeholder="Search"
                          />
                          <button className="tw-invisible">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              color="#a197aa"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(161, 151, 170)" }}
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              1
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                2
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /2
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              47.40 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                100.00%
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                    <div className="tw-flex-1 tw-text-white-2">
                      Crystals Available To Refine
                    </div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      color="#f5f3f7"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(245, 243, 247)" }}
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </div>
                  <div
                    className="tw-overflow-hidden"
                    style={{ height: "0px" }}
                  >
                    <div className="tw-px-4 tw-pb-4">
                      <div className="tw-space-y-3 tw-py-4">
                        <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#a197aa"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "rgb(161, 151, 170)" }}
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line
                              x1="21"
                              y1="21"
                              x2="16.65"
                              y2="16.65"
                            ></line>
                          </svg>
                          <input
                            className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                            placeholder="Search"
                          />
                          <button className="tw-invisible">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              color="#a197aa"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(161, 151, 170)" }}
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              1
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                2
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /2
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              47.40 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                100.00%
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="tw-flex tw-p-4 tw-cursor-pointer tw-text-sm tw-border-t tw-border-r tw-border-gray-300 hover:tw-bg-gray-200">
                    <div className="tw-flex-1 tw-text-white-2">Element</div>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      color="#f5f3f7"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ color: "rgb(245, 243, 247)" }}
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </div>
                  <div
                    className="tw-overflow-hidden"
                    style={{ height: "0px" }}
                  >
                    <div className="tw-px-4 tw-pb-4">
                      <div className="tw-space-y-3 tw-py-4">
                        <div className="tw-flex tw-items-center tw-space-x-2 tw-bg-gray-300 tw-px-4 tw-rounded-[40px]">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            color="#a197aa"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "rgb(161, 151, 170)" }}
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line
                              x1="21"
                              y1="21"
                              x2="16.65"
                              y2="16.65"
                            ></line>
                          </svg>
                          <input
                            className="tw-outline-none tw-border-none tw-text-white-2 tw-placeholder-light-gray-200"
                            placeholder="Search"
                          />
                          <button className="tw-invisible">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              color="#a197aa"
                              height="24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ color: "rgb(161, 151, 170)" }}
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="tw-overflow-y-auto tw-max-h-[500px] dark-scroll-bar tw-space-y-[10px]">
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              Wood
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                35
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /119
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              38.90 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                29.41%
                              </span>
                            </div>
                          </button>
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              Water
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                24
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /119
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              39.00 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                20.17%
                              </span>
                            </div>
                          </button>
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              Fire
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                22
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /119
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              40.00 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                18.49%
                              </span>
                            </div>
                          </button>
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              Metal
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                22
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /119
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              40.00 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                18.49%
                              </span>
                            </div>
                          </button>
                          <button className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-3 tw-text-left tw-rounded tw-bg-gray-100 hover:tw-bg-gray-200 tw-p-2 tw-border tw-transition-colors tw-border-gray-300">
                            <span className="tw-text-white-2 tw-text-sm">
                              Earth
                            </span>
                            <div className="tw-justify-self-end">
                              <span className="tw-text-white-2 tw-text-sm">
                                16
                              </span>
                              <span className="tw-text-light-gray-500 tw-text-xs">
                                /119
                              </span>
                            </div>
                            <span className="tw-text-light-gray-500 tw-text-sm">
                              47.69 ◎ floor
                            </span>
                            <div className="tw-justify-self-end tw-row-start-2 tw-col-start-2">
                              <span className="tw-bg-[rgba(228,37,117,0.2)] tw-p-1 tw-text-pink-200 tw-rounded-sm tw-text-[11px]">
                                13.45%
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


