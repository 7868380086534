import React, { useEffect, useState } from "react";
import { login } from "../services/userService";
import { getBalance } from "../utils/contractUtils";

export const TransactionContext = React.createContext();
const { ethereum } = window;

export const TransactionsProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentAccountBalance, setCurrentAccountBalance] = useState(0)
  const [currentUser, setCurrentUser] = useState({});

  const handlerUserLogin = async (user) => {
    const loginResp = await login(user);
    if (loginResp.code === 200) {
      setCurrentUser(loginResp.data);
      localStorage.setItem("currentUser", JSON.stringify(loginResp.data));
    }
    return loginResp;
  }


  // connect to wallet
  const connectWallet = async () => {
    try {
      if (!ethereum) return;
      const accounts = await ethereum.request({ method: "eth_requestAccounts", });
      const account = accounts[0].toLowerCase();

      setCurrentAccount(account);
      localStorage.setItem("currentAccount", account);
      localStorage.setItem("last-connected-wallet", "true");
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectWallet = async () => {
    localStorage.removeItem("currentAccount");
    localStorage.removeItem("last-connected-wallet");
    localStorage.removeItem("currentUser");
    setCurrentAccount('');
    setCurrentUser({});
    window.location.reload()
  };

  const autoConnectWallet = async () => {
    if (localStorage.getItem("last-connected-wallet") === "true") {
      try {
        if (!ethereum) return;
        const accounts = await ethereum.request({ method: "eth_accounts" });
        if (accounts.length) {
          const account = accounts[0].toLowerCase();
          setCurrentAccount(account);
          localStorage.setItem("currentAccount", account);

          getBalance(account).then(balance => {
            console.log('balance', balance);
            setCurrentAccountBalance(balance)
          })
        } else {
          console.log("No accounts found");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };




  const accountsChanged = () => {
    if (ethereum) {
      ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          console.log('accountWasChanged', accounts);
          const account = accounts[0].toLowerCase();
          setCurrentAccount(account);
          localStorage.setItem("currentAccount", account);
        } else {
          setCurrentAccount('');
          localStorage.setItem("currentAccount", "");
        }
        window.location.reload();
      });

      // ethereum.on('chainChanged', (chainId) => {
      //   window.location.reload();
      // });

      // ethereum.on('disconnect', () => {
      //   setCurrentAccount('');
      // });
    }
  }

  useEffect(() => {
    const currentUserJson = localStorage.getItem("currentUser")
    if (currentUserJson) {
      setCurrentUser(JSON.parse(currentUserJson));
    }

    if (ethereum) {
      autoConnectWallet();
      accountsChanged(); // refresh page if account changed
    } else {
      console.log("Please install MetaMask.");
    }
  }, [])

  return (
    <div>
      <TransactionContext.Provider
        value={{
          connectWallet,
          disconnectWallet,
          currentAccount,
          currentAccountBalance,
          currentUser,
          handlerUserLogin
        }}
      >
        {children}
      </TransactionContext.Provider>
    </div>
  );
};
