import React from 'react';
import Slider from "react-slick";
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './Games.css';

class Games extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],
      p2: [
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
		  { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
		  { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "2.5" },
        { name: "NFT Leaguez", content:"A revolutionary VR competitive gaming show on PC.", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "2.5" },
      ],


    };
  }

  componentDidMount() {

  }


  render() {
    const settingsSlick = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoPlay: true,
      autoPlaySpeed: 3000,
      dotsClass: "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full customDots",
      arrows: false,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: dots => (
        <div>
          <ul className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4" style={{ gridTemplateColumns: "repeat(" + this.state.p2.length + ", 1fr)" }}>
            {dots}
          </ul>
        </div>
      ),
      customPaging: i => (
          <div key={i} className="tw-cursor-pointer tw-flex tw-items-center">
            <span className={`${this.state.currentSlide === i ? 'tw-bg-white-2' : 'tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50'}`}></span>
          </div>
      )
    };




    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left /> 
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240" style={{marginLeft: "240px"}} >
            
	
	




  <section className="tw-py-0">
   <div className="tw-p-4">
    <div className="videoSection">
     <video controls="" preload="metadata" poster="https://bafybeih2c7ryrr6wxyygdr53v2jwo772ydhgzpn5jemw3btvn4smyc74wy.ipfs.dweb.link/" autoPlay="" playsInline=""  src="https://vz-084cd8a2-5ba.b-cdn.net/38df630b-d076-4a19-a0db-6a00883c4d17/play_720p.mp4">
     </video>
    </div>
    <section className="tw-px-4">
     <div className="tw-mb-4">
      <span className="tw-border tw-border-solid tw-border-pink-primary tw-p-2 tw-rounded-md tw-text-pink-primary">Pixward Games</span>
     </div>
     <div className="tw-flex-none md:tw-flex">
      <div className="tw-flex-1 flex-col">
       <span className="tw-text-5xl tw-font-800 tw-leading-120 text-white tw-mb-2">BOSS FIGHTERS</span>
       <div className="tw-flex tw-flex-row tw-flex-wrap tw-text-l tw-mb-5 text-white">
        <div className="tw-border tw-border-solid tw-border-blue-primary tw-rounded-full tw-px-3 tw-py-1 tw-mr-2 tw-mt-2 tw-flex tw-items-center">
         Action
        </div>
        <div className="tw-border tw-border-solid tw-border-blue-primary tw-rounded-full tw-px-3 tw-py-1 tw-mr-2 tw-mt-2 tw-flex tw-items-center">
         VR
        </div>
        <div className="tw-border tw-border-solid tw-border-blue-primary tw-rounded-full tw-px-3 tw-py-1 tw-mr-2 tw-mt-2 tw-flex tw-items-center">
         Shooter
        </div>
        <div className="tw-mr-1 tw-flex tw-items-center" data-tooltipped="" aria-describedby="tippy-tooltip-1" data-original-title="Website" style={{display: "inline"}}>
         <a href="#" target="_blank" rel="noreferrer" className="tw-mr-1 hover:tw-opacity-80 tw-flex tw-items-center"><img src="/static/media/globe3.5f62bf97ebe1e803c8e6ebbb340b4073.svg" className="tw-w-8 tw-py-1 tw-mt-2" /></a>
        </div>
        <div className="tw-mr-1 tw-flex tw-items-center" data-tooltipped="" aria-describedby="tippy-tooltip-2" data-original-title="Twitter" style={{display: "inline"}} >
         <a href="#" target="_blank" rel="noreferrer" className="tw-mr-1 hover:tw-opacity-80 tw-flex tw-items-center"><img src="/static/media/twitter3.0e79fa041a93266204ffe2fecb852af9.svg" className="tw-w-8 tw-py-1 tw-mt-2" /></a>
        </div>
        <div className="tw-mr-1 tw-flex tw-items-center" data-tooltipped="" aria-describedby="tippy-tooltip-3" data-original-title="Discord" style={{display: "inline"}}>
         <a href="#" target="_blank" rel="noreferrer" className="tw-mr-1 hover:tw-opacity-80 tw-flex tw-items-center"><img src="/static/media/discord3.392988d2112e18a9b55c266bef52397f.svg" className="tw-w-8 tw-py-1 tw-mt-2" /></a>
        </div>
       </div>
       <p className="tw-mb-10 color-grey-500">A revolutionary VR competitive gaming show on PC.</p>
       <a className="tw-mr-4" href="/marketplace/"><button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_primary__22Ken">Explore Collection</button></a>
      </div>
      <div className="tw-mt-8 md:tw-mt-0 tw-flex-1">
       <div className="md:tw-px-8">
        <ul className="tw-flex tw-mb-4">
         <li className="tw-mr-8">
          <div className="tw-border-b tw-border-pink-primary tw-cursor-pointer tw-inline-block tw-text-l tw-font-medium tw-text-center text-white tw-py-4 md:tw-pt-0">
           About BOSS FIGHTERS
          </div></li>
         <li className="tw-mr-8">
          <div className="tw-cursor-pointer tw-inline-block tw-text-l tw-font-medium tw-text-center text-white tw-py-4 md:tw-pt-0">
           Pixward Games
          </div></li>
        </ul>
        <div>
         <p className="color-grey-500">BOSS FIGHTERS is a unique multiplayer competitive game where a VR player controls a powerful boss-monster, while PC players team up against it - battling for prestige and valuable rewards in a futuristic gladiators show!</p>
        </div>

		<div   style={{display: "none"}}  >
	  <p className="color-grey-500">BOSS FIGHTERS is developed by Pixward Games, where top-class artists, designers, and battle-hardened VR developers create ground-breaking innovative VR games.</p>
		</div>


       </div>
      </div>
     </div>
    </section>
    <section className="demoSection"></section>
    <section className="tw-py-2 tw-px-4">
     <div>
      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
       <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Rowdies</h2>
       <div className="tw-flex tw-flex-nowrap tw-rounded tw-border-solid tw-border-gray-300 tw-border Carousel_pillTabs__amXzc">
        <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 tw-bg-pink-primary hover:tw-opacity-80">Recently Listed</button>
        <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 hover:tw-opacity-80">Floor Price</button>
       </div>
       <div className="tw-flex-grow-2"></div>
       <a className="tw-flex tw-justify-center tw-border-2 tw-border-gray-300 tw-py-1 tw-px-2 tw-text-white-1 Carousel_seeAll__H7M3I" href="/marketplace/bossfightersclub">See All</a>
      </div>
      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
       <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden" disabled="">
        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg"   style={{color: "rgb(255, 255, 255)"}}>
         <polyline points="15 18 9 12 15 6"></polyline>
        </svg></button>
       <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
      
		
		{this.state.p2.map((item, index) => (
      <div key={index}>
		
		<div className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2" data-key="BOSS FIGHTERS Rowdy #0790" data-index="0"  key={index}>
         <div itemID={item.name}>
          <a href="/games/details">
           <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
            <img draggable="false" loading="lazy" className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl" src={item.pic} style={{ backgroundColor: "transparent" }} />
            <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
             <span className="tw-text-white-1">{item.name}</span>
             <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>{item.content}</span></span>
             <div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId">
              <span className="fs-14px tw-font-bold tw-text-white-1 tw-flex tw-items-center"><img width="12px" className="me-1" src="/static/media/solana-icon-gradient.fa522d66295471a41d0ae10b89993cf3.svg" alt="solana" />{item.code} SOL</span>
             </div>
            </div>
           </div></a>
         </div>
        </div>

        <div className="react-horizontal-scrolling-menu--separator " data-key="BOSS FIGHTERS Rowdy #0790-separator" data-index="0.1"></div>
	    </div>
		))}




       </div>

       <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right" color="#fff">
         <polyline points="9 18 15 12 9 6"></polyline>
        </svg></button>
      </div>
     </div>
    </section>
   </div>
  </section>


  <section className="tw-px-4 tw-pt-0">
   <div className="tw-flex tw-justify-between tw-mb-4">
    <h3 className="mt-3 mb-0 fw-bold fs-28px line-height-36px">Collections</h3>
   </div>
   <div className="tw-grid tw-gap-3 tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-4 2xl:tw-grid-cols-6">

{this.state.p2.map((item, index) => (
   
    <div className="p-0 text-center card collection-card me" key={index}>
     <a href="/marketplace/">
      <div className="image-over border-radius-24px tw-relative">
       <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0">
        <img draggable="false" loading="lazy" className="card-img-top tw-object-cover tw-w-full tw-h-full" src={item.pic}  style={{ backgroundColor: "transparent" }} />
       </div>
      </div>
      <div className="p-0 card-caption col-12">
       <div className="tw-px-4 tw-pt-3 tw-pb-4">
        <h5 className="tw-mb-2 tw-text-[20px] tw-truncate" title="Mini Royale: Nations - Season 1 (Premium)">{item.name}</h5>
        <div className="tw-text-secondary fs-14px description" title={item.content}>
         {item.content}
        </div>
       </div>
      </div></a>
    </div>
))}





   </div>
  </section>













			</div> 
          </div>
        </div>
      </div>
    );
  }
}

export default Games;

