import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Header from "./Header";
import Left from "./Left";
import client from "./services/apiService";
import { Button, Divider, notification, Space, Modal, message } from 'antd';
import "./Home.css";
import "./Games.css";


export default function Games() {

  const [p1, setP1] = useState([
    {
      name: "NoSignal",
      pic: "/static/media/ntf1.png",
      price: "0.85",
      priceLast: "15.47",
    },
  ])
  const [p2, setP2] = useState([
    {
      name: "Mini Royale: Nations",
      pic: "/static/images/launchpad_main.gif",
      code: "01d  07h 58m",
    },
    {
      name: "Panzerdogs",
      pic: "/static/images/game.png",
      code: "01d  07h 58m",
    },
    {
      name: "NFTLeaguez",
      pic: "/static/images/game3.jpeg",
      code: "01d  07h 58m",
    },
    {
      name: "Panzerdogs",
      pic: "/static/images/game4.png",
      code: "01d  07h 58m",
    },
    {
      name: "NFTLeaguez",
      pic: "/static/images/game5.jpeg",
      code: "01d  07h 58m",
    },
    {
      name: "Panzerdogs",
      pic: "/static/images/game6.png",
      code: "01d  07h 58m",
    },
  ])
  const [currentSlide, setCurrentSlide] = useState(0)

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `${message}`,
      // description: `hello`,
      placement,
    });
  };

  const handlerGetRewardTokenConfirm = () => {
    const account = localStorage.getItem("currentAccount");
    let body = {
      "networkInfo": { "network": "goerli", "chain": "eth" },
      "address": `${account}`
    }
    openNotification(`info`, `topRight`, `Please wait transaction complete`)
    client({ url: '/token', method: 'post', data: body }).then(resp => {
      console.log('resp', resp)
      const { data } = resp.data;
      console.log(`https://goerli.etherscan.io/tx/${data.hash}`)
      Modal.success({
        title: 'Notice',
        content: `Transaction is submitted, view detail on https://goerli.etherscan.io/tx/${data.hash}`,
        width: 1000,
      });
    })
  }

  const handlerGetRewardToken = () => {
    const account = localStorage.getItem("currentAccount");
    // console.log('account', account)

    if (account == null || account == '') {
      openNotification(`warning`, `topRight`, `Please sign in and connect to your wallet.`)
      // message.error(`Please sign in and connect to your wallet.`);
    } else {
      Modal.confirm({
        title: 'Get reward token',
        content: `We will send you ${10} reward token if you click confirm button.`,
        width: 600,
        cancelText: 'Cancel',
        okText: "Confirm",
        okType: "primary",
        onOk() {
          handlerGetRewardTokenConfirm();
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }

  const settingsSlick = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass:
      "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full customDots",
    arrows: false,
    beforeChange: (prev, next) => {
      // this.setState({ currentSlide: next });
      setCurrentSlide(next)
    },
    appendDots: (dots) => (
      <div>
        <ul
          className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4"
          style={{
            gridTemplateColumns: "repeat(" + p2.length + ", 1fr)",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div key={i} className="tw-cursor-pointer tw-flex tw-items-center">
        <span
          className={`${currentSlide === i
            ? "tw-bg-white-2"
            : "tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50"
            }`}
        ></span>
      </div>
    ),
  };

  return (
    <div className="App">
      {contextHolder}
      <div className="main page tw-bg-[#120d18]">
        <Header />
        <div className="tw-flex tw-w-full tw-flex-auto">
          <Left />
          <div
            id="content"
            className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240"
            style={{ marginLeft: "240px" }}
          >
            <section className="tw-py-0">
              <div>
                <Slider {...settingsSlick}>
                  {p2.map((item, index) => (
                    <div key={index}>
                      <div className="headerCardContainer">
                        <div
                          className="headerCardContent"
                          style={{
                            backgroundImage: 'url("/static/media/bg3.jpeg")',
                          }}
                        >
                          <div className="tw-flex tw-flex-col tw-w-full tw-justify-between">
                            <div className="tw-flex tw-justify-end"></div>
                            <div className="tw-flex tw-items-end">
                              <div className="tw-w-full xl:tw-w-3/6 tw-flex tw-flex-col tw-gap-3">
                                <span className="tw-text-3xl md:tw-text-5xl tw-font-800 tw-leading-120 text-white">
                                  Infinite Drive
                                </span>
                                <p className="tw-hidden md:tw-block tw-mt-2 text-white">
                                  Drive for Fun, Race to earn. Welcome to the
                                  first Real Driving Metaverse!
                                </p>
                                {/* <a href="/games/details/infinite_drive"> */}
                                <button onClick={handlerGetRewardToken}
                                  type="button"
                                  className="xl:tw-w-1/3 tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-mt-4 PlainButton_primary__22Ken"
                                >
                                  Get Reward Token
                                </button>
                                {/* </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </section>

            <section className="tw-px-8 tw-pt-8 tw-pb-4">
              <div>
                {/* <div className="tw-grid md:tw-grid-cols-2 tw-gap-8 tw-pt-12 tw-pb-8">
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <img
                      className="tw-w-96"
                      src="/static/media/eden_games.65a6f7ef737397a9eda5d9e5b45eda09.svg"
                      alt="Eden Games"
                    />
                    <p className="color-grey-500 md:tw-w-5/6 xl:tw-w-4/6">
                      We have launched 35+ games including Everseed, SkateX,
                      DeFi Pirates, Mini Royale Nations, and more. I META ID
                      Launchpad provides the widest reach, exposure and tools
                      for creators to launch their collections.
                    </p>
                    <a href="#" target="_blank" rel="noreferrer">
                      <button
                        type="button"
                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_primary__22Ken"
                      >
                        Get in touch
                      </button>
                    </a>
                  </div>
                  <div className="tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-12 tw-pt-8 md:tw-pt-0">
                    <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                      <div className="tw-flex tw-justify-between">
                        <h3 className="tw-font-medium tw-text-2xl">22M +</h3>
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/people.8fb05a867c81053991131565a50f0503.svg"
                        />
                      </div>
                      <p className="tw-text-sm color-grey-500">
                        Unique monthly visitors
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                      <div className="tw-flex tw-justify-between">
                        <h3 className="tw-font-medium tw-text-2xl">
                          13min +
                        </h3>
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/clock.dda3483e661b75c47b4f1f98c1f636db.svg"
                        />
                      </div>
                      <p className="tw-text-sm color-grey-500">
                        Average user session time
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                      <div className="tw-flex tw-justify-between">
                        <h3 className="tw-font-medium tw-text-2xl">320K +</h3>
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/twitter4.3fec5930bf7c4f246120f604ef0e4ad7.svg"
                        />
                      </div>
                      <p className="tw-text-sm color-grey-500">
                        Twitter followers
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                      <div className="tw-flex tw-justify-between">
                        <h3 className="tw-font-medium tw-text-2xl">80K +</h3>
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/discord4.77260d235b96c464df4bfbb080cada6f.svg"
                        />
                      </div>
                      <p className="tw-text-sm color-grey-500">
                        Discord members
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="tw-pt-16">
                  <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">
                    Our Services Include
                  </h2>
                  <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 xl:tw-grid-cols-5 tw-gap-4 tw-pt-8">
                    <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                      <img
                        alt="1"
                        className="tw-w-8"
                        src="https://magiceden.io/static/media/info.e17e60e5867e83d5b232961af88e8113.svg"
                      />
                      <h3 className="tw-font-bold">Dedicated Support</h3>
                      <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                        Partner with a dedicated manager for the best NFT
                        consultation from initial call to post launch.
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                      <img
                        alt="1"
                        className="tw-w-8"
                        src="/static/media/upload.9bb612969c066bf2826007998e71460c.svg"
                      />
                      <h3 className="tw-font-bold">Customizable Mint</h3>
                      <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                        Mint with our custom smart contract with whitelist,
                        pre-mint, SPL mint and off platform mint capabilities.
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                      <img
                        alt="1"
                        className="tw-w-8"
                        src="/static/media/eye.25661337c6482e54a774bbb1dd7164e7.svg"
                      />
                      <h3 className="tw-font-bold">Increased Exposure</h3>
                      <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                        Gain over 600K impressions per campaign and get
                        connected to key influencers, projects, and guilds.
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                      <img
                        alt="1"
                        className="tw-w-8"
                        src="/static/media/wallet.97aea3804e5547c57a8bbef7afa6b16b.svg"
                      />
                      <h3 className="tw-font-bold">User Safety Features</h3>
                      <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                        Give minters peace of mind with a private dox and
                        funds temporarily held in escrow.
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                      <img
                        alt="1"
                        className="tw-w-8"
                        src="/static/media/send.c277ed167fea4ec49b406655e6592ac5.svg"
                      />
                      <h3 className="tw-font-bold">Coming Soon</h3>
                      <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                        • SPL Support • SFT Support • API Support
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="tw-px-8 tw-pt-8">
              <div className="tw-flex tw-flex-col tw-gap-4">
                <div>
                  <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                    <h2
                      className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                      _msthash="407243"
                      _msttexthash="15745730"
                    >
                      Play on ME
                    </h2>
                    <div className="tw-flex-grow-2"></div>
                  </div>
                  <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                      disabled=""
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        color="#fff"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "rgb(255, 255, 255)" }}
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                    <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                      {p2.map((item, index) => (
                        <div key={index}>
                          <div
                            className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                            data-key="Panzerdogs"
                            data-index="0"
                            key={index}
                          >
                            <div itemID={item.name}>
                              <Link to={"/games/details/" + item.name}>
                                <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                  <img
                                    draggable="false"
                                    loading="lazy"
                                    className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                    src={item.pic}
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                  <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                    <span
                                      className="tw-text-white-1"
                                      _msthash="1465484"
                                      _msttexthash="9741043"
                                    >
                                      {item.name}
                                    </span>
                                    <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                      <span
                                        _msthash="1896122"
                                        _msttexthash="18941117"
                                      >
                                        {item.code}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div
                            className="react-horizontal-scrolling-menu--separator "
                            data-key="Panzerdogs-separator"
                            data-index="0.1"
                          ></div>
                        </div>
                      ))}
                    </div>
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                        color="#fff"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                    <h2
                      className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                      _msthash="407244"
                      _msttexthash="17026425"
                    >
                      Partner Games
                    </h2>
                    <div className="tw-flex-grow-2"></div>
                  </div>
                  <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                      disabled=""
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        color="#fff"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "rgb(255, 255, 255)" }}
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                    <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                      {p2.map((item, index) => (
                        <div key={index}>
                          <div
                            className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                            data-key="Panzerdogs"
                            data-index="0"
                            key={index}
                          >
                            <div itemID={item.name}>
                              <Link to={"/games/details/" + item.name}>
                                <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                  <img
                                    draggable="false"
                                    loading="lazy"
                                    className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                    src={item.pic}
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                  <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                    <span
                                      className="tw-text-white-1"
                                      _msthash="1465484"
                                      _msttexthash="9741043"
                                    >
                                      {item.name}
                                    </span>
                                    <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                      <span
                                        _msthash="1896122"
                                        _msttexthash="18941117"
                                      >
                                        {item.code}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div
                            className="react-horizontal-scrolling-menu--separator "
                            data-key="Panzerdogs-separator"
                            data-index="0.1"
                          ></div>
                        </div>
                      ))}
                    </div>
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                        color="#fff"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>

                <div>
                  <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                    <h2
                      className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                      _msthash="407245"
                      _msttexthash="12272351"
                    >
                      Recently Added
                    </h2>
                    <div className="tw-flex-grow-2"></div>
                  </div>
                  <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                      disabled=""
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        color="#fff"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ color: "rgb(255, 255, 255)" }}
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                    <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                      {p2.map((item, index) => (
                        <div key={index}>
                          <div
                            className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                            data-key="Panzerdogs"
                            data-index="0"
                            key={index}
                          >
                            <div itemID={item.name}>
                              <Link to={"/games/details/" + item.name}>
                                <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                  <img
                                    draggable="false"
                                    loading="lazy"
                                    className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                    src={item.pic}
                                    style={{ backgroundColor: "transparent" }}
                                  />
                                  <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                    <span
                                      className="tw-text-white-1"
                                      _msthash="1465484"
                                      _msttexthash="9741043"
                                    >
                                      {item.name}
                                    </span>
                                    <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                      <span
                                        _msthash="1896122"
                                        _msttexthash="18941117"
                                      >
                                        {item.code}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div
                            className="react-horizontal-scrolling-menu--separator "
                            data-key="Panzerdogs-separator"
                            data-index="0.1"
                          ></div>
                        </div>
                      ))}
                    </div>
                    <button
                      style={{ display: "none" }}
                      className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-chevron-right"
                        color="#fff"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

class Games2 extends React.Component {
  state = {
    p1: [
      {
        name: "NoSignal",
        pic: "/static/media/ntf1.png",
        price: "0.85",
        priceLast: "15.47",
      },
    ],
    p2: [
      {
        name: "Mini Royale: Nations",
        pic: "/static/images/launchpad_main.gif",
        code: "01d  07h 58m",
      },
      {
        name: "Panzerdogs",
        pic: "/static/images/game.png",
        code: "01d  07h 58m",
      },
      {
        name: "NFTLeaguez",
        pic: "/static/images/game3.jpeg",
        code: "01d  07h 58m",
      },
      {
        name: "Panzerdogs",
        pic: "/static/images/game4.png",
        code: "01d  07h 58m",
      },
      {
        name: "NFTLeaguez",
        pic: "/static/images/game5.jpeg",
        code: "01d  07h 58m",
      },
      {
        name: "Panzerdogs",
        pic: "/static/images/game6.png",
        code: "01d  07h 58m",
      },
    ],
  }

  handlerGetRewardToken() {
    const account = localStorage.getItem("currentAccount");
    let body = {
      "networkInfo": { "network": "goerli", "chain": "eth" },
      "toAddress": `${account}`
    }
    client(`/api/getRewardToken`, { body }).then(resp => {
      const { data } = resp
      console.log('data', data)
      alert(`https://goerli.etherscan.io/tx/${data.hash}`)
    })
  }

  componentDidMount() { }

  render() {
    const settingsSlick = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass:
        "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full customDots",
      arrows: false,
      beforeChange: (prev, next) => {
        this.setState({ currentSlide: next });
      },
      appendDots: (dots) => (
        <div>
          <ul
            className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4"
            style={{
              gridTemplateColumns: "repeat(" + this.state.p2.length + ", 1fr)",
            }}
          >
            {dots}
          </ul>
        </div>
      ),
      customPaging: (i) => (
        <div key={i} className="tw-cursor-pointer tw-flex tw-items-center">
          <span
            className={`${this.state.currentSlide === i
              ? "tw-bg-white-2"
              : "tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50"
              }`}
          ></span>
        </div>
      ),
    };

    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
          <Header />
          <div className="tw-flex tw-w-full tw-flex-auto">
            <Left />
            <div
              id="content"
              className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240"
              style={{ marginLeft: "240px" }}
            >
              <section className="tw-py-0">
                <div>
                  <Slider {...settingsSlick}>
                    {this.state.p2.map((item, index) => (
                      <div key={index}>
                        <div className="headerCardContainer">
                          <div
                            className="headerCardContent"
                            style={{
                              backgroundImage: 'url("/static/media/bg3.jpeg")',
                            }}
                          >
                            <div className="tw-flex tw-flex-col tw-w-full tw-justify-between">
                              <div className="tw-flex tw-justify-end"></div>
                              <div className="tw-flex tw-items-end">
                                <div className="tw-w-full xl:tw-w-3/6 tw-flex tw-flex-col tw-gap-3">
                                  <span className="tw-text-3xl md:tw-text-5xl tw-font-800 tw-leading-120 text-white">
                                    Infinite Drive
                                  </span>
                                  <p className="tw-hidden md:tw-block tw-mt-2 text-white">
                                    Drive for Fun, Race to earn. Welcome to the
                                    first Real Driving Metaverse!
                                  </p>
                                  {/* <a href="/games/details/infinite_drive"> */}
                                  <button onClick={() => this.handlerGetRewardToken()}
                                    type="button"
                                    className="xl:tw-w-1/3 tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-mt-4 PlainButton_primary__22Ken"
                                  >
                                    Get Reward Token
                                  </button>
                                  {/* </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </section>

              <section className="tw-px-8 tw-pt-8 tw-pb-4">
                <div>
                  {/* <div className="tw-grid md:tw-grid-cols-2 tw-gap-8 tw-pt-12 tw-pb-8">
                    <div className="tw-flex tw-flex-col tw-gap-4">
                      <img
                        className="tw-w-96"
                        src="/static/media/eden_games.65a6f7ef737397a9eda5d9e5b45eda09.svg"
                        alt="Eden Games"
                      />
                      <p className="color-grey-500 md:tw-w-5/6 xl:tw-w-4/6">
                        We have launched 35+ games including Everseed, SkateX,
                        DeFi Pirates, Mini Royale Nations, and more. I META ID
                        Launchpad provides the widest reach, exposure and tools
                        for creators to launch their collections.
                      </p>
                      <a href="#" target="_blank" rel="noreferrer">
                        <button
                          type="button"
                          className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_primary__22Ken"
                        >
                          Get in touch
                        </button>
                      </a>
                    </div>
                    <div className="tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-12 tw-pt-8 md:tw-pt-0">
                      <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                        <div className="tw-flex tw-justify-between">
                          <h3 className="tw-font-medium tw-text-2xl">22M +</h3>
                          <img
                            alt="1"
                            className="tw-w-8"
                            src="/static/media/people.8fb05a867c81053991131565a50f0503.svg"
                          />
                        </div>
                        <p className="tw-text-sm color-grey-500">
                          Unique monthly visitors
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                        <div className="tw-flex tw-justify-between">
                          <h3 className="tw-font-medium tw-text-2xl">
                            13min +
                          </h3>
                          <img
                            alt="1"
                            className="tw-w-8"
                            src="/static/media/clock.dda3483e661b75c47b4f1f98c1f636db.svg"
                          />
                        </div>
                        <p className="tw-text-sm color-grey-500">
                          Average user session time
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                        <div className="tw-flex tw-justify-between">
                          <h3 className="tw-font-medium tw-text-2xl">320K +</h3>
                          <img
                            alt="1"
                            className="tw-w-8"
                            src="/static/media/twitter4.3fec5930bf7c4f246120f604ef0e4ad7.svg"
                          />
                        </div>
                        <p className="tw-text-sm color-grey-500">
                          Twitter followers
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-pink-primary tw-gap-4">
                        <div className="tw-flex tw-justify-between">
                          <h3 className="tw-font-medium tw-text-2xl">80K +</h3>
                          <img
                            alt="1"
                            className="tw-w-8"
                            src="/static/media/discord4.77260d235b96c464df4bfbb080cada6f.svg"
                          />
                        </div>
                        <p className="tw-text-sm color-grey-500">
                          Discord members
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="tw-pt-16">
                    <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">
                      Our Services Include
                    </h2>
                    <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 xl:tw-grid-cols-5 tw-gap-4 tw-pt-8">
                      <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="https://magiceden.io/static/media/info.e17e60e5867e83d5b232961af88e8113.svg"
                        />
                        <h3 className="tw-font-bold">Dedicated Support</h3>
                        <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                          Partner with a dedicated manager for the best NFT
                          consultation from initial call to post launch.
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/upload.9bb612969c066bf2826007998e71460c.svg"
                        />
                        <h3 className="tw-font-bold">Customizable Mint</h3>
                        <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                          Mint with our custom smart contract with whitelist,
                          pre-mint, SPL mint and off platform mint capabilities.
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/eye.25661337c6482e54a774bbb1dd7164e7.svg"
                        />
                        <h3 className="tw-font-bold">Increased Exposure</h3>
                        <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                          Gain over 600K impressions per campaign and get
                          connected to key influencers, projects, and guilds.
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/wallet.97aea3804e5547c57a8bbef7afa6b16b.svg"
                        />
                        <h3 className="tw-font-bold">User Safety Features</h3>
                        <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                          Give minters peace of mind with a private dox and
                          funds temporarily held in escrow.
                        </p>
                      </div>
                      <div className="tw-flex tw-flex-col tw-p-4 tw-border tw-border-pink-primary tw-rounded-xl tw-gap-2">
                        <img
                          alt="1"
                          className="tw-w-8"
                          src="/static/media/send.c277ed167fea4ec49b406655e6592ac5.svg"
                        />
                        <h3 className="tw-font-bold">Coming Soon</h3>
                        <p className="tw-text-sm tw-whitespace-pre-line color-grey-500">
                          • SPL Support • SFT Support • API Support
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="tw-px-8 tw-pt-8">
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <div>
                    <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                      <h2
                        className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                        _msthash="407243"
                        _msttexthash="15745730"
                      >
                        Play on ME
                      </h2>
                      <div className="tw-flex-grow-2"></div>
                    </div>
                    <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                        disabled=""
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="#fff"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "rgb(255, 255, 255)" }}
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                        {this.state.p2.map((item, index) => (
                          <div key={index}>
                            <div
                              className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                              data-key="Panzerdogs"
                              data-index="0"
                              key={index}
                            >
                              <div itemID={item.name}>
                                <Link to={"/games/details/" + item.name}>
                                  <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                    <img
                                      draggable="false"
                                      loading="lazy"
                                      className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                      src={item.pic}
                                      style={{ backgroundColor: "transparent" }}
                                    />
                                    <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                      <span
                                        className="tw-text-white-1"
                                        _msthash="1465484"
                                        _msttexthash="9741043"
                                      >
                                        {item.name}
                                      </span>
                                      <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                        <span
                                          _msthash="1896122"
                                          _msttexthash="18941117"
                                        >
                                          {item.code}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div
                              className="react-horizontal-scrolling-menu--separator "
                              data-key="Panzerdogs-separator"
                              data-index="0.1"
                            ></div>
                          </div>
                        ))}
                      </div>
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-chevron-right"
                          color="#fff"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                      <h2
                        className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                        _msthash="407244"
                        _msttexthash="17026425"
                      >
                        Partner Games
                      </h2>
                      <div className="tw-flex-grow-2"></div>
                    </div>
                    <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                        disabled=""
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="#fff"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "rgb(255, 255, 255)" }}
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                        {this.state.p2.map((item, index) => (
                          <div key={index}>
                            <div
                              className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                              data-key="Panzerdogs"
                              data-index="0"
                              key={index}
                            >
                              <div itemID={item.name}>
                                <Link to={"/games/details/" + item.name}>
                                  <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                    <img
                                      draggable="false"
                                      loading="lazy"
                                      className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                      src={item.pic}
                                      style={{ backgroundColor: "transparent" }}
                                    />
                                    <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                      <span
                                        className="tw-text-white-1"
                                        _msthash="1465484"
                                        _msttexthash="9741043"
                                      >
                                        {item.name}
                                      </span>
                                      <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                        <span
                                          _msthash="1896122"
                                          _msttexthash="18941117"
                                        >
                                          {item.code}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div
                              className="react-horizontal-scrolling-menu--separator "
                              data-key="Panzerdogs-separator"
                              data-index="0.1"
                            ></div>
                          </div>
                        ))}
                      </div>
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-chevron-right"
                          color="#fff"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div>
                    <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                      <h2
                        className="tw-text-xl sm:tw-text-3xl tw-font-bold"
                        _msthash="407245"
                        _msttexthash="12272351"
                      >
                        Recently Added
                      </h2>
                      <div className="tw-flex-grow-2"></div>
                    </div>
                    <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw-hidden"
                        disabled=""
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          color="#fff"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ color: "rgb(255, 255, 255)" }}
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                        {this.state.p2.map((item, index) => (
                          <div key={index}>
                            <div
                              className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2"
                              data-key="Panzerdogs"
                              data-index="0"
                              key={index}
                            >
                              <div itemID={item.name}>
                                <Link to={"/games/details/" + item.name}>
                                  <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden">
                                    <img
                                      draggable="false"
                                      loading="lazy"
                                      className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl"
                                      src={item.pic}
                                      style={{ backgroundColor: "transparent" }}
                                    />
                                    <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62">
                                      <span
                                        className="tw-text-white-1"
                                        _msthash="1465484"
                                        _msttexthash="9741043"
                                      >
                                        {item.name}
                                      </span>
                                      <span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs">
                                        <span
                                          _msthash="1896122"
                                          _msttexthash="18941117"
                                        >
                                          {item.code}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div
                              className="react-horizontal-scrolling-menu--separator "
                              data-key="Panzerdogs-separator"
                              data-index="0.1"
                            ></div>
                          </div>
                        ))}
                      </div>
                      <button
                        style={{ display: "none" }}
                        className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_right__T0VIX Carousel_arrow__pjtQq"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-chevron-right"
                          color="#fff"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default Games;
