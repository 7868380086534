import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';


class LaunchpadContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left /> 
            

            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">
              <div className="tw-max-w-screen-3xl tw-mx-auto tw-my-4 tw-w-full tw-px-8">
                <div className="tw-flex tw-flex-1 tw-flex-col-reverse md:tw-flex-row tw-mx-auto tw-gap-8 tw-justify-between">
                  <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-grow md:tw-max-w-[40%]">
                    <h1 className="mt-1 tw-text-[58px] tw-font-extrabold tw-leading-none">Proof-of-Change</h1>
                    <div className="tw-inline-flex tw-gap-2 tw-content-center tw-w-fit-content tw-flex-wrap">
                      <div className="tw-border tw-border-solid tw-border-purple-1 tw-p-2 tw-rounded-md tw-text-white-1 tw-h-fit-content tw-text-[14px] tw-flex tw-gap-2 tw-whitespace-nowrap"><span><div title="Copy token address" className="" style={{display: "inline"}}>Free Mint</div></span></div>
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-44" data-original-title="Discord" style={{display: "inline"}}><a href="#" target="_blank" rel="noreferrer"><img src="/static/media/discord_white.a80cd7b6a598d458f12090e4f0316953.svg" alt="1" className="tw-w-6 tw-h-6 hover:tw-opacity-80" /></a></div>
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-45" data-original-title="Twitter" style={{display: "inline"}}><a href="#" target="_blank" rel="noreferrer"><img alt="1" src="/static/media/twitter_white.646bf11eec160fc04b57653d5d95478b.svg" className="tw-w-6 tw-h-6 hover:tw-opacity-80" /></a></div>
                      </div>
                    </div>
                    <div className="tw-mb-4 tw-text-light-gray-500">
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">The first ever Web 3 Reforestation Competition - Project Galaxy vs. I META ID vs. Vera!</p>
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Three epic projects competing to see who can activate the most community members to grow a forest in the real world! Whichever community mints the most NFTs wins the competition and will be rewarded! Additionally, a real tree will be planted on behalf of EACH AND EVERY NFT minted by the winning community. Let’s grow the I META ID Forest!!</p>
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Free mint, real impact!</p>
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Presented by Komori!</p>
                      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Conditions: ㅤ
                      </p>
                      <ol className="tw-text-light-gray-500 tw-pb-6 tw-ml-4" depth="0" style={{listStyle: "outside"}}>
                        <li className="tw-my-0" index="0">
                          <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">One mint per wallet</p>
                        </li>
                        <li className="tw-my-0" index="1">
                          <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Must be greenlisted (WL) to mint</p>
                        </li>
                        <li className="tw-my-0" index="2">
                          <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">To earn greenlist click <a className="tw-text-pink-primary tw-text-md" href="#" target="_blank" rel="noreferrer">here</a></p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="md:tw-max-w-[50%] tw-flex tw-flex-col tw-items-center tw-w-full tw-flex-grow tw-self-stretch">
                    <div className="overflow-hidden tw-w-full tw-mb-4 tw-rounded-3xl">
                      <div><img src="https://bafybeid63kefqdvtnbfw3rfvenc77r64r5ftusako2uyrxkwub3cewx6ry.ipfs.dweb.link/" alt="Proof-of-Change" className="tw-object-cover tw-aspect-square tw tw-w-screen tw-flex-grow tw-rounded-3xl overflow-hidden" /><img alt="1" src="https://bafybeid63kefqdvtnbfw3rfvenc77r64r5ftusako2uyrxkwub3cewx6ry.ipfs.dweb.link/" width="1" height="1" className="tw-absolute tw-invisible" /></div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="tw-w-full tw-h-[1px] tw-border tw-border-solid tw-border-purple-1 tw-mt-12 tw-mb-4 lg:tw-mt-32 lg:tw-mb-10"></div>
                  </div>





  <div className="">
   <div className="tw-w-full tw-h-[1px] tw-border tw-border-solid tw-border-purple-1 tw-mt-12 tw-mb-4 lg:tw-mt-32 lg:tw-mb-10"></div>
  </div> 
  <div>
   <div className="tw-relative tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-mx-auto tw-gap-32 tw-pb-20 tw-justify-between tw-overflow-hidden">
    <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-grow md:tw-w-[40%]">
     <h1 className="mt-1 tw-text-[58px] tw-font-extrabold tw-leading-none">Ev.io - First Edition Swords</h1>
     <div className="tw-flex tw-flex-wrap tw-gap-4">
      <a href="#" target="_blank" rel="noreferrer noopener" className="tw-border tw-border-solid tw-border-purple-1 tw-flex tw-items-center tw-gap-2 tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2">
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" color="#e42575">
        <path stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round" d="M15 10.833v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V6.667A1.667 1.667 0 014.167 5h5M12.5 2.5h5v5M8.332 11.667L17.499 2.5"></path>
       </svg><span>Website</span></a>
      <a href="#" target="_blank" rel="noreferrer noopener" className="tw-border tw-border-solid tw-border-purple-1 tw-flex tw-items-center tw-gap-2 tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2">
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" color="#e42575">
        <path stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round" d="M15 10.833v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V6.667A1.667 1.667 0 014.167 5h5M12.5 2.5h5v5M8.332 11.667L17.499 2.5"></path>
       </svg><span>Discord</span></a>
      <a href="#" target="_blank" rel="noreferrer noopener" className="tw-border tw-border-solid tw-border-purple-1 tw-flex tw-items-center tw-gap-2 tw-w-fit tw-rounded-full tw-px-2.5 tw-py-1.5 tw-text-white-2">
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" color="#e42575">
        <path stroke="#F5F3F7" strokeLinecap="round" strokeLinejoin="round" d="M15 10.833v5a1.666 1.666 0 01-1.667 1.667H4.167A1.667 1.667 0 012.5 15.833V6.667A1.667 1.667 0 014.167 5h5M12.5 2.5h5v5M8.332 11.667L17.499 2.5"></path>
       </svg><span>Twitter</span></a>
     </div>
     <div>
      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Ev.io is a p2e first-person shooter developed by Addicting Games. Teleportation, triple jump, impulse, smoke, flash and sticky grenades - ev.io is host to all kinds of fun abilities that go beyond simple &quot;point and shoot&quot; mechanics. Play and earn in deathmatch, team deathmatch, battle royale, mayhem, capture the flag and survival game modes.</p> 
      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Beta launched in January and ev.io already has over a million monthly active players. The game plays directly in the browser at <a className="tw-text-pink-primary tw-text-md" href="#" target="_blank" rel="noreferrer">https://ev.io</a> with no downloads or installs needed and is designed first and foremost to be highly accessible on all desktop devices.</p> 
      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Ev.io is fully integrated with the Solana blockchain. Although the in-game currency (e) is off-chain, you can convert it directly to Solana. Earn e by having your NFT equipped and getting kills, and for certain achievements such as headshots, killing sprees, multikills, noscopes, sticky grenade kills, land and tripmine kills, kills from the grave, longshot and sword kills.</p> 
      <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">The first edition sword mint on I META ID will be the last time first edition NFTs will ever be minted!</p>
     </div>
    </div>
    <div className="tw-flex tw-flex-col tw-gap-4 tw-flex-grow md:tw-w-[50%]">
     <div className="tw-w-full tw-self-stretch">
      <div className="tw-flex tw-gap-8 lg:tw-gap-16">
       <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 tw-text-white-2 tw-font-medium tw-text-lg">
        <span className="me-tab2-title">Roadmap</span>
       </div>
       <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 is-active tw-text-white-2 tw-font-medium tw-text-lg">
        <span className="me-tab2-title">Team</span>
       </div>
      </div>
     </div>
     <div className="tw-p-4">
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">The idea for ev.io was born out of conversations between Chris Scott and Addicting Games (former) CEO Bill Karamouzis in 2019 - and the mutual desire to revolutionize the web gaming space with a competent and well-executed first person shooter. Everyone on the ev.io team is self-taught and driven by curiosity and passion, rather than going through the motions of making a game.</p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">Chris Scott - Team Lead / Game Director</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">Chris Scott leads the development and game design on ev.io. Chris calls himself a “browser game ideologue” (of all things) and believes in making video games fun first (“you would think it was obvious”) and easily accessible second (“streamable, just like netflix”). Unhappy and frustrated with the current state of games, Chris left his uninspiring corporate gig in an unrelated field in 2015 without much of a plan except to work full time on games. He solo developed several web games, including Strike Tactics and Feudal Wars, both RTS games made for the browser (of all things). In 2017 he made (and sold) Tactics Core (a moba-like) which was his first commercially successful web game, before joining Addicting Games. Although he wouldn’t dare touch a 3d model (much to David’s relief), he takes a very hands-on approach to the programming. Much of ev.io’s game design was informed by his experience playing Destiny 1 with friends from 2015-2017 (&quot;RIP Destiny 1&quot;).</p>
       <br />
      </div> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]"><a className="tw-text-pink-hot tw-block tw-text-md tw-text-[14px]" href="#" target="_blank" rel="noreferrer">https://twitter.com/ForgeableSum</a></p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">Johannes Bonitz (JBS) - Game Developer / Designer</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">JBS is the star game developer on ev.io, and first hire. JBS also got his start in game dev as a solo dev, working on a browser RTS called Little War Game. The mutual interest in making RTS games for the browser is what brought Chris Scott and JBS first together, back in 2014. JBS’ second game was a top-down shooter called slay.one. JBS lives in Munich, Germany which means he is hard at work while most of the team is asleep. Also, SC2 master league, baby!</p>
       <br />
      </div> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]"><a className="tw-text-pink-hot tw-block tw-text-md tw-text-[14px]" href="#" target="_blank" rel="noreferrer">https://twitter.com/jbgames_</a></p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">David Kawecki - Map Designer</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">David began making maps as a hobby using the Forge mode in Halo 3 and continued throughout the sequels. During this time David worked nights as a security guard. In 2019 he began learning 3D modeling in Blender hoping to start an art career. In 2020 he got his first art contract working for ev.io as an environment artist/layout designer. He is responsible for handcrafting most of the maps in ev.io, including Bishop, Dusk, Fractal, Dragon Temple and Evasion. More recently, Ancient and Drought. Last year he began doing weapon skins, lighting for the maps and various 2D art for the game. This year he's done NFTs and 2D promotional art for the weapon and player skins. David has continued working on new maps and is now assisting with the direction of game art.</p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">Sani Aga - Character Artist</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">Sani is our character artist living in the Philippines. He started on the team prototyping maps and moved into character design. He also does animations and created most of the amazing NFT art you see on explorer.ev.io. Although English is not his first language, and therefore, can’t communicate with the team much, his work speaks for itself. It was Sani that invented the iconic characters of ev.io: Thorn, Akuma, Anubis, Fear, etc.</p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">Isaac Gurillo - Web3 Engineer</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">Isaac is our web3 engineer. Having been involved in several successful web3 projects, Isaac brings technical experience and expertise to all on-chain integrations, such as in-game token drops, equipping NFTs and e to SOL conversion. Isaac is also a veteran web game developer, having created many self-published io games, like Tank Royale.</p>
       <br />
      </div> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]"><a className="tw-text-pink-hot tw-block tw-text-md tw-text-[14px]" href="#" target="_blank" rel="noreferrer">https://twitter.com/devMidgard</a></p>
       <br />
      </div> 
      <h1 className="tw-text-white-2 tw-text-[14px] tw-font-semibold" level="1">David Thien Ho - Partnerships / Crypto strategy</h1> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]">David (aka ghoodsite) is the newest member of the ev.io team. Prior to joining the team, Dave owned and operated nightclubs/bars, worked as a traveling DJ, and spent his free time as a content creator and competitive gamer. Dave founded GGS, a play-to-earn guild formed to create opportunities for players, and helped contribute to the growth and evolution of web3 gaming through investing and participating in various web3 communities and projects.</p>
       <br />
      </div> 
      <div className="tw-my-1">
       <p className="tw-text-light-gray-500 tw-text-md tw-text-[14px]"><a className="tw-text-pink-hot tw-block tw-text-md tw-text-[14px]" href="#" target="_blank" rel="noreferrer">https://twitter.com/ghoodsite</a></p>
       <br />
      </div>
     </div>
    </div>
   </div>
  </div>



                 


                </div>
              </div>


          </div>
        </div>
      </div>
    );
  }
}

export default LaunchpadContent;

