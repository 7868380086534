import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './assets-Sell.css';


class Sellercentral extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left name="Sidebar_slim__EH6w7" bg="none" /> 
          

  <div className="as-body desktop"> 
   <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width:"100%"}}> 
    <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
    </div> 
    <div className="sticks-top-no-nav background-color-aqua border-color-mermaid     padding-left-xlarge padding-right-xlarge padding-top-mini padding-bottom-mini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-space-between flex-align-content-space-between flex-justify-content flex-justify-content-space-between flex-full-width amsg-2018 fonts-loaded border-color-mermaid is-sticky sticks-top design-Sell is-fluid is-nowrap is-sticky sticks-top" id="" style={{width: "100%"}}> 
     <div className="has-max-width"> 
      <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-mini padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
       <div className=" image image-size-custom align-start  "> 
        <img src="https://m.media-amazon.com/images/G/01/sell/images/Amazon_Seller_Central_logo_white.svg" alt="" title=""  style={{height:"100%",width:"auto"}} /> 
       </div> 
      </div> 
      

     </div> 
    </div> 
    <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
    </div> 
    <div></div> 
    <div className="background-color-aqua border-color-squid-ink     padding-left-xlarge padding-right-xlarge padding-top-xlarge padding-bottom-xxlarge border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-center flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
     <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
      <h1 className="heading align-center font-size-xxxlarge color-white ember font-heavy">Become an I META ID seller</h1> 
     </div> 
     <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-minibase border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
      <div className="text align-center color-diamond font-size-large ember font-normal">
        More than half of multi-channel sellers prefer to acquire customers through I META ID. 
      </div> 
     </div> 
     <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-justify-content flex-justify-content-center flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
      <div className="dual-cta ember"> 
       <div className="cta-wrapper first-cta button-spacing-xlarge"> 
        <p className="top-text color-squid-ink font-size-xsmall"><span> </span></p> 
        <a href="#" className="link button button-type-primary font-size-xlarge " data-ld-append="SCUSRP_WP_H" id="rp_cta_h" aria-label="">Register</a> 
        <p className="bottom-text color-diamond font-size-xsmall">$39.99 + sales fee per month</p> 
       </div> 
       <div className="cta-wrapper second-cta"> 
        <p className="top-text color-squid-ink font-size-xsmall"><span> </span></p> 
        <a href="#" className="link button button-type-secondary-light font-size-xlarge " data-ld-append="LM_WP_H" id="learn_2c_h" aria-label="">Learn more</a> 
        <p className="bottom-text color-squid-ink font-size-xsmall"><span> </span></p> 
       </div> 
      </div> 
     </div> 
    </div> 
    <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
    </div> 
    <div></div> 
    <div className="border-color-squid-ink     padding-left-xlarge padding-right-xlarge padding-top-xsmall padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
     <div className="has-max-width"> 
      <div className="text align-center color-storm font-size-xsmall ember font-normal">
        I META ID internal data, fiscal year 2021 survey of 21,200 U.S. multi-channel sellers
      </div> 
     </div> 
    </div> 
    <div className="border-color-squid-ink     padding-left-xlarge padding-right-xlarge padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
     <div className="has-max-width"> 
      <div className="border-color-snow     padding-left-zero padding-right-zero padding-top-minibase padding-bottom-zero border-left-zero border-right-zero border-top-1px border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow design-Sell" id="" style={{width: "100%"}}> 
       <div className="spacer"></div> 
      </div> 
      <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell is-fluid is-nowrap" id="" style={{width: "100%"}}> 
       <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "33.333333333333336%"}}> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-base padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
          <div className="text align-start color-squid-ink font-size-small ember font-bold">
             Sell on I META ID 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_cost" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_cost">How much does it cost?</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_bg" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_bg">Beginner's guide</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_ecom" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_ecom">Introduction to e-commerce</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_inv" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_inv">Inventory management</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_books" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_books">How to sell I META ID</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_01_so" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_01_so">Sell online</a> 
          </div> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-base padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
          <div className="text align-start color-squid-ink font-size-small ember font-bold">
            Sales plan 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_02_fba" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_02_fba">FBA</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_02_ads" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_02_ads">I META ID Advertising</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_02_ww" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_02_ww">Store worldwide</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_02_b2b" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_02_b2b">I META ID Business Purchase</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_02_more" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_02_more">More plans</a> 
          </div> 
         </div> 
        </div> 
       </div> 
       <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "33.333333333333336%"}}> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-base padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
          <div className="text align-start color-squid-ink font-size-small ember font-bold">
            I META ID serves brands 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_03_build" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_03_build">Shop online</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_03_tips" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_03_tips">Brand tips for I META ID marketplaces</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_03_stories" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_03_stories">Brand case studies</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_03_br" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_03_br">I META ID Brand Registry</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_03_store" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_03_store">E-commerce store</a> 
          </div> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-base padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
          <div className="text align-start color-squid-ink font-size-small ember font-bold">
            resource 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="scw_ft_04_blog" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_blog">Selling Partner Blog</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_04_su" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_su">Seller University</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_04_help" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_help">Seller Central Help Center</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_04_events" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_events">Seller activity</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_04_bba" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_bba">Black Business Accelerator</a> 
          </div> 
         </div> 
         <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_04_more" style={{width: "100%"}}> 
          <div className="align-start"> 
           <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_04_more">More ways to make a profit</a> 
          </div> 
         </div> 
        </div> 
       </div> 
       <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "33.333333333333336%"}}> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xsmall border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
         <div className="text align-start color-squid-ink font-size-small ember font-bold">
            tool 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_05_login" style={{width: "100%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_05_login">Sign in to your seller account</a> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_05_app" style={{width: "100%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_05_app">I META ID Seller App</a> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_05_scpn" style={{width: "100%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_05_scpn">Seller Central Partner Network</a> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-xmini border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_05_calc" style={{width: "100%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_05_calc">I META ID Fee Calculator</a> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="ft_05_autoprice" style={{width: "100%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark" data-ld-append="scw_ft_05_autoprice">Automate pricing</a> 
         </div> 
        </div> 
       </div> 
      </div> 
      <div className="border-color-snow     padding-left-zero padding-right-zero padding-top-zero padding-bottom-minibase border-left-zero border-right-zero border-top-zero border-bottom-1px flex-container flex-align-items-stretch flex-align-content-flex-start flex-full-width amsg-2018 fonts-loaded border-color-snow design-Sell" id="" style={{width: "100%"}}> 
       <div className="spacer"></div> 
      </div> 
     </div> 
    </div> 
    <div className="seller-services-footer-hmd border-color-squid-ink     padding-left-xlarge padding-right-xlarge padding-top-minibase padding-bottom-minibase border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-space-between flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell is-fluid" id="" style={{width: "100%"}}> 
     <div className="has-max-width"> 
      <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-center flex-align-content-center amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "25%"}}> 
       <a className="link-container  " href="#"> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-center flex-full-width amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "100%"}}> 
         <div className=" image image-size-custom align-start  "> 
          <img src="https://m.media-amazon.com/images/G/01/sell/images/smile_logo_squid700.svg" alt="亚马逊徽标" title="亚马逊徽标"  style={{height: "12px",width:"auto"}}data-src="https://m.media-amazon.com/images/G/01/sell/images/smile_logo_squid700.svg" /> 
         </div> 
        </div></a> 
      </div> 
      <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-center flex-align-content-center flex-justify-content flex-justify-content-flex-end amsg-2018 fonts-loaded border-color-squid-ink design-Sell is-fluid is-nowrap" id="" style={{width: "75%"}}> 
       <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-center flex-align-content-flex-start flex-justify-content flex-justify-content-flex-end amsg-2018 fonts-loaded border-color-squid-ink design-Sell is-fluid is-nowrap" id="" style={{width: "50%"}}> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-minibase padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-center amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark">Terms of Service</a> 
         </div> 
        </div> 
        <div className="border-color-squid-ink     padding-left-zero padding-right-xlarge padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-center amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
         <div className="align-start"> 
          <a href="#" className="link  link-type-plain-text-dark">Privacy Policy</a> 
         </div> 
        </div> 
       </div> 
       <div className="border-color-squid-ink     padding-left-zero padding-right-zero padding-top-zero padding-bottom-zero border-left-zero border-right-zero border-top-zero border-bottom-zero flex-container flex-align-items-stretch flex-align-content-center amsg-2018 fonts-loaded border-color-squid-ink design-Sell" id="" style={{width: "50%"}}> 
        <div className="text align-end color-squid-ink font-size-xsmall ember font-normal">
          &copy; 2022，IMETAID.IO Services LLC. 
        </div> 
       </div> 
      </div> 
     </div> 
    </div> 
   </div> 
   <div> 
    <div id="omniture-details" data-page-e-var4="zh-CN" data-page-e-var1="WP" data-page-e-var34="1" data-page-e-var75="" data-page-page-name="SC:US:WP-Welcome" data-page-page-type="Homepage" data-page-prop1="WP" data-page-prop34="1" data-page-prop75=""></div> 
   </div> 
  </div>


      









			</div> 
          </div>
        </div>
      
    );
  }
}

export default Sellercentral;



