import axios from "axios";


const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API, // api 的 base_url
  // timeout: 5000, // request timeout
});

// Add a request interceptor
client.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
client.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

// function client(endpoint, { method, body, ...customConfig } = {}) {
//   const headers = {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   }

//   if (!method) {
//     method = body ? "POST" : "GET";
//   }

//   const url = `${endpoint}`;
//   var config = {
//     url,
//     method: method,
//     ...customConfig,
//     headers: {
//       ...headers,
//       ...customConfig.headers,
//     },
//   };

//   if (body) {
//     config.data = body;
//   }

//   return new Promise((resolve, reject) => {
//     service(config).then((response) => {
//       resolve(response);
//     }).catch((error) => {
//       reject(error);
//     });
//   });
// }

export default client;