import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';

class StatsMarketplace extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left /> 
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">
              <section className="main-content tw-flex tw-flex-col tw-pt-0">
                <div className="row tw-flex tw-flex-col statistics-page__collections-table">
                  <div className="col-12 tw-p-0">
                    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-5 tw-items-end tw-justify-start lg:tw-justify-between tw-mb-4 tw-mx-auto tw-border-b-[1px] tw-border-t-[1px] tw-border-gray-300 tw-px-3">
                      <div className="tw-flex tw-self-start tw-space-x-8">
                        <a href="/stats">
                          <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 tw-flex tw-items-center !tw-px-0 after:!tw-bg-transparent !tw-text-white-primary"><span className="tw-mr-2"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" color="#EBE7EE"><path d="M2.5 13.75L10 18.125L17.5 13.75" stroke="#EBE7EE" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.5 10L10 14.375L17.5 10" stroke="#EBE7EE" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z" stroke="#EBE7EE" strokeLinecap="round" strokeLinejoin="round"></path></svg></span><span className="me-tab2-title !tw-text-[16px]">Collections</span></div>
                        </a>

                        <a href="/stats/marketplace">
                          <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 is-active tw-flex tw-items-center !tw-px-0 after:!tw-bg-transparent !tw-text-pink-primary"><span className="tw-mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#E42575" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" color="#e42575"><path d="M18.3337 10H15.0003L12.5003 17.5L7.50033 2.5L5.00033 10H1.66699" strokeLinecap="round" strokeLinejoin="round"></path></svg></span><span className="me-tab2-title !tw-text-[16px]">Marketplaces</span></div>
                        </a>

                      </div>
                    </div>
                    <div className="tw-flex tw-w-full tw-space-x-3 tw-px-3 tw-items-center tw-justify-between tw-mt-2 tw-mb-6">
                      <div className="tw-flex tw-items-start">
                        <div className="tw-flex">
                          <div className="me-dropdown-container">
                            <div className="cursor-pointer position-relative">
                              <input readOnly="" className="tw-truncate tw-select-none" defaultValue="Last 7 days" style={{ width: "170px" }} />
                              <div className="d-flex align-items-center h-100 chevron-down">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f5f3f7" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </div>
                            <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border" style={{ "position": "absolute", "inset": "0px auto auto 0px", "width": "170px", "transform": "translate(340px, 214px)" }} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom">
                              <div className="tw-absolute tw-bg-gray-200 tw-rounded-xl tw-overflow-auto tw-mt-2 tw-text-white-2 tw-w-full">
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 24 hours</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 7 days</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 30 days</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-px-3">
                      <div className="me-table__container heavy">
                        <div className="overflow-auto dark-scroll-bar">
                          <table role="table" className="me-table heavy">
                            <thead>
                              <tr role="row">
                                <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{cursor: "pointer"}}><span className="caret"></span>#</th>
                                <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{cursor: "pointer"}}><span className="caret"></span>Marketplace</th>
                                <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{cursor: "pointer"}}><span className="caret"></span><span>Volume<span className="tw-ml-2 table-header--time-period">7d</span></span>
                                </th>
                                <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{cursor: "pointer"}}><span className="caret"></span><span>Transactions<span className="tw-ml-2 table-header--time-period">7d</span></span>
                                </th>
                                <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{cursor: "pointer"}}><span className="caret"></span><span>Active Wallets<span className="tw-ml-2 table-header--time-period">7d</span></span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.p1.map((item, index) => (
                              <tr role="row" key={index}>
                                <td role="cell"><span>1</span></td>
                                <td role="cell"><span className="tw-flex tw-items-center tw-justify-start"><span>I META ID</span></span>
                                </td>
                                <td role="cell"><span className="tw-flex tw-items-center justify-content-end"><span className="tw-whitespace-nowrap">◎ 352,509.58</span></span>
                                </td>
                                <td role="cell"><span className="tw-flex tw-items-center justify-content-end"><span className="tw-whitespace-nowrap">463,420</span></span>
                                </td>
                                <td role="cell"><span className="tw-flex tw-items-center justify-content-end"><span className="tw-whitespace-nowrap">75,164</span></span>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tw-flex tw-flex-col tw-mx-2 tw-my-5 tw-space-y-5"><span>1 - Stats are updated every 30 minutes.</span><span>2 - Some marketplaces update their smart contracts frequently, which can lead to a scenario where we were not informed about it; and thus, we might miss some transactions used to calculate this table. In case your marketplace is affected by this, please submit a ticket <a target="_blank" rel="noreferrer" className="tw-text-pink-hot" href="#">here</a> so we can fix it.</span></div>
                    </div>
                    
                  </div>
                </div>
              </section>
              
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default StatsMarketplace;

