import React from 'react';
import Header from "./Header";
import Left from "./Left";
import './Home.css';
import './Launchpad.css';


class Launchpad extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }




  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/>
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left />

            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">

              <div className="carousel-container tw-mb-12">
                <div className="featured-carousel-container tw-flex tw-justify-center">
                  <div className="container">
                    <div className="row mt-72px">
                      <div className="col-12 tw-pl-[5px]">
                        <div className="carousel tw-w-full tw-relative">
                          <div className="slick-slider slick-initialized">
                            <div className="slick-list">
                              <div className="slick-track" style={{"opacity":"1","transform":"translate3d(0px, 0px, 0px)","width":"1153px"}}>
                                <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style={{"outline":"none","width":"1153px"}}>
                                  <div>
                                    <div className="tw-grid tw-grid-cols-11 tw-px-0 tw-w-full tw-h-auto lg:tw-h-600px">
                                      <div className="tw-mt-4 lg:tw-mt-0 tw-col-span-11 tw-order-2 lg:tw-order-1 lg:tw-col-span-5 2xl:tw-col-span-5 tw-pr-2 tw-h-full tw-flex tw-flex-col tw-justify-top">
                                        <div>
                                          <div className="tw-text-pink-primary tw-mt-2 tw-mb-4 fs-12px tw-border tw-border-solid tw-border-pink-primary tw-rounded-md tw-px-2 tw-py-1 tw-w-fit-content tw-tracking-widest">FEATURED LAUNCH</div>
                                          <h2 className="tw-text-5xl ">Lonely Listeners Club</h2>
                                          <p className="tw-mt-3 tw-text-light-gray-500 LaunchpadCarousel_desc__yZOdh">
                                            <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Listening pays off. ‏‏
                                            </p>
                                            <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Building the most innovative Web3 Music Investment Platform. ‎
                                            </p>
                                            <p className="tw-text-light-gray-500 tw-mb-3 tw-text-[16] tx-line-[24]">Run by Music Industry Professionals and backed by the Lonely Listeners Club.</p>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="tw-mt-4 lg:tw-mt-0 tw-col-span-11 tw-order-1 lg:tw-order-1 lg:tw-col-start-7 lg:tw-col-span-5 tw-pr-2 tw-h-full tw-flex tw-flex-col tw-justify-center"><img draggable="false" loading="lazy" src="https://img-cdn.magiceden.dev/rs:fill:800:800:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/051ca6e9095aebe510592628d6d27423/f8caf159" style={{"backgroundColor":"transparent"}} alt="1" /></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row mt-72px">
                    <div className="col-12">
                      <div className="tw-flex">
                        <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 tw-text-white-1 is-active tw-font-800 tw-px-3 tw-mb-8 tw-mt-10"><span className="me-tab2-title !tw-text-[32px]">Live</span></div>
                        <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 tw-font-800 tw-px-3 tw-mb-8 tw-mt-10"><span className="me-tab2-title !tw-text-[32px]">Claims</span></div>
                      </div>
                      <div className="launch-pad__grid">
                        <a href="/launchpad/attr">
                          <div>
                            <div className="tw-mb-3 tw-w-full tw-h-[265px] tw-overflow-hidden">
                              <video autoPlay="" playsInline="" loop="" className="tw-max-w-full">
                                <source src="https://vz-084cd8a2-5ba.b-cdn.net/69bc6fd2-fd99-43a8-924e-9996957f0a59/play_720p.mp4?ext=mp4" type="video/mp4" />
                              </video>
                            </div>
                            <div className="launch-pad__card__name">Rolling Youth Festival Pass NFT</div>
                            <div className="launch-pad__card__date">LIVE</div>
                            <div className="launch-pad__card__facts">
                              <div>
                                <label>items</label><span>500</span></div>
                              <div>
                                <label>price</label><span>2.00 SOL</span></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-72px">
                    <div className="col-12">
                      <h2 className="tw-text-32px tw-font-800 tw-px-3 tw-mb-8 tw-mt-10">Upcoming</h2>
                      <div className="launch-pad__grid">
                          {this.state.p1.map((item, index) => (
                        <div key={index}>
                              <a href={`/launchpad/+${item.name}`}>
                                <div className="tw-mb-3 tw-w-full tw-h-[265px] tw-overflow-hidden"><img alt="1" draggable="false" loading="lazy" src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/8ab9ee3117a976e9382504d91352cedb/3fc23556" style={{ backgroundColor: "transparent" }} /></div>
                                <div className="launch-pad__card__name">Infinite Drive: Car Series 1</div>
                                <div className="launch-pad__card__date"><span>05d 09h 04m</span></div>
                                <div className="launch-pad__card__facts">
                                  <div>
                                    <label>items</label><span>3565</span></div>
                                  <div>
                                    <label>price</label><span>2.00 SOL</span></div>
                                </div>
                              </a>
                        </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-72px">
                    <div className="col-12">
                      <h2 className="tw-text-32px tw-font-800 tw-px-3 tw-mb-8 tw-mt-10">Ended</h2>
                      <div className="launch-pad__grid">
                        {this.state.p1.map((item, index) => (
                          <div key={index}>
                            <a href={`/launchpad/+${item.name}`}>
                              <div className="tw-mb-3 tw-w-full tw-h-[265px] tw-overflow-hidden"><img alt="1" draggable="false" loading="lazy" src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/8ab9ee3117a976e9382504d91352cedb/3fc23556" style={{ backgroundColor: "transparent" }} /></div>
                              <div className="launch-pad__card__name">Infinite Drive: Car Series 1</div>
                              <div className="launch-pad__card__date"><span>05d 09h 04m</span></div>
                              <div className="launch-pad__card__facts">
                                <div>
                                  <label>items</label><span>3565</span></div>
                                <div>
                                  <label>price</label><span>2.00 SOL</span></div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>



                </div>
              </div>





            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Launchpad;

