import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import LaunchpadAbout from './LaunchpadAbout';
import LaunchpadContent from './LaunchpadContent';
import Launchpad from './Launchpad';
import Marketplace from './Marketplace';
import Auction from './Auction';
import AuctionContent from './AuctionContent';
import Drops from './Drops';
import DropsContent from './DropsContent';
import PopularCollections from './PopularCollections';
import Stats from './Stats';
import StatsMarketplace from './StatsMarketplace';
import WatchList from './WatchList';
import Games from './Games';
import Browse from './Browse';
import GamesContent from './GamesContent';
import Lottery from './Lottery';
import Details from './Details';
import About from './About';
import Sellercentral from './Sellercentral';
import Me from './Me';
import "./App.css"



// import { ethers } from "ethers";
// import { nftMarketplace, basicNft, basicNftTwo, buyNft, getAddress, getNft, getNfts } from "./utils/contractUtils"


// import { useQuery } from "@apollo/client"
// import GET_ACTIVE_ITEMS from "./utils/subgraphQueries";

// console.log('process.env.REACT_APP_HELLO', process.env.REACT_APP_HELLO)
// console.log('process.env.NODE_ENV', process.env.NODE_ENV)

function App() {
  return (
    < Router >
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/launchpad" element={<Launchpad />}></Route>
        <Route path="/launchpad/about" element={<LaunchpadAbout />}></Route>
        <Route path="/launchpad/:str" element={<LaunchpadContent />}></Route>
        <Route path="/marketplace/:address" element={<Marketplace />}></Route>
        <Route path="/marketplace/details/:str" element={<Details />}></Route>
        <Route path="/auction" element={<Auction />}></Route>
        <Route path="/auction/:str" element={<AuctionContent />}></Route>
        <Route path="/drops" element={<Drops />}></Route>
        <Route path="/drops/:str" element={<DropsContent />}></Route>
        <Route path="/popular-collections" element={<PopularCollections />} ></Route>
        <Route path="/stats" element={<Stats />}></Route>
        <Route path="/stats/marketplace" element={<StatsMarketplace />}></Route>
        <Route path="/watchlist" element={<WatchList />}></Route>
        <Route path="/Games" element={<Games />}></Route>
        <Route path="/games/browse" element={<Browse />}></Route>
        <Route path="/games/content" element={<GamesContent />}></Route>
        <Route path="/games/details" element={<Details />}></Route>
        <Route path="/games/lottery" element={<Lottery />}></Route>
        <Route path="/games/details/:str" element={<Details />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/sellercentral" element={<Sellercentral />}></Route>
        <Route path="/sellercentral" element={<Sellercentral />}></Route>
        <Route path="/me" element={<Me />}></Route>
      </Routes>
    </Router >
  );
}
export default App;
