import client from "./apiService"


//

const popularCollections = () => {
    return client.get(`https://stats-mainnet.magiceden.io/collection_stats/popular_collections/sol?limit=10&window=1d`)
}

const getNFTByMintAddress = () => {
    return client.get(`https://api-mainnet.magiceden.io/rpc/getNFTByMintAddress/haoJpuAfuBuj5AVKa6PVNGzcoEc855Aj5kjFJLfDQAK`)
}

const getBidsByMintAddresses = () => {
    return client.get(`https://api-mainnet.magiceden.io/idxv2/getBidsByMintAddresses?hideExpired=true&mintAddresses=haoJpuAfuBuj5AVKa6PVNGzcoEc855Aj5kjFJLfDQAK&direction=1&field=2&limit=500&offset=0`)
}

const getGlobalActivitiesByQuery = () => {
    return client.get(`https://api-mainnet.magiceden.io/rpc/getGlobalActivitiesByQuery?q=%7B%22%24match%22%3A%7B%22mint%22%3A%22haoJpuAfuBuj5AVKa6PVNGzcoEc855Aj5kjFJLfDQAK%22%2C%22source%22%3A%7B%22%24nin%22%3A%5B%22yawww%22%2C%22solanart%22%2C%22tensortrade%22%2C%22hadeswap%22%2C%22coralcube_v2%22%2C%22elixir_buy%22%2C%22elixir_sell%22%2C%22hyperspace%22%5D%7D%7D%2C%22%24sort%22%3A%7B%22blockTime%22%3A-1%2C%22createdAt%22%3A-1%7D%2C%22%24skip%22%3A0%7D`)
}

export { popularCollections, getNFTByMintAddress, getBidsByMintAddresses, getGlobalActivitiesByQuery };