import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { TransactionsProvider } from './context/TransactionContext'
import { ConfigProvider } from 'antd';


import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client"

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_PUBLIC_SUBGRAPH_URL,
})

console.log('CHANID', process.env.REACT_APP_CHANID)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TransactionsProvider>
      <ApolloProvider client={client}>
        {/*  */}
        <ConfigProvider theme={{ token: { colorPrimary: '#12263c', }, }} >
          <App />
        </ConfigProvider>
      </ApolloProvider>
    </TransactionsProvider>



  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
