import React from 'react';
import Header from "./Header";
import Left from "./Left";
import Icon from './Icon';
import './Home.css';

export default class Lottery extends React.Component {
    state = {
        tabIndex: 0,
        p1: [
            { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
            { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
            { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
            { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
            { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        ],
        lotteryDate: new Date().getTime() + 33 * 60 * 60 * 1000,
        lotteryDateStr: ''
    };

    componentDidMount() {
        function checkTime(i) {
            if (i < 10 && i != 0) {
                i = "0" + i;
            }
            return i;
        }
        setInterval(() => {
            let now = new Date().getTime()

            var dateDiff = this.state.lotteryDate - now;
            var days = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出天数
            //计算出小时数
            var leave1 = dateDiff % (24 * 3600 * 1000)  //计算天数后剩余的毫秒数
            var hours = Math.floor(leave1 / (3600 * 1000))
            //计算相差分钟数
            var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
            var minutes = Math.floor(leave2 / (60 * 1000))
            //计算相差秒数
            var leave3 = leave2 % (60 * 1000)   //计算分钟数后剩余的毫秒数
            var seconds = Math.round(leave3 / 1000)
            //调用checkTime方法将数字小于10的在前面补0
            days = checkTime(days);
            hours = checkTime(hours);
            minutes = checkTime(minutes);
            seconds = checkTime(seconds);
            this.setState({
                lotteryDateStr: `${days} days ${hours} : ${minutes}: ${seconds}`
            })
        }, 1000)
    }

    render() {
        return (
            <div className="App">
                <div className="main page tw-bg-[#120d18]">
                    <Header />
                    <div className="tw-flex tw-w-full tw-flex-auto">
                        <Left name="Sidebar_slim__EH6w7" />

                        <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__slim lg:tw-ml-80px">
                            <div className="tw-mt-8 tw-mb-16 container full">
                                <h1 className="tw-mb-8 tw-text-5xl tw-font-extrabold FeaturedCarouselV2_carouselItemTitle__E7-QW tw-text-center">Lottery Pool</h1>
                                {<div className="tw-mx-auto tw-max-w-2xl tw-mb-8 md:tw-mb-16">
                                    {false && <p className="tw-text-gray-3 tw-mt-4 tw-text-center">Mark your calendars. Get ready to mint the latest STAR NFT projects
                                        <br />Minting across <a className="tw-text-pink-primary" href="/#launchpad">Launchpad</a> on a Blockchain near you!
                                    </p>}
                                    <div className="tw-border-solid tw-border tw-border-yellow-1 tw-rounded-lg tw-p-3 tw-mt-4 text-white tw-flex tw-gap-2 tw-items-center">
                                        <Icon name="WarningTriangle" />Countdown to the lottery: {this.state.lotteryDateStr}
                                    </div>
                                </div>}
                                <div className="tw-mb-12 !tw-w-[min(100%,1024px)] tw-mx-auto">
                                    <div className="tw-flex tw-gap-2 md:tw-gap-8 tw-flex-row tw-flex-wrap tw-gap-y-4">
                                        <div className="tw-flex">
                                            <button onClick={() => this.setState({ tabIndex: 0 })}>
                                                <div className={`tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 ${this.state.tabIndex === 0 ? "is-active" : ""}`}>
                                                    <span className="me-tab2-title">Tokens</span>
                                                </div>
                                            </button>

                                            <button onClick={() => this.setState({ tabIndex: 1 })}>
                                                <div className={`tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 ${this.state.tabIndex === 1 ? "is-active" : ""}`}>
                                                    <span className="me-tab2-title">Winners</span>
                                                    {false && <span className="tw-absolute tw-animate-pulse tw-right-1 tw-h-2 tw-w-2 tw-bg-pink-primary tw-rounded-full"></span>}
                                                </div>
                                            </button>

                                        </div>
                                        {false && <div className="tw-ml-auto tw-flex tw-gap-2 md:tw-gap-4 tw-items-center">
                                            <div className="tw-flex tw-flex-nowrap tw-rounded tw-border-solid tw-border-gray-300 tw-border tw-rounded-[40px]">
                                                <div className="tw-flex tw-items-center tw-relative tw-rounded-full tw-pr-1 tw-bg-pink-primary">
                                                    <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 tw-rounded-[40px] tw-bg-pink-primary hover:tw-opacity-80">Show all</button>
                                                </div>
                                                <div className="tw-flex tw-items-center tw-relative tw-rounded-full tw-pr-1">
                                                    <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 tw-rounded-[40px] hover:tw-opacity-80">
                                                        <div className="tw-flex tw-items-center tw-gap-2">
                                                            <div className="tw-bg-gray-300 tw-rounded-full tw-p-1 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                    <path d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z" fill="url(#paint0_linear_354_8099_9452234539)"></path>
                                                                    <path d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z" fill="url(#paint1_linear_354_8099_7482901644)"></path>
                                                                    <path d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z" fill="url(#paint2_linear_354_8099_8474888147)"></path>
                                                                    <defs>
                                                                        <linearGradient id="paint0_linear_354_8099_9452234539" x1="16.6538" y1="1.87538" x2="7.1259" y2="20.1251" gradientUnits="userSpaceOnUse">
                                                                            <stop stopColor="#00FFA3"></stop>
                                                                            <stop offset="1" stopColor="#DC1FFF"></stop>
                                                                        </linearGradient>
                                                                        <linearGradient id="paint1_linear_354_8099_7482901644" x1="12.4877" y1="-0.299659" x2="2.95979" y2="17.9501" gradientUnits="userSpaceOnUse">
                                                                            <stop stopColor="#00FFA3"></stop>
                                                                            <stop offset="1" stopColor="#DC1FFF"></stop>
                                                                        </linearGradient>
                                                                        <linearGradient id="paint2_linear_354_8099_8474888147" x1="14.5575" y1="0.78106" x2="5.02959" y2="19.0308" gradientUnits="userSpaceOnUse">
                                                                            <stop stopColor="#00FFA3"></stop>
                                                                            <stop offset="1" stopColor="#DC1FFF"></stop>
                                                                        </linearGradient>
                                                                    </defs>
                                                                </svg>
                                                            </div><span>Solana</span></div>
                                                    </button>
                                                </div>
                                                <div className="tw-flex tw-items-center tw-relative tw-rounded-full tw-pr-1">
                                                    <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 tw-rounded-[40px] hover:tw-opacity-80">
                                                        <div className="tw-flex tw-items-center tw-gap-2">
                                                            <div className="tw-bg-gray-300 tw-rounded-full tw-p-1 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                                                    <g fill="none" fillRule="evenodd">
                                                                        <g fill="#FFF" fillRule="nonzero">
                                                                            <path fillOpacity=".602" d="M16.498 4v8.87l7.497 3.35z"></path>
                                                                            <path d="M16.498 4L9 16.22l7.498-3.35z"></path>
                                                                            <path fillOpacity=".602" d="M16.498 21.968v6.027L24 17.616z"></path>
                                                                            <path d="M16.498 27.995v-6.028L9 17.616z"></path>
                                                                            <path fillOpacity=".2" d="M16.498 20.573l7.497-4.353-7.497-3.348z"></path>
                                                                            <path fillOpacity=".602" d="M9 16.22l7.498 4.353v-7.701z"></path>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div><span>Ethereum</span></div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="tw-flex tw-gap-4">
                                                <a className="tw-hidden md:tw-block" href="#">
                                                    <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-w-full tw-h-12 PlainButton_primary__22Ken">List Your Project</button>
                                                </a>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="tw-mx-auto 3xl:tw-min-w-[1024px] 3xl:tw-max-w-7xl">
                                    {/* tokens */}
                                    {this.state.tabIndex === 0 && <div className="me-table__container bordered slim-padding">
                                        <div className="overflow-auto dark-scroll-bar">
                                            <table role="table" className="me-table bordered">
                                                <thead>
                                                    <tr role="row">
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret"></span>Transaction</th>
                                                        <th colSpan="1" role="columnheader"><span className="caret"></span>Community</th>
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret-up"></span>When</th>
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret"></span>Blockchain</th>
                                                        <th colSpan="1" role="columnheader"><span className="caret"></span>Total prize money</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {this.state.p1.map(item =>
                                                        <tr role="row">
                                                            <td role="cell">
                                                                <a className="tw-flex tw-gap-4 tw-items-center" href="#/drops/trophy_hunters?tab=allDrops">
                                                                    <div className="tw-w-16 tw-h-16 tw-rounded tw-overflow-hidden tw-flex tw-items-center tw-justify-center">
                                                                        <img draggable="false" loading="lazy" className="tw-object-cover" src="/static/images/game.png" alt={1} style={{ backgroundColor: 'transparent' }} />
                                                                    </div>
                                                                    <span className="tw-text-white-2 tw-text-base">IMETAID TOKEN</span>
                                                                </a>
                                                            </td>
                                                            <td role="cell">
                                                                <div className="tw-flex tw-gap-2"><a href="#" target="_blank" rel="noreferrer noopener"><img className="tw-w-6" src="/static/media/globe_white.1cc8238d59c1be911999eabdeeb72b8c.svg" alt="website link icon" /></a><a href="#" target="_blank" rel="noreferrer noopener"><img className="tw-w-6" src="/static/media/twitter_white.646bf11eec160fc04b57653d5d95478b.svg" alt="twitter link icon" /></a><a href="#" target="_blank" rel="noreferrer noopener"><img className="tw-w-6" src="/static/media/discord_white.a80cd7b6a598d458f12090e4f0316953.svg" alt="discord link icon" /></a></div>
                                                            </td>
                                                            <td role="cell">Aug 11, 13:00</td>
                                                            <td role="cell">
                                                                <span className="tw-flex tw-items-center tw-gap-2"> <Icon name="ETH" /> Ethereum</span>
                                                            </td>
                                                            <td role="cell">2000</td>
                                                        </tr>
                                                    )}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-2 tw-gap-y-5 me-table__pagination mt-2">
                                            <div>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2" disabled="">&lt;&lt;</button>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2" disabled="">&lt;</button>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2">&gt;</button>
                                                <button className="me-table__pagination-btn tw-px-2">&gt;&gt;</button>
                                            </div>
                                            <div><span>Page <strong>1 of 12</strong> </span><span>&nbsp;|&nbsp; Go to page: <input className="me-input-box" type="number" defaultValue="1" style={{ width: "100px", height: "36px" }} /></span> </div>
                                            <div className="me-dropdown-container ms-auto me-table__pagination-page-size !tw-w-[100px]">
                                                <div className="cursor-pointer position-relative">
                                                    <input readOnly="" className="tw-truncate tw-select-none" defaultValue="25" />
                                                    <div className="d-flex align-items-center h-100 chevron-down">
                                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f5f3f7" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                                                            <polyline points="6 9 12 15 18 9"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border" data-popper-reference-hidden="true" data-popper-escaped="true" data-popper-placement="top-start" style={{ "position": "absolute", "inset": "auto auto 0px 0px", "width": "100px", "transform": "translate(1527px, 2148px)" }}>
                                                    <div className="me-select dark-scroll-bar">
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>10</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex me-selected-item fw-500"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575" className="me-10px"><path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M22 4L12 14.01L9 11.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>25</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>50</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>100</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {/* winners */}
                                    {this.state.tabIndex === 1 && <div className="me-table__container bordered slim-padding">
                                        <div className="overflow-auto dark-scroll-bar">
                                            <table role="table" className="me-table bordered">
                                                <thead>
                                                    <tr role="row">
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret"></span>User Name</th>
                                                        <th colSpan="1" role="columnheader"><span className="caret"></span>Token</th>
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret-up"></span>When</th>
                                                        <th colSpan="1" role="columnheader" title="Toggle SortBy" style={{ cursor: "pointer" }}><span className="caret"></span>Blockchain</th>
                                                        <th colSpan="1" role="columnheader"><span className="caret"></span>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {this.state.p1.map(item =>
                                                        <tr role="row">
                                                            <td role="cell">Jack Zhang</td>
                                                            <td role="cell">
                                                                <a className="tw-flex tw-gap-4 tw-items-center" href="#/drops/trophy_hunters?tab=allDrops">
                                                                    <div className="tw-w-16 tw-h-16 tw-rounded tw-overflow-hidden tw-flex tw-items-center tw-justify-center">
                                                                        <img draggable="false" loading="lazy" className="tw-object-cover" src="/static/images/game.png" alt={1} style={{ backgroundColor: 'transparent' }} />
                                                                    </div>
                                                                    <span className="tw-text-white-2 tw-text-base">IMETAID TOKEN</span>
                                                                </a>
                                                            </td>
                                                            <td role="cell">Aug 11, 13:00</td>
                                                            <td role="cell">
                                                                <span className="tw-flex tw-items-center tw-gap-2"> <Icon name="ETH" /> Ethereum</span>
                                                            </td>

                                                            <td role="cell">200</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-2 tw-gap-y-5 me-table__pagination mt-2">
                                            <div>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2" disabled="">&lt;&lt;</button>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2" disabled="">&lt;</button>
                                                <button className="me-table__pagination-btn tw-mr-2 tw-px-2">&gt;</button>
                                                <button className="me-table__pagination-btn tw-px-2">&gt;&gt;</button>
                                            </div>
                                            <div><span>Page <strong>1 of 12</strong> </span><span>&nbsp;|&nbsp; Go to page: <input className="me-input-box" type="number" defaultValue="1" style={{ width: "100px", height: "36px" }} /></span> </div>
                                            <div className="me-dropdown-container ms-auto me-table__pagination-page-size !tw-w-[100px]">
                                                <div className="cursor-pointer position-relative">
                                                    <input readOnly="" className="tw-truncate tw-select-none" defaultValue="25" />
                                                    <div className="d-flex align-items-center h-100 chevron-down">
                                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f5f3f7" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                                                            <polyline points="6 9 12 15 18 9"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border" data-popper-reference-hidden="true" data-popper-escaped="true" data-popper-placement="top-start" style={{ "position": "absolute", "inset": "auto auto 0px 0px", "width": "100px", "transform": "translate(1527px, 2148px)" }}>
                                                    <div className="me-select dark-scroll-bar">
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>10</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex me-selected-item fw-500"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#e42575" className="me-10px"><path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M22 4L12 14.01L9 11.01" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>25</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>50</div>
                                                        <div tabIndex="0" className="me-select-item tw-flex"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#594A68" className="me-10px"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>100</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true" style={{ "backdropFilter": "blur(20px)" }}>
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content position-relative tw-bg-gray-100 tw-rounded-xl tw-p-4 tw-border-gray-500">
                                        <div className="modal-body">
                                            <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
                                                <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-text-white-2">
                                                    <h2 className="tw-text-xl tw-mb-2">Before you mint… as always, DYOR.</h2>
                                                    <p className="tw-mb-2">Projects featured are upcoming NFT projects that are not an endorsement from I META ID or a guarantee of listing on I META ID post-mint.</p>
                                                    <p><a className="tw-text-pink-primary" href="#" target="_blank" rel="noreferrer">Learn more about DYOR</a></p>
                                                    <div className="tw-mt-10">
                                                        <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-mr-2 PlainButton_transparent__LvWuT">I understand</button>
                                                        <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 PlainButton_transparent__LvWuT">Take me back</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div >
        );
    }
}

