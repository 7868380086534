import React from "react";
import Header from "./Header";
import Left from "./Left";
import ComingSoon from "./components/ComingSoon";
import "./Home.css";
import styles from './extend.module.css';

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      p1: [
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "15.47",
        },
        {
          name: "Lanren",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "15.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
        {
          name: "NoSignal",
          pic: "/static/media/ntf1.png",
          price: "0.85",
          priceLast: "10.47",
        },
      ],

      p2: [
        {
          name: "NFT Leaguez",
          pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif",
          code: "01d  07h 58m",
        },
        {
          name: "NFT Leaguez",
          pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif",
          code: "01d  07h 58m",
        },
        {
          name: "NFT Leaguez",
          pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif",
          code: "01d  07h 58m",
        },
        {
          name: "NFT Leaguez",
          pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif",
          code: "01d  07h 58m",
        },
        {
          name: "NFT Leaguez",
          pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592",
          code: "01d  07h 58m",
        },
      ],
    };
  }
  componentDidMount() { }

  render() {
    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
          <Header />
          <div className="tw-flex tw-w-full tw-flex-auto">
            <Left />
            <div
              id="content"
              className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px"
            >
              {true ? <ComingSoon /> : (<section className="main-content tw-flex tw-flex-col tw-pt-0">
                <div className="row tw-flex tw-flex-col statistics-page__collections-table">
                  <div className="col-12 tw-p-0">
                    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-5 tw-items-end tw-justify-start lg:tw-justify-between tw-mb-4 tw-mx-auto tw-border-b-[1px] tw-border-t-[1px] tw-border-gray-300 tw-px-3">
                      <div className="tw-flex tw-self-start tw-space-x-8">
                        <a href="/stats">
                          <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 is-active tw-flex tw-items-center !tw-px-0 after:!tw-bg-transparent !tw-text-pink-primary">
                            <span className="tw-mr-2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                color="#E42575"
                              >
                                <path
                                  d="M2.5 13.75L10 18.125L17.5 13.75"
                                  stroke="#E42575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M2.5 10L10 14.375L17.5 10"
                                  stroke="#E42575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M2.5 6.25L10 10.625L17.5 6.25L10 1.875L2.5 6.25Z"
                                  stroke="#E42575"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <span className="me-tab2-title !tw-text-[16px]">
                              Collections
                            </span>
                          </div>
                        </a>

                        <a href="/stats/marketplace">
                          <div className="tw-relative me-tab2 tw-flex tw-cursor-pointer tw-mr-2 tw-py-2 tw-px-4 tw-flex tw-items-center !tw-px-0 after:!tw-bg-transparent tw-text-white-primary !tw-text-white-primary">
                            <span className="tw-mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#EBE7EE"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                color="#e42575"
                              >
                                <path
                                  d="M18.3337 10H15.0003L12.5003 17.5L7.50033 2.5L5.00033 10H1.66699"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <span className="me-tab2-title !tw-text-[16px]">
                              Marketplaces
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="tw-flex tw-w-full tw-space-x-3 tw-px-3 tw-items-center tw-justify-between tw-mt-2 tw-mb-6">
                      <div className="tw-flex-1">
                        <div>
                          <div
                            className="accordion accordion-flush open"
                            id="accordion-0"
                          >
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="flush-heading-0"
                              >
                                <button
                                  className="accordion-button tw-h-[40px] !tw-mt-0 !tw-bg-gray-300 !tw-text-light-gray-500 collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapse-0"
                                  aria-expanded="false"
                                  aria-controls="flush-collapse-0"
                                >
                                  <span className="tw-mr-2">
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      color="#A197AA"
                                      height="24"
                                      width="24"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(161, 151, 170)" }}
                                    >
                                      <circle cx="11" cy="11" r="8"></circle>
                                      <line
                                        x1="21"
                                        y1="21"
                                        x2="16.65"
                                        y2="16.65"
                                      ></line>
                                    </svg>
                                  </span>
                                  <div className={styles.placeholder}><input type="text" style={{ border: "0px", color: "#fff" }} placeholder="Search" /></div>
                                </button>
                              </h2>
                              <div
                                id="flush-collapse-0"
                                className="mt-1 accordion-collapse collapse"
                                aria-labelledby="flush-heading-0"
                                data-bs-parent="#accordion-0"
                              >
                                <div className="p-0 accordion-body tw-rounded-lg !tw-bg-gray-300 !tw-text-light-gray-500">
                                  <div className="tw-m-4">
                                    <div>
                                      Select collections which match
                                      <div className="tw-flex tw-flex-nowrap tw-rounded tw-border-solid tw-border-gray-300 tw-border Filters_pillTabs__faLBS">
                                        <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Filters_pillTab__p7lsP tw-bg-pink-primary hover:tw-opacity-80">
                                          all
                                        </button>
                                        <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Filters_pillTab__p7lsP hover:tw-opacity-80">
                                          any
                                        </button>
                                      </div>
                                      of the following conditions:
                                    </div>
                                    <div>
                                      <div className="Filters_predicate__5VO9s">
                                        <div className="me-dropdown-container Filters_select__Ygket">
                                          <div className="cursor-pointer position-relative">
                                            <input
                                              readOnly=""
                                              className="tw-truncate tw-select-none"
                                              defaultValue="ME Floor Price"
                                            />
                                            <div className="d-flex align-items-center h-100 chevron-down">
                                              <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                color="#f5f3f7"
                                                height="24"
                                                width="24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                  color: "rgb(245, 243, 247)",
                                                }}
                                              >
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                              </svg>
                                            </div>
                                          </div>
                                          <div
                                            aria-label="dropdown-list"
                                            className="dropdown tw-text-secondary no-border"
                                            style={{
                                              position: "absolute",
                                              inset: "0px auto auto 0px",
                                              width: "0px",
                                              transform: "translate(0px, 4px)",
                                            }}
                                            data-popper-reference-hidden="true"
                                            data-popper-escaped="true"
                                            data-popper-placement="bottom-start"
                                          >
                                            <div className="me-select dark-scroll-bar">
                                              <div
                                                tabIndex="0"
                                                className="me-select-item tw-flex"
                                              >
                                                <span>
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    color="#594A68"
                                                    className="me-10px"
                                                  >
                                                    <path
                                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                Collection Name
                                              </div>
                                              <div
                                                tabIndex="0"
                                                className="me-select-item tw-flex me-selected-item fw-500"
                                              >
                                                <span>
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    color="#e42575"
                                                    className="me-10px"
                                                  >
                                                    <path
                                                      d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                    <path
                                                      d="M22 4L12 14.01L9 11.01"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                ME Floor Price
                                              </div>
                                              <div
                                                tabIndex="0"
                                                className="me-select-item tw-flex"
                                              >
                                                <span>
                                                  <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    color="#594A68"
                                                    className="me-10px"
                                                  >
                                                    <path
                                                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                      stroke="currentColor"
                                                      strokeWidth="1.5"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    ></path>
                                                  </svg>
                                                </span>
                                                Volume
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="Filters_inline__nsFEC">
                                          <div className="me-dropdown-container Filters_select__Ygket">
                                            <div className="cursor-pointer position-relative">
                                              <input
                                                readOnly=""
                                                className="tw-truncate tw-select-none"
                                                defaultValue="greater than"
                                              />
                                              <div className="d-flex align-items-center h-100 chevron-down">
                                                <svg
                                                  stroke="currentColor"
                                                  fill="none"
                                                  strokeWidth="2"
                                                  viewBox="0 0 24 24"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  color="#f5f3f7"
                                                  height="24"
                                                  width="24"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  style={{
                                                    color: "rgb(245, 243, 247)",
                                                  }}
                                                >
                                                  <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg>
                                              </div>
                                            </div>
                                            <div
                                              aria-label="dropdown-list"
                                              className="dropdown tw-text-secondary no-border"
                                              style={{
                                                position: "absolute",
                                                inset: "0px auto auto 0px",
                                                width: "0px",
                                                transform:
                                                  "translate(0px, 4px)",
                                              }}
                                              data-popper-reference-hidden="true"
                                              data-popper-escaped="true"
                                              data-popper-placement="bottom-start"
                                            >
                                              <div className="me-select dark-scroll-bar">
                                                <div
                                                  tabIndex="0"
                                                  className="me-select-item tw-flex me-selected-item fw-500"
                                                >
                                                  <span>
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      color="#e42575"
                                                      className="me-10px"
                                                    >
                                                      <path
                                                        d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                      <path
                                                        d="M22 4L12 14.01L9 11.01"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  greater than
                                                </div>
                                                <div
                                                  tabIndex="0"
                                                  className="me-select-item tw-flex"
                                                >
                                                  <span>
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      color="#594A68"
                                                      className="me-10px"
                                                    >
                                                      <path
                                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  less than
                                                </div>
                                                <div
                                                  tabIndex="0"
                                                  className="me-select-item tw-flex"
                                                >
                                                  <span>
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      color="#594A68"
                                                      className="me-10px"
                                                    >
                                                      <path
                                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  greater than or equal to
                                                </div>
                                                <div
                                                  tabIndex="0"
                                                  className="me-select-item tw-flex"
                                                >
                                                  <span>
                                                    <svg
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      color="#594A68"
                                                      className="me-10px"
                                                    >
                                                      <path
                                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </span>
                                                  less than or equal to
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <input
                                            className="numeric-input Filters_input__5tL1J"
                                            defaultValue="0"
                                          />
                                          <div className="tw-inline-flex">
                                            <span className="Filters_delete__Xu6Tu">
                                              <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                color="#e42575"
                                                height="24"
                                                width="24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                  color: "rgb(228, 37, 117)",
                                                }}
                                              >
                                                <circle
                                                  cx="18"
                                                  cy="5"
                                                  r="3"
                                                ></circle>
                                                <circle
                                                  cx="6"
                                                  cy="12"
                                                  r="3"
                                                ></circle>
                                                <circle
                                                  cx="18"
                                                  cy="19"
                                                  r="3"
                                                ></circle>
                                                <line
                                                  x1="8.59"
                                                  y1="13.51"
                                                  x2="15.42"
                                                  y2="17.49"
                                                ></line>
                                                <line
                                                  x1="15.41"
                                                  y1="6.51"
                                                  x2="8.59"
                                                  y2="10.49"
                                                ></line>
                                              </svg>
                                            </span>
                                            <span className="Filters_delete__Xu6Tu">
                                              <svg
                                                stroke="currentColor"
                                                fill="none"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                color="#e42575"
                                                height="24"
                                                width="24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                  color: "rgb(228, 37, 117)",
                                                }}
                                              >
                                                <line
                                                  x1="18"
                                                  y1="6"
                                                  x2="6"
                                                  y2="18"
                                                ></line>
                                                <line
                                                  x1="6"
                                                  y1="6"
                                                  x2="18"
                                                  y2="18"
                                                ></line>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-h-8 PlainButton_bordered__3suwZ"
                                      >
                                        + Add filter
                                      </button>
                                      <button
                                        type="button"
                                        className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-ml-2 tw-h-8"
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tw-flex tw-items-start">
                        <div className="tw-flex">
                          <div className="me-dropdown-container">
                            <div className="cursor-pointer position-relative">
                              <input
                                readOnly=""
                                className="tw-truncate tw-select-none"
                                defaultValue="Last 24 hours"
                                style={{ width: "170px" }}
                              />
                              <div className="d-flex align-items-center h-100 chevron-down">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  color="#f5f3f7"
                                  height="24"
                                  width="24"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ color: "rgb(245, 243, 247)" }}
                                >
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </div>
                            <div
                              aria-label="dropdown-list"
                              className="dropdown tw-text-secondary no-border"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto 0px",
                                width: "170px",
                                transform: "translate(1794px, 217px)",
                              }}
                              data-popper-reference-hidden="false"
                              data-popper-escaped="false"
                              data-popper-placement="bottom"
                            >
                              <div className="tw-absolute tw-bg-gray-200 tw-rounded-xl tw-overflow-auto tw-mt-2 tw-text-white-2 tw-w-full">
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 5 minutes
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 15 minutes
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 1 hour
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 6 hours
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 24 hours
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 7 days
                                </button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">
                                  Last 30 days
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-px-3">
                      <div
                        className="me-table__container"
                        style={{
                          height: "calc(100vh - 200px)",
                          border: "1px solid rgb(43, 32, 55)",
                        }}
                      >
                        <div
                          data-test-id="virtuoso-scroller"
                          data-virtuoso-scroller="true"
                          tabIndex="0"
                          className="dark-scroll-bar"
                          style={{
                            height: "100%",
                            outline: "none",
                            overflowY: "auto",
                            position: "relative",
                          }}
                        >
                          <div
                            data-viewport-type="element"
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: "0px",
                            }}
                          >
                            <table
                              role="table"
                              className="me-table transparent"
                              style={{ borderSpacing: "0px" }}
                            >
                              <thead
                                className="tw-border-b-[1px] tw-border-gray-400 tw-fixed"
                                style={{
                                  zIndex: "1",
                                  position: "sticky",
                                  top: "0px",
                                  backgroundColor: "rgba(12,39,62)",
                                }}
                              >
                                <tr role="row">
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className=""
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>Collection
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>Volume Total
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>
                                    <span>24h Volume</span>
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>
                                    <span>24h % Volume</span>
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>
                                    <span>Floor Price</span>
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>
                                    <div className="tw-mx-2 tw-w-full tw-text-right">
                                      Owners
                                    </div>
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    className="tw-text-right"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="caret"></span>
                                    <span>Total Supply</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody data-test-id="virtuoso-item-list">
                                {this.state.p1.map((item, index) => (
                                  <tr
                                    key={index}
                                    role="row"
                                    data-index="0"
                                    data-known-size="61"
                                    data-item-index="0"
                                    className="!tw-border-b-[1px] !tw-border-gray-400 me-table-border"
                                    style={{ overflowAnchor: "none" }}
                                  >
                                    <td role="cell">
                                      <a
                                        className="tw-flex tw-min-w-0 tw-items-center tw-justify-start tw-space-x-3 tw-cursor-pointer"
                                        href="/marketplace/"
                                        style={{ width: "480px" }}
                                      >
                                        <div className="tw-flex-shrink-0">
                                          {index + 1}
                                        </div>
                                        <div className="tw-flex-shrink-0 tw-rounded-full">
                                          <img
                                            draggable="false"
                                            loading="lazy"
                                            height="32"
                                            width="32"
                                            className="tw-h-[32px] tw-w-[32px] tw-rounded-full"
                                            src="https://img-cdn.magiceden.dev/rs:fill:32:32:0:0/plain/https://creator-hub-prod.s3.us-east-2.amazonaws.com/genesis_genopets_habitats_pfp_1649105768353.gif"
                                            alt=""
                                            style={{
                                              backgroundColor: "transparent",
                                            }}
                                          />
                                        </div>
                                        <div className="mx-2 tw-truncate">
                                          Genesis Genopets Habitats
                                        </div>
                                      </a>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end tw-space-x-1">
                                        <div
                                          className="tw-cursor-help tw-max-w-[100px] tw-truncate"
                                          data-tooltipped=""
                                          aria-describedby="tippy-tooltip-242"
                                          data-original-title="148.1K ◎"
                                          style={{ display: "inline" }}
                                        >
                                          <span className="tw-whitespace-nowrap tw-truncate">
                                            148.1K
                                          </span>
                                        </div>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z"
                                            fill="url(#paint0_linear_354_8099_4590429120)"
                                          ></path>
                                          <path
                                            d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z"
                                            fill="url(#paint1_linear_354_8099_6899255644)"
                                          ></path>
                                          <path
                                            d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z"
                                            fill="url(#paint2_linear_354_8099_1444751919)"
                                          ></path>
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_354_8099_4590429120"
                                              x1="16.6538"
                                              y1="1.87538"
                                              x2="7.1259"
                                              y2="20.1251"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint1_linear_354_8099_6899255644"
                                              x1="12.4877"
                                              y1="-0.299659"
                                              x2="2.95979"
                                              y2="17.9501"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint2_linear_354_8099_1444751919"
                                              x1="14.5575"
                                              y1="0.78106"
                                              x2="5.02959"
                                              y2="19.0308"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      </span>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end tw-space-x-1">
                                        <div
                                          className="tw-cursor-help tw-max-w-[100px] tw-truncate"
                                          data-tooltipped=""
                                          aria-describedby="tippy-tooltip-243"
                                          data-original-title="4.3K ◎"
                                          style={{ display: "inline" }}
                                        >
                                          <span className="tw-whitespace-nowrap tw-truncate">
                                            4.3K
                                          </span>
                                        </div>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z"
                                            fill="url(#paint0_linear_354_8099_8921541599)"
                                          ></path>
                                          <path
                                            d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z"
                                            fill="url(#paint1_linear_354_8099_5450425498)"
                                          ></path>
                                          <path
                                            d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z"
                                            fill="url(#paint2_linear_354_8099_1163599230)"
                                          ></path>
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_354_8099_8921541599"
                                              x1="16.6538"
                                              y1="1.87538"
                                              x2="7.1259"
                                              y2="20.1251"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint1_linear_354_8099_5450425498"
                                              x1="12.4877"
                                              y1="-0.299659"
                                              x2="2.95979"
                                              y2="17.9501"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint2_linear_354_8099_1163599230"
                                              x1="14.5575"
                                              y1="0.78106"
                                              x2="5.02959"
                                              y2="19.0308"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      </span>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end cell-increase">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 320 512"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path>
                                        </svg>
                                        0.61%
                                      </span>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end tw-space-x-1">
                                        <div
                                          className="tw-cursor-help tw-max-w-[100px] tw-truncate"
                                          data-tooltipped=""
                                          aria-describedby="tippy-tooltip-244"
                                          data-original-title="35.80 ◎"
                                          style={{ display: "inline" }}
                                        >
                                          <span className="tw-whitespace-nowrap tw-truncate">
                                            35.80
                                          </span>
                                        </div>
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z"
                                            fill="url(#paint0_linear_354_8099_2988457352)"
                                          ></path>
                                          <path
                                            d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z"
                                            fill="url(#paint1_linear_354_8099_1856839323)"
                                          ></path>
                                          <path
                                            d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z"
                                            fill="url(#paint2_linear_354_8099_8611436085)"
                                          ></path>
                                          <defs>
                                            <linearGradient
                                              id="paint0_linear_354_8099_2988457352"
                                              x1="16.6538"
                                              y1="1.87538"
                                              x2="7.1259"
                                              y2="20.1251"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint1_linear_354_8099_1856839323"
                                              x1="12.4877"
                                              y1="-0.299659"
                                              x2="2.95979"
                                              y2="17.9501"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                            <linearGradient
                                              id="paint2_linear_354_8099_8611436085"
                                              x1="14.5575"
                                              y1="0.78106"
                                              x2="5.02959"
                                              y2="19.0308"
                                              gradientUnits="userSpaceOnUse"
                                            >
                                              <stop stopColor="#00FFA3"></stop>
                                              <stop
                                                offset="1"
                                                stopColor="#DC1FFF"
                                              ></stop>
                                            </linearGradient>
                                          </defs>
                                        </svg>
                                      </span>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end tw-space-x-1">
                                        <span className="tw-whitespace-nowrap">
                                          4.1K
                                        </span>
                                      </span>
                                    </td>
                                    <td role="cell">
                                      <span className="tw-flex tw-items-center justify-content-end tw-space-x-1">
                                        <span className="tw-whitespace-nowrap">
                                          5.5K
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stats;
