import { ethers } from "ethers";
import contract from "./contract.json"
import abi from "./abi.json"

const CHAIN_ID = process.env.REACT_APP_CHANID;

// console.log('CHAIN_ID', CHAIN_ID);

const { ethereum } = window;

const createContract = (contractAddress, contractABI) => {
    if (!ethereum) return;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();// 签名
    const contract = new ethers.Contract(contractAddress, contractABI, signer);// 获取合约对象
    return contract;
};

const nftMarketplace = createContract(contract[CHAIN_ID].NftMarketplace, abi.NftMarketplace);
// const basicNft = createContract(contract[CHAIN_ID].BasicNft, abi.BasicNft);
// const basicNftTwo = createContract(contract[CHAIN_ID].BasicNftTwo, abi.BasicNftTwo);

const getAddress = contratName => contract[CHAIN_ID][contratName];

const contracts = [{
    name: `Doodles`,
    nftAddress: getAddress("BasicNft"),
    pic: "/static/images/nft1.avif",
    volumeTotal: "0.85",
    volumeOf24h: "4.3K",
    volumeOfPercent24h: "0.61%",
    floorPrice: 35.80,
    owners: 100,
    totalSupply: 100,
},
{
    name: `Invisible Friends`,
    nftAddress: getAddress("BasicNftTwo"),
    pic: "/static/images/nft2.gif",
    volumeTotal: "0.85",
    volumeOf24h: "4.3K",
    volumeOfPercent24h: "0.61%",
    floorPrice: 35.80,
    owners: 100,
    totalSupply: 100,
},
];

const updateNftInfo = async (nfts) => {
    // console.log('nfts', nfts)
    return nfts.map(element => {
        if (element.nftAddress.toLowerCase() === getAddress("BasicNft").toLowerCase()) {
            return {
                ...element,
                "imageURL": "/static/images/nft1.avif",
                "name": "Doodles"
            };
        } else if (element.nftAddress.toLowerCase() === getAddress("BasicNftTwo").toLowerCase()) {
            return {
                ...element,
                "imageURL": "/static/images/nft2.gif",
                "name": "Invisible Friends"
            }
        } else {
            return {// default
                ...element,
                "imageURL": "/static/images/nft2.gif",
                "name": "Invisible Friends"
            }
        }
    })
}

const buyNft = async (nftAddress, tokenId, price) => {
    const buyResult = await nftMarketplace.buyItem(nftAddress, tokenId, { value: ethers.utils.parseEther(price, 'ether') });
    // console.log(`Loading - ${buyResult.hash}`);
    await buyResult.wait();
    // console.log(`Success - ${buyResult.hash}`);
    return { buyResult, nftAddress, tokenId, price };
}

const sellNft = async (nftAddress, tokenId, price) => {
    // console.log(nftAddress, tokenId, price, contract[CHAIN_ID].NftMarketplace.toLowerCase())
    console.log("Approving NFT...")
    console.log('tokenId=', ethers.BigNumber.from(tokenId).toNumber())
    const basicNft = createContract(nftAddress, abi.BasicNft);
    const approvalTx = await basicNft.approve(contract[CHAIN_ID].NftMarketplace.toLowerCase(), tokenId)
    await approvalTx.wait(1)
    console.log("Listing NFT...")
    const listItemTx = await nftMarketplace.listItem(nftAddress, tokenId, ethers.utils.parseEther(price))
    await listItemTx.wait(1)
    console.log("NFT Listed!")
    return { listItemTx, nftAddress, tokenId, price };
}

const getProviderOrSigner = async (needSigner = false) => {
    if (!ethereum) {
        alert("Please install metamask plugin")
        return;
    }
    const web3Provider = new ethers.providers.Web3Provider(ethereum);
    const { chainId } = await web3Provider.getNetwork();
    console.log('chainId', chainId)
    if (needSigner) {
        const signer = web3Provider.getSigner();
        return signer;
    }
    return web3Provider;
};

const getBalance = async (address) => {
    const provider = await getProviderOrSigner()
    const balance = await provider.getBalance(address)
    console.log('balance', balance)
    const balanceInEth = ethers.utils.formatEther(balance)
    console.log(`${address} balance: ${balanceInEth} ETH`)
    return (+balanceInEth).toFixed(4)
    // return getProviderOrSigner().then(provider => provider.getBalance(address)).then((balance) => ethers.utils.formatEther(balance))
}

export {
    getAddress,
    contracts,
    createContract,
    nftMarketplace,
    getProviderOrSigner,
    getBalance,
    // basicNft,
    // basicNftTwo,
    // getNft,
    // getNfts,
    buyNft,
    sellNft,
    updateNftInfo
}