import React from 'react';
import Slider from "react-slick";
import Header from "./Header";
import Footer from "./Footer";
import Left from "./Left";
import './Home.css';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel  } from 'react-responsive-carousel';


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      p0: [
        { name: "NoSignal", pic: "/static/media/bg1.png" },
        { name: "Lanren", pic: "/static/media/bg2.png"},
      ],
      p1: [
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "Lanren", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "15.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
        { name: "NoSignal", pic: "/static/media/ntf1.png", price: "0.85", priceLast: "10.47" },
      ],


      p2: [
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://cdn.discordapp.com/attachments/984471984048062564/992394757739384922/launchpad_main.gif", code: "01d  07h 58m" },
        { name: "NFT Leaguez", pic: "https://img-cdn.magiceden.dev/rs:fill:252:189:0:0/plain/https://dl.airtable.com/.attachmentThumbnails/1c5d1a7ee45c691c57164f9821933378/0ff4d592", code: "01d  07h 58m" },
      ],





    };
  }
  componentDidMount() {


  }



  render() {

    const settingsSlick = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass: "tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full FeaturedCarouselV2_customDots__tc6nQ",
      arrows: false,
    };

    return (
      <div className="App">
        <div className="main page tw-bg-[#120d18]">
         <Header/> 
         <div className="tw-flex tw-w-full tw-flex-auto">
           <Left /> 
            <div id="content" className="tw-relative tw-flex tw-flex-col tw-flex-auto tw-ml-0 2xl:tw-flex 2xl:tw-items-center content__regular lg:tw-ml-240px">
              <div className="carousel-container tw-mb-12">
                <div className="tw-flex tw-items-center tw-flex-col tw-pr-6 tw-pl-4">
                  <div className="carousel tw-w-full tw-relative sm:tw-border sm:tw-border-gray-300 tw-min-h-500px lg:tw-h-600px tw-overflow-hidden FeaturedCarouselV2_carousel__ebYGy">
                    <div className="tw-grid tw-grid-cols-11 tw-w-full tw-h-auto lg:tw-h-600px tw-absolute">
                      <div className="tw-mt-4 lg:tw-mt-0 tw-col-span-11 tw-order-2 lg:tw-order-1 lg:tw-col-span-5 2xl:tw-col-span-4 tw-px-10 tw-h-full tw-flex tw-flex-col tw-justify-center">
                        <svg aria-labelledby="6qlaj0g-aria" role="img" height="64px">
                          <title id="6qlaj0g-aria">Loading...</title>
                          <rect role="presentation" x="0" y="0" width="100%" height="100%" clipPath="url(#6qlaj0g-diff)" style={{ fill: "url(&quot;#6qlaj0g-animated-diff&quot;)" }}></rect>
                          <defs>
                            <clipPath id="6qlaj0g-diff">
                              <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%"></rect>
                            </clipPath>
                            <linearGradient id="6qlaj0g-animated-diff">
                              <stop offset="0%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="50%" stopColor="#444" stopOpacity="1">
                                <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="100%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                        <svg aria-labelledby="83s58a9-aria" role="img" height="48px" className="tw-my-6">
                          <title id="83s58a9-aria">Loading...</title>
                          <rect role="presentation" x="0" y="0" width="100%" height="100%" clipPath="url(#83s58a9-diff)" style={{ fill: "url(&quot;#83s58a9-animated-diff&quot)" }}></rect>
                          <defs>
                            <clipPath id="83s58a9-diff">
                              <rect x="0" y="0" rx="2" ry="2" width="100%" height="16px"></rect>
                              <rect x="0" y="24px" rx="2" ry="2" width="100%" height="16px"></rect>
                            </clipPath>
                            <linearGradient id="83s58a9-animated-diff">
                              <stop offset="0%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="50%" stopColor="#444" stopOpacity="1">
                                <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="100%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <div className="tw-col-span-11 tw-order-1 lg:tw-order-2 lg:tw-col-span-6 2xl:tw-col-span-7 tw-overflow-hidden tw-h-300px lg:tw-h-full tw-rounded-md">
                        <svg aria-labelledby="3mecu89-aria" role="img" width="100%" height="100%">
                          <title id="3mecu89-aria">Loading...</title>
                          <rect role="presentation" x="0" y="0" width="100%" height="100%" clipPath="url(#3mecu89-diff)" style={{ fill: "url(&quot;#3mecu89-animated-diff&quot;)" }}></rect>
                          <defs>
                            <clipPath id="3mecu89-diff">
                              <rect x="0" y="0" rx="2" ry="2" width="100%" height="600px"></rect>
                            </clipPath>
                            <linearGradient id="3mecu89-animated-diff">
                              <stop offset="0%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="-2; -2; 1" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="50%" stopColor="#444" stopOpacity="1">
                                <animate attributeName="offset" values="-1; -1; 2" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                              <stop offset="100%" stopColor="#333" stopOpacity="1">
                                <animate attributeName="offset" values="0; 0; 3" keyTimes="0; 0.25; 1" dur="2s" repeatCount="indefinite"></animate>
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                          <Slider  {...settingsSlick}  >
                          {this.state.p0.map((item, index) => (
                            <div key={index}>
                              <div className="tw-grid tw-grid-cols-11 tw-w-full tw-h-auto lg:tw-h-600px tw-bg-gray-100">
                                <div className="tw-mt-4 lg:tw-mt-0 tw-col-span-11 tw-order-2 lg:tw-order-1 lg:tw-col-span-5 2xl:tw-col-span-4 tw-px-10 tw-h-full tw-flex tw-flex-col tw-justify-center">
                                  <div>
                                    <h2 className="tw-text-5xl tw-font-extrabold FeaturedCarouselV2_carouselItemTitle__E7-QW">Founders Coins</h2>
                                    <p className="tw-mt-3 tw-text-light-gray-500">The first ever Bear Drop. A collection of 10,000 Founders Coins celebrating our history-making, record-breaking, culture-shifting community.</p>
                                    <a className="tw-text-white-1" href="/marketplace/">
                                      <button type="button" className="tw-inline-flex tw-justify-center tw-items-center tw-rounded-md tw-text-white-1 PlainButton_btn__CRXH4 tw-my-8 PlainButton_primary__22Ken">Explore Collection</button>
                                    </a>
                                  </div>
                                </div>
                                <div className="tw-col-span-11 tw-order-1 lg:tw-order-2 lg:tw-col-span-6 2xl:tw-col-span-7 tw-overflow-hidden lg:tw-h-full tw-rounded-md tw-h-300px" style={{ backgroundImage: "url(/static/media/bg1.png)", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }}></div>
                              </div>
                            </div>
                          ))}
                          </Slider>
                  </div>

                  <div className="tw-absolute sm:tw-relative tw-grid tw-grid-cols-11 tw-w-full FeaturedCarouselV2_customDots__tc6nQ"><ul className="tw-col-span-11 md:tw-col-start-3 md:tw-col-span-7 lg:tw-col-start-4 lg:tw-col-span-5 xl:tw-col-start-5 xl:tw-col-span-3 tw-justify-center tw-grid tw-gap-2 tw-px-12 lg:tw-px-4" style={{"gridTemplateColumns":"repeat(2, 1fr)"}}><li className="tw-cursor-pointer tw-flex tw-items-center"><span className="tw-bg-gray-6 lg:tw-bg-opacity-100 tw-bg-opacity-50"></span></li><li className="tw-cursor-pointer tw-flex tw-items-center"><span className="tw-bg-white-2"></span></li></ul></div>
                  
                </div>

                <div className="carousel-container sm:tw-mt-32">
                  <div className="tw-flex tw-flex-col tw-space-y-6 sm:tw-space-y-8">
                    <section className="tw-px-4 tw-pt-0">
                      <div className="tw-flex tw-flex-col tw-flex-wrap md:tw-flex-row tw-mb-6 tw-gap-4 tw-justify-center md:tw-justify-start">
                        <h3 className="tw-mb-0 tw-font-bold tw-text-[28px] tw-leading-9 ">Popular Collections</h3>
                        <div className="tw-flex">
                          <div className="me-dropdown-container">
                            <div className="cursor-pointer position-relative">
                              <input readOnly="" className="tw-truncate tw-select-none" defaultValue="Last 5 minutes" style={{ width: "170px" }} />
                              <div className="d-flex align-items-center h-100 chevron-down">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#f5f3f7" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(245, 243, 247)" }}>
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </div>
                            <div aria-label="dropdown-list" className="dropdown tw-text-secondary no-border" style={{ position: "absolute", inset: "auto auto 0px 0px", width: "170px", transform: "translate(624px, 433px)" }} data-popper-reference-hidden="true" data-popper-escaped="true" data-popper-placement="top">
                              <div className="tw-absolute tw-bg-gray-200 tw-rounded-xl tw-overflow-auto tw-mt-2 tw-text-white-2 tw-w-full">
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 5 minutes</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 15 minutes</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 1 hour</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 6 hours</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 24 hours</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 7 days</button>
                                <button className="tw-flex tw-w-full tw-py-2 tw-px-3 tw-text-left tw-text-sm hover:tw-bg-gray-400 disabled:tw-cursor-not-allowed disabled:tw-text-light-gray-500">Last 30 days</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tw-flex-grow"></div><a className="tw-flex tw-justify-center tw-border-2 tw-border-gray-300 tw-py-1 tw-px-2 tw-text-white-1 Carousel_seeAll__H7M3I" href="/popular-collections">See All</a></div>

                      <div className="tw-grid tw-grid-rows-6 xl:tw-grid-rows-4 3xl:tw-grid-rows-3 sm:tw-grid-flow-col tw-gap-y-8 3xl:tw-gap-x-32 tw-gap-x-16">
                        {this.state.p1.map((item, index) => (
                          <a className="tw-grid tw-grid-cols-[20px,68px,1fr] tw-items-center tw-gap-x-1" href="/marketplace/" key={index} >
                            <div className=" tw-text-white-1 tw-font-bold tw-justify-self-end tw-mr-1">1</div> <img draggable="false" loading="lazy" alt="ntf" className="tw-rounded-full tw-w-16 tw-h-16" src={item.pic} style={{ backgroundColor: "transparent" }} />
                            <div className="tw-flex tw-flex-col tw-gap-1 tw-overflow-hidden">
                              <div className="tw-flex">
                                <h3 className="tw-font-bold tw-white-2 tw-truncate">{item.name}</h3>
                                <div className="tw-flex-grow"></div><span>-</span></div>
                              <div className="tw-flex tw-items-center tw-justify-between tw-text-light-gray-500 tw-text-xs">
                                <div className="tw-flex tw-items-center tw-justify-between tw-space-x-1"><span>Floor</span>
                                  <svg width="14" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z" fill="url(#paint0_linear_354_8099_3763232332)"></path>
                                    <path d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z" fill="url(#paint1_linear_354_8099_4955547757)"></path>
                                    <path d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z" fill="url(#paint2_linear_354_8099_4807055177)"></path>
                                    <defs>
                                      <linearGradient id="paint0_linear_354_8099_3763232332" x1="16.6538" y1="1.87538" x2="7.1259" y2="20.1251" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                      <linearGradient id="paint1_linear_354_8099_4955547757" x1="12.4877" y1="-0.299659" x2="2.95979" y2="17.9501" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                      <linearGradient id="paint2_linear_354_8099_4807055177" x1="14.5575" y1="0.78106" x2="5.02959" y2="19.0308" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                    </defs>
                                  </svg><span>{item.price}</span></div>
                                <div className="tw-flex tw-items-center tw-justify-between tw-space-x-1">
                                  <svg width="14" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.80286 13.8188C3.90696 13.7147 4.0501 13.6539 4.20191 13.6539H17.9689C18.2205 13.6539 18.3463 13.9576 18.1685 14.1354L15.4489 16.855C15.3448 16.9591 15.2017 17.0198 15.0498 17.0198H1.28281C1.03124 17.0198 0.905451 16.7162 1.08329 16.5383L3.80286 13.8188Z" fill="url(#paint0_linear_354_8099_4172400548)"></path>
                                    <path d="M3.80286 3.66482C3.9113 3.56072 4.05443 3.5 4.2019 3.5H17.9689C18.2205 3.5 18.3463 3.80362 18.1685 3.98146L15.4489 6.70103C15.3448 6.80513 15.2017 6.86585 15.0498 6.86585H1.28281C1.03124 6.86585 0.905451 6.56223 1.08329 6.3844L3.80286 3.66482Z" fill="url(#paint1_linear_354_8099_1586810329)"></path>
                                    <path d="M15.4489 8.70938C15.3448 8.60528 15.2017 8.54456 15.0498 8.54456H1.28281C1.03124 8.54456 0.905451 8.84818 1.08329 9.02601L3.80286 11.7456C3.90696 11.8497 4.0501 11.9104 4.20191 11.9104H17.9689C18.2205 11.9104 18.3463 11.6068 18.1685 11.429L15.4489 8.70938Z" fill="url(#paint2_linear_354_8099_0971032468)"></path>
                                    <defs>
                                      <linearGradient id="paint0_linear_354_8099_4172400548" x1="16.6538" y1="1.87538" x2="7.1259" y2="20.1251" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                      <linearGradient id="paint1_linear_354_8099_1586810329" x1="12.4877" y1="-0.299659" x2="2.95979" y2="17.9501" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                      <linearGradient id="paint2_linear_354_8099_0971032468" x1="14.5575" y1="0.78106" x2="5.02959" y2="19.0308" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#00FFA3"></stop>
                                        <stop offset="1" stopColor="#DC1FFF"></stop>
                                      </linearGradient>
                                    </defs>
                                  </svg><span>{item.priceLast}</span></div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </section>

                    <div className="tw-px-3 sm:tw-px-6">
                      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                        <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Launchpad drops</h2>
                        <div className="tw-flex tw-flex-nowrap tw-rounded tw-border-solid tw-border-gray-300 tw-border Carousel_pillTabs__amXzc">
                          <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 tw-bg-pink-primary hover:tw-opacity-80">Next 7 days</      button>
                          <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 hover:tw-opacity-80">Coming soon</button>
                        </div>
                        <div className="tw-flex-grow-2"></div><a className="tw-flex tw-justify-center tw-border-2 tw-border-gray-300 tw-py-1 tw-px-2 tw-text-white-1                        Carousel_seeAll__H7M3I" href="/launchpad">See All</a></div>
                      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                        <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw- hidden" disabled="">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub" >
                          {this.state.p2.map((item, index) => (
                            <div key={index}>
                              <div className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2" data-key="NFT Leaguez" data-index="0" key={index}>
                                <div itemID={item.name}>
                                  <a href={`/launchpad/+${item.name}`}>
                                    <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden"><img draggable="false" alt="1" loading="lazy" className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl" src={item.pic} style={{ backgroundColor: "transparent" }} />
                                      <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl                               Carousel_cardInfo__voX62"><span className="tw-text-white-1">{item.name}</span><span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>NFT Leaguez</span></span> <div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId tw-flex tw-space-x-2"><span>{item.code}</span></div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="react-horizontal-scrolling-menu--separator " data-key="NFT Leaguez-separator" data-index="0.1"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="tw-px-3 sm:tw-px-6">
                      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                        <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Content hub</h2>
                        <div className="tw-flex-grow-2"></div>
                      </div>
                      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                        <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw- hidden" disabled="">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub">
                          {this.state.p2.map((item, index) => (
                            <div key={index}>
                              <div className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2" data-key="NFT Leaguez" data-index="0" key={index} >
                                <div itemID={item.name}>
                                  <a href="#">
                                    <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden"><img draggable="false" alt="1" loading="lazy" className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl" src={item.pic} style={{ backgroundColor: "transparent" }} />
                                      <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl                               Carousel_cardInfo__voX62"><span className="tw-text-white-1">Ap3Father: The Don O...</span><span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>Ap3Father:   The Don O...</span></span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="react-horizontal-scrolling-menu--separator " data-key="NFT Leaguez-separator" data-index="0.1"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="tw-px-3 sm:tw-px-6">
                      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                        <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Auctions</h2>
                        <div className="tw-flex tw-flex-nowrap tw-rounded tw-border-solid tw-border-gray-300 tw-border Carousel_pillTabs__amXzc">
                          <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 tw-bg-pink-primary hover:tw-opacity-80">Upcoming</      button>
                          <button className="tw-px-2 tw-py-2 tw-text-xs tw-rounded tw-text-white-1 Carousel_pillTab__rZkG6 hover:tw-opacity-80">Ended</button>
                        </div>
                        <div className="tw-flex-grow-2"></div><a className="tw-flex tw-justify-center tw-border-2 tw-border-gray-300 tw-py-1 tw-px-2 tw-text-white-1 Carousel_seeAll__H7M3I" href="/auction">See All</a></div>
                      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                        <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw- hidden" disabled="">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub" >
                          {this.state.p2.map((item, index) => (
                            <div key={index}>
                              <a href={`/auction/+${item.name}`}>
                              <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden Carousel_carouselItem__y6Ip3"><img draggable="false" loading="lazy" className="card-img-top SolanaNFTMedia_media__1RXR3 Carousel_cardMedia__kJpe- SolanaNFTMedia_center__YTvJM Carousel_cardMedia__kJpe-2" src="https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://hxgkxp4mpxr2zqcygtsyijmv43ssivbbvcrdfripgi6hir3mjy.arweave.net/Pcyrv4x946zAWDTlhCWV5uUkVCG-oojLFDzI8dEdsTg" style={{ backgroundColor: "transparent" }} alt="1" /><div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl Carousel_cardInfo__voX62"><span className="tw-text-white-1">The Sol Army GV Pass</span><span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>The Sol Army</span></span><div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId"><div className="tw-space-x-1"><span>Aug 12. 2022</span></div></div></div></div></a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="tw-px-3 sm:tw-px-6">
                      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                        <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Top upvoted drops</h2>
                        <div className="tw-flex-grow-2"></div>
                        <a className="tw-flex tw-justify-center tw-border-2 tw-border-gray-300 tw-py-1 tw-px-2 tw-text-white-1 Carousel_seeAll__H7M3I" href="/drops">See All</a></div>
                      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                        <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw- hidden" disabled="">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub" >
                          {this.state.p2.map((item, index) => (
                            <div key={index}>
                              <div className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2" data-key="NFT Leaguez" data-index="0" key={index}>
                                <div itemID={item.name}>
                                  <a href={`/drops/+${item.name}`}>
                                    <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden"><img draggable="false" alt="1" loading="lazy" className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl" src={item.pic} style={{ backgroundColor: "transparent" }} />
                                      <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl                               Carousel_cardInfo__voX62"><span className="tw-text-white-1">{item.name}</span><span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>NFT Leaguez</span></span>
                                        <div className="tw-flex tw-gap-1"><div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId"><span className="tw-text-xs tw-flex tw-items-center tw-text-pink-primary"><svg strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#e42575" fill="#e42575" className="tw-mr-1 tw-h-3"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg> 4403</span></div><div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId"><span className="tw-text-xs text-white">Aug 18, 08:00</span></div></div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="react-horizontal-scrolling-menu--separator " data-key="NFT Leaguez-separator" data-index="0.1"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="tw-px-3 sm:tw-px-6">
                      <div className="tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mb-3">
                        <h2 className="tw-text-xl sm:tw-text-3xl tw-font-bold">Frens are watching</h2>
                        <div className="" data-tooltipped="" aria-describedby="tippy-tooltip-1" data-original-title="Collections featured were added to most personal watchlist." style={{ display: "inline" }}>
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#e42575" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(228, 37, 117)" }}>
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="16" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12.01" y2="8"></line>
                          </svg>
                        </div>
                        <div className="tw-flex-grow-2"></div>
                        </div>
                      <div className="react-horizontal-scrolling-menu--wrapper Carousel_carousel__a8r0p">
                        <button style={{ display: "none" }} className="tw-absolute tw-rounded-full tw-z-20 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center Carousel_left__EcpM8 Carousel_arrow__pjtQq tw- hidden" disabled="">
                          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#fff" height="24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ color: "rgb(255, 255, 255)" }}>
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <div className="react-horizontal-scrolling-menu--scroll-container Carousel_scrollContainer__9fHub" >
                          {this.state.p2.map((item, index) => (
                            <div key={index}>
                              <div className="react-horizontal-scrolling-menu--item Carousel_carouselItem__y6Ip2" data-key="NFT Leaguez" data-index="0" key={index}>
                                <div itemID={item.name}>
                                  <a href="/marketplace">
                                    <div className="Carousel_standalone__P6jOX Carousel_card__qZh08 tw-relative tw-rounded-xl tw-border tw-border-gray-300 tw-overflow-hidden"><img draggable="false" alt="1" loading="lazy" className="Carousel_cardMedia__kJpe- tw-object-cover tw-rounded-xl" src={item.pic} style={{ backgroundColor: "transparent" }} />
                                      <div className="tw-w-full tw-text-sm tw-whitespace-nowrap tw-flex tw-flex-col tw-items-center tw-rounded-b-xl                               Carousel_cardInfo__voX62"><span className="tw-text-white-1">{item.name}</span><span className="tw-text-pink-primary tw-flex tw-items-center tw-space-x-2 tw-text-xs"><span>NFT Leaguez</span></span>
                                        <div className="tw-bg-gray-200 tw-text-xs tw-py-1 tw-px-2 tw-mt-3 tw-text-white-1 Carousel_pill__oweId"><span className="tw-text-xs text-white">8291 watchers</span></div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="react-horizontal-scrolling-menu--separator " data-key="NFT Leaguez-separator" data-index="0.1"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Footer /> 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
