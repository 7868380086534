import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }
  componentDidMount() {
  }
  render() {
    return (
      <div>
        <footer className="tw-mb-10 tw-px-4">
          <div className="tw-p-3 tw-rounded-xl">
            <div className="row tw-grid tw-grid-rows-2 tw-grid-cols-6 lg:tw-grid-rows-1 lg:tw-grid-cols-11">
              <div className="tw-col-span-6 lg:tw-col-span-5 tw-flex tw-flex-col tw-justify-between">
                <div><img className="tw--ml-2" src="/static/media/logo3.png" alt="magic eden logo" /></div>
                <div>
                  <div className="tw-text-light-gray-700 tw-mt-4">I META ID the homepage of NFT's</div>
                  <div className="tw-text-light-gray-700 tw-mt-4">©2022 Euclid Labs, Inc. All Rights Reserved.</div>
                </div>
              </div>
              <div className="tw-col-span-2 tw-flex tw-pt-6">
                <div>
                  <h4 className="tw-text-lg title">Marketplace</h4>
                  <ul className="submenu">
                    <li><a href="/popular-collections">Popular collections</a></li>
                    <li><a href="/launchpad">Launchpad</a></li>
                    <li><a href="/auctions">Auctions</a></li>
                  </ul>
                </div>
              </div>
              <div className="tw-col-span-2 tw-pt-6">
                <h4 className="tw-text-lg title"><a href="/games">I META ID Games</a></h4>
                <ul className="submenu">
                  <li><a href="/games/browse">All Games</a></li>
                  <li><a href="#" target="_blank" rel="noreferrer">Launch With Us</a></li>
                </ul>
              </div>
              <div className="tw-col-span-2 tw-pt-6">
                <div>
                  <h4 className="tw-text-lg title">Resources</h4>
                  <ul className="submenu">
                    <li><a href="/about">About Us</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">API</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Careers</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">System Status</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Support</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Shop</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Copyright Policy</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Terms of Service</a></li>
                    <li><a href="#" target="_blank" rel="noreferrer noopener">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    );
  }
}
export default Footer;
